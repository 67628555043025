(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbHighlight', rbHighlight);

    /**
     * @ngdoc directive
     * @name common.directive:rbHighlight
     *
     * @restrict A
     * @requires ngModel
     * @element input
     *
     * @description
     *     Highlights an element by adding and removeing a class
     *
     * @ngInject
     */
    function rbHighlight($timeout) {
        var directive = {
            restrict: 'A',
            scope: {
                model: '=rbHighlight'
            },
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, element) {
            var highlightClass = 'isHighlighted';

            $scope.$watch('model', function (newValue, oldValue) {
                if (newValue === oldValue) {
                    return;
                }

                // element.on('transitionend', function() {
                //     element.removeClass(highlightClass);
                // }, false);

                element.addClass(highlightClass);

                $timeout(function () {
                    element.removeClass(highlightClass);
                }, 500);

            }, true);
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbHeaderTitle', headerTitle);

    /**
     * @ngdoc directive
     * @name common.directive:rbHeaderTitle
     *
     * @scope {}
     * @replace true
     * @requires $rootScope
     * @element h1
     *
     * @description
     *     Sets the title headline of the current page
     *
     * @author Bogdan Gersak
     *
     * @ngInject
     */
    function headerTitle() {
        var directive = {
            controller: Controller,
            controllerAs: 'vm',
            scope: {},
            restrict: 'E',
            template: '<div class="c-pageHeader c-pageHeader--{{ vm.section }}">' +
                      '<h1 class="c-pageHeader__title">{{ vm.headerTitle }}</h1>' +
                      '<span class="c-icon c-icon--dt is{{ vm.sectionClass }}"></span>' +
                      '</div>'
        };

        return directive;

        /* @ngInject */
        function Controller($rootScope, $scope, $translate) {
            var vm = this;

            $scope.$watch(
                function() {
                    return $rootScope.section;
                },
                function() {
                    vm.section = $rootScope.section;
                    vm.title = 'title_' + vm.section;
                    vm.sectionClass = vm.section.charAt(0).toUpperCase() + vm.section.substr(1);

                    $translate(vm.title).then(function(i18nTitle) {
                        vm.headerTitle = i18nTitle;
                    });
            });
        }
    }
})();

(function () {
    'use strict';

    /**
     * @ngdoc directive
     * @name runner.directive:rbRunnerSaddle
     *
     * @scope {}
     * @replace true
     * @restrict E
     *
     * @description
     *     Displays the saddle for a runner
     *
     */

    angular
        .module('runner')
        .directive('rbRunnerSaddle', rbRunnerSaddle);

    /* @ngInject */
    function rbRunnerSaddle() {
        return {
            controller: Controller,
            controllerAs: 'vm',
            replace: true,
            scope: {
                country: '@',
                raceType: '@',
                programNumber: '@',
                size: '@'
            },
            restrict: 'E',
            templateUrl: '/angular/components/runner/views/rb-runner-saddle.html'
        };

        /* @ngInject */
        function Controller ($scope) {
            var vm = this;
        }
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .filter('eventsBySpecials', eventsBySpecials);

    /**
     * @ngDoc filter
     *
     * @description
     *     Filters a list of events whether it is a special or not
     *
     *
     * @return {Array} Events
     *
     * @author Andras Matzon
     */
    function eventsBySpecials() {
        return function (events, preFiltered) {
            var filteredEvents = [];

            if (!(events && events.length)) {
                return;
            }

            for (var i = events.length - 1; i >= 0; i--) {
                if ((preFiltered && events[i].is_special) || (!preFiltered && !events[i].is_special)) {
                    filteredEvents.push(events[i]);
                    //break;
                }
            }

            return filteredEvents;
        };
    }
})(angular);

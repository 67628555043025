(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbInfoTooltip', tooltip);

    /**
     * @ngdoc directive
     * @name  common.directive:rbInfoTooltip
     *
     * @restrict A
     * @requires $window
     * @requires $filter
     *
     * @description
     *     This type of tooltip can be used to show information about a specific area.
     *
     *     The default behaviour is to show the tooltip on click, but another event can be
     *     specified as well, as second item in the configuration array. First have to be always
     *     the message translation index, which will be translated by translation filter.
     *
     *
     *
     * @example
        <div rb-info-tooltip=" 'message' ">
            ...
        </div>

        <div rb-info-tooltip=" ['message', 'hover'] ">
            ...
        </div>
     *
     * @author Norbert Varga
     */
    function tooltip($window, $filter, $timeout) {
        var directive = {
            scope: {
                tooltipOptions: '=rbInfoTooltip'
            },
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, elem) {
            var Tipped = $window.Tipped,
                errorName = 'rb-info-tooltip directive: ',
                tooltipOptions = scope.tooltipOptions,
                defaultOptions = {
                    hook: 'righttop',
                    hideOthers: true,
                    offset: { x: 2, y: 0 },
                    stem: { offset: { x: 0, y: 6 } },
                    skin: 'dark form-field-hint',
                    padding: 0,
                    fadeIn: false,
                    showOn: 'click',
                    hideOn: 'click-outside',
                    maxWidth: 250
                },
                options = {},
                message = '';

            if ( !angular.isArray(tooltipOptions) && !angular.isString(tooltipOptions) ) {
                throw new Error(errorName, 'Options passed are invalid! Should be an array of string');
            }

            if ( angular.isArray(tooltipOptions) ) {

                if ( angular.isString(tooltipOptions[0]) ) {
                    message = tooltipOptions[0];
                }
                else {
                    throw new Error(errorName + 'Message must be a string!');
                }

                if ( angular.isObject(tooltipOptions[1]) ) {
                    angular.extend(options, defaultOptions, tooltipOptions[1]);
                }
                else {
                    throw new Error(errorName + 'Settings must be an object!');
                }
            }

            if ( angular.isString(tooltipOptions) ) {
                message = tooltipOptions;
                options = defaultOptions;
            }

            // wait for translate module load and then create msg
            $timeout(function () {
                Tipped.create(elem, $filter('translate')(message), options);
            }, 50);
        }
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbSecurepaymentWidget', rbSecurepaymentWidget);

    /**
     * @ngdoc directive
     * @name common.directive:rbSecurepaymentWidget
     *
     * @requires RaceBetsJS
     *
     * @description
     *     Secure payment guaranteed widget
     *
     * @ngInject
     */
    function rbSecurepaymentWidget(RaceBetsJS) {
        return {
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/components/views/rb-securepayment-widget.html'
        };
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbFixLength', fixLength);

    /**
     * @ngdoc directive
     * @name  common.directive:rbFixLength
     *
     * @restrict A
     * @requires ngModel
     * @element input
     *
     * @description
     *     Validates and restrict form value according to a given integer
     *     in 'maxlength' attribute of the element.
     *     Creates an isolated scope with bidirectional data binding to
     *     the passed model.
     *
     * @author Norbert Varga
     */
    function fixLength() {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, elem, attrs, ngModelCtrl) {

            checkMaxlength();

            ngModelCtrl.$parsers.unshift(function (newValue) {

                checkMaxlength();

                ngModelCtrl.$setValidity('length', newValue.length === parseInt(attrs.maxlength) );

                return newValue;
            });

            function checkMaxlength() {
                if (!attrs.maxlength) {
                    throw new Error('rb-fix-length directive: maxlength must be specified!');
                }

                if (attrs.maxlength < 1) {
                    throw new Error('rb-fix-length directive: maxlength must be greater than 0!');
                }
            }
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('user')
        .factory('UserSession', userSessionService);

    /**
     * @ngdoc service
     * @name user.service:UserSession
     *
     * @requires RaceBetsJS
     *
     * @description
     *     Wrapper service to handle user's session cookie
     *
     * @ngInject
     */
    function userSessionService (RaceBetsJS) {
        var sessionCookie = {};

        var service = {
            get: getSession,
            set: saveSession
        };

        return service;

        /**
         * @ngdoc method
         * @name user.service:UserSession#getSession
         * @public
         * @methodOf user.service:UserSession
         *
         * @description
         *     Get user's settings from session cookie
         *
         * @return {Object} Settings object from session cookie
         */
        function getSession(sessionGroup) {
            sessionCookie = RaceBetsJS.application.assets.session.getCookie();

            return sessionCookie[sessionGroup] || sessionCookie;
        }

        /**
         * @ngdoc method
         * @name user.service:UserSession#saveSession
         * @public
         * @methodOf user.service:UserSession
         *
         * @description
         *     Save the specified settings object to settings cookie
         */
        function saveSession(sessionGroup, settings) {
            if (!sessionGroup || !settings) {
                return;
            }

            RaceBetsJS.application.assets.session.setCookie(sessionGroup, settings);
        }
    }

})();

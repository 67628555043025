(function (angular) {
    'use strict';

    angular
        .module('featuredHorses')
        .factory('HorsesAbroadResource', horsesAbroadResource);

    /**
     * @ngdoc service
     * @name featuredHorses.service:NextraceResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Next Race API resource
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function horsesAbroadResource($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v2/horses_abroad\/:part',
            {},
            {
                getCountries: {
                    method: 'GET',
                    params: {
                        part: 'countries'
                    }
                }
            });
    }
})(angular);

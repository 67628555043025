(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbJustInteger', justInteger);

    /**
     * @ngdoc directive
     * @name common.directive:rbJustInteger
     *
     * @restrict A
     * @requires ngModel
     * @element input
     *
     * @description
     *     Allow typing just integers.
     *
     * @author Norbert Varga
     */
    function justInteger() {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, element, attrs, ngModelCtrl) {

            ngModelCtrl.$parsers.unshift(function (newValue) {
                if (newValue) {
                    var digits = newValue.replace(/[^0-9]+/g, '');

                    ngModelCtrl.$viewValue = digits;
                    ngModelCtrl.$render();

                    return digits;
                }

                return newValue;
            });
        }
    }
})(angular);

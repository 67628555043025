(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbInputGtmEvent', rbInputGtmEvent);

    /**
     * @ngdoc directive
     * @name  common.directive:rbInputEvent
     *
     * @restrict A
     * @requires ngModel,
     * @requires GoogleTagManager
     *
     * @description
     *          Sends input attributes to Google Tag Manager on change
     *
     * @ngInject
     */
    function rbInputGtmEvent(GoogleTagManager) {
        return {
            scope: false,
            require: '^ngModel',
            restrict: 'A',
            link: link
        };

        function link(scope, elem, attrs, ctrl) {
            // Listen on change
            elem.on('change.gtm', trackEvent);

            /**
             * @ngdoc method
             * @name common.directive:rbInputGtmEvent#trackEvent
             * @private
             * @methodOf common.directive:rbInputGtmEvent
             *
             * @description
             *      Input change callback sends to GTM
             */
            function trackEvent() {
                if (!ctrl.$dirty) {
                    return;
                }

                var fieldName = attrs.rbInputGtmEvent;

                GoogleTagManager.customEvent({
                    'FieldName': fieldName,
                    'event': 'RegistrationFieldChange'
                });
            }
        }
    }
})(angular);

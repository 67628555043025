(function (angular) {
    'use strict';

    angular
        .module('common')
        .factory('Users', UsersFactory);

    /**
     * @ngdoc service
     * @name common.service:Users
     *
     * @requires $q
     * @requires UsersResource
     *
     * @description
     *     Handles API calls to server
     *
     * @ngInject
     */
    function UsersFactory ($rootScope, $q, $injector, UsersResource, UsersResourceV2) {
        var se = this;
        var service = {
            retrieveUsername: retrieveUsername,
            requestPasswordReset: requestPasswordReset,
            resetPassword: resetPassword,
            registerUser: registerUser,
            userExists: userExists,
            registerTrustlyUser: registerTrustlyUser
        };

        if ($rootScope.brandName === 'racebets') {
            se.trustlyResource = $injector.get('UsersTrustlyResourceV2');
        }

        return service;

        /**
         * @name common.service:Users#requestUsername
         * @methodOf common.service:Users
         *
         * @description
         *     Request username
         *
         * @returns {Object} - Promise object
         */
        function retrieveUsername(data) {
            var defer = $q.defer();

            UsersResource
                .retrieveUsername(data).$promise
                .then(
                    function (resp) {
                        defer.resolve(resp.data);
                    },
                    function (resp) {
                        defer.reject(resp.data);
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:Users#requestPasswordReset
         * @methodOf common.service:Users
         *
         * @description
         *     Request password reset
         *
         * @returns {Object} - Promise object
         */
        function requestPasswordReset(data) {
            var defer = $q.defer();

            UsersResource
                .requestPasswordReset(data).$promise
                .then(
                    function (resp) {
                        defer.resolve(resp.data);
                    },
                    function (resp) {
                        defer.reject(resp.data);
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:Users#resetPassword
         * @methodOf common.service:Users
         *
         * @description
         *     Request password reset
         *
         * @returns {Object} - Promise object
         */
        function resetPassword(data) {
            var defer = $q.defer();

            UsersResource
                .resetPassword(data).$promise
                .then(
                    function (resp) {
                        defer.resolve(resp.data);
                    },
                    function (resp) {
                        defer.reject(resp.data);
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:Users#userExists
         * @methodOf common.service:Users
         *
         * @description
         *     Check if username is already used. If username is found, the
         *     resource returns with success, so we reject the promise.
         *
         * @returns {Object} - Promise object
         */
        function userExists(username) {
            var defer = $q.defer();

            UsersResourceV2
                .exist({
                    username: username
                })
                .$promise
                .then(
                    function () {
                        defer.reject();
                    },
                    function () {
                        defer.resolve();
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:Users#registerUser
         * @methodOf common.service:Users
         *
         * @description
         *     Request password reset
         *
         * @returns {Object} - Promise object
         */
        function registerUser(data) {
            var defer = $q.defer();

            UsersResourceV2
                .register(data).$promise
                .then(
                    function (resp) {
                        defer.resolve(resp);
                    },
                    function (resp) {
                        defer.reject(resp);
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:Users#registerTrustlyUser
         * @methodOf common.service:Users
         *
         * @description
         *     Request password reset
         *
         * @returns {Object} - Promise object
         */
        function registerTrustlyUser(data) {
            var defer = $q.defer();

            se.trustlyResource
                .register(data).$promise
                .then(
                    function (resp) {
                        defer.resolve(resp);
                    },
                    function (resp) {
                        defer.reject(resp);
                    }
                );

            return defer.promise;
        }

    }
})(angular);

(function (angular) {
    'use strict';

    angular
        .module('common')
        .factory('GoogleTagManager', googleTagManager);

    /**
     * @ngdoc service
     * @name common.service:GoogleTagManager
     *
     * @requires $window
     *
     * @description
     *         Sends custom data to Google Tag Manager's dataLayer
     *
     * @ngInject
     */
    function googleTagManager($window, RaceBetsJS) {
        var dataLayer = $window.dataLayer = window.dataLayer || [];

        var service = {
            analyticsEvent: analyticsEvent,
            customEvent: customEvent,
            getLobby: window.getLobby
        };

        return service;

        /**
         * @ngdoc method
         * @name common.service:googleTagManager#analyticsEvent
         * @public
         * @methodOf common.service:googleTagManager
         *
         * @description
         *         GTM sends custom event to Google Analytics event trigger
         *
         * @param {Object} data Event data
         * @param {boolean} nonInteraction Event type is non-interaction
         */
        function analyticsEvent(data, nonInteraction) {
            var event = {
                'eventCategory': data.category || 'n/a',
                'eventAction': data.action || 'n/a',
                'eventLabel': data.label || 'n/a',
                'event': nonInteraction ? 'analyticsNonInteraction' : 'analyticsEvent'
            };

            if (data.value && isFinite(data.value)) {
                angular.extend(event, {
                    'eventValue': data.value
                });
            }

            dataLayer.push(event);
        }

        /**
         * @ngdoc method
         * @name common.service:googleTagManager#customEvent
         * @public
         * @methodOf common.service:googleTagManager
         *
         * @description
         *         Sends custom event data to Tag Manager's dataLayer
         *
         * @param {Object} data
         */
        function customEvent(data) {
            dataLayer.push(data);
        }
    }
})(angular);

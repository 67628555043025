(function () {
    'use strict';

    angular
        .module('user')
        .factory('UserSettings', userSettingsService);

    /**
     * @ngdoc service
     * @name user.service:UserSettings
     *
     * @requires $rootScope
     * @requires RaceBetsJS
     *
     * @description
     *     Service to handle user's settings
     *
     * @author Norbert Varga
     *
     * @ngInject
     */
    function userSettingsService ($rootScope, RaceBetsJS) {
        var settingsCookie = {};

        var service = {
            get: getSettings,
            set: saveSettings
        };

        return service;

        /**
         * @ngdoc method
         * @name user.service:UserSettings#getSettings
         * @public
         * @methodOf user.service:UserSettings
         *
         * @description
         *     Get user's settings from settings cookie
         *
         * @return {Object} Settings object from settings cookie
         */
        function getSettings(settingsGroup) {
            settingsCookie = RaceBetsJS.application.assets.settings.get();

            return settingsCookie.dialog[settingsGroup] || settingsCookie.dialog;
        }

        /**
         * @ngdoc method
         * @name user.service:UserSettings#saveSettings
         * @public
         * @methodOf user.service:UserSettings
         *
         * @description
         *     Save the specified settings object to settings cookie
         */
        function saveSettings(settingsGroup, settings, raw) {
            if (!settingsGroup || !settings) {
                return;
            }
            settingsCookie = RaceBetsJS.application.assets.settings.get(raw);

            if (['general', 'betslip', 'countries'].indexOf(settingsGroup) > -1) {
                settingsCookie.dialog[settingsGroup] = settings;
            } else {
                return;
            }

            RaceBetsJS.application.assets.settings.save(
                $.stringify(settingsCookie),
                // Emit update of user's settings cookie after save
                function() {
                    return $rootScope.$emit('User:settings:update', settingsCookie);
                }
            );
        }
    }

})(angular);

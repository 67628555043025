(function (angular, _) {
    'use strict';

    angular
        .module('featuredHorses')
        .directive('rbFeaturedHorsesFilter', rbFeaturedHorsesFilter);

    /**
     * @ngdoc directive
     * @name featuredHorses.directive:rbFeaturedHorsesFilter
     *
     * @require $document
     * @require RaceBetsJS
     *
     * @restrict E
     *
     * @description
     *      Common filtering for featured horses (like horses abroad, stars, etc.)
     *
     */
    function rbFeaturedHorsesFilter($document, Settings, RaceBetsJS) {
        return {
            controller: Controller,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                filterData: '=',
                filters: '='
            },
            templateUrl: '/angular/modules/featured-horses/views/rb-featured-horses-filter.html',
            link: link
        };

        /* @ngInject */
        function Controller() {
            var vm = this;

            vm.toggleCalendar = toggleCalendar;
            vm.sumHorses = sumHorses;
            vm.getCountryIOC = Settings.getCountryIOC;

            /**
             * @ngdoc property
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#loading
             * @public
             * @propertyOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *     Service loading
             *
             * @type {Boolean}
             */
            vm.loading = false;

            /**
             * @ngdoc property
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#showCalendar
             * @public
             * @propertyOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *     Show datepicker calendar
             *
             * @type {Boolean}
             */
            vm.showCalendar = false;

            /**
             * @ngdoc property
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#disableNextDay
             * @public
             * @propertyOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *     Disable next day button
             *
             * @type {Boolean}
             */
            vm.disableNextDay = false;

            /**
             * @ngdoc property
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#disablePrevDay
             * @public
             * @propertyOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *     Disable next day button
             *
             * @type {Boolean}
             */
            vm.disablePrevDay = false;

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#toggleCalendar
             * @public
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Show calendar
             */
            function toggleCalendar() {
                vm.showCalendar = true;
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#sumHorses
             * @public
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Sum of all horses in one race type
             *
             * @param raceTypeData Country data in selected race type
             */
            function sumHorses(raceTypeData) {
                var sum = 0;

                _.each(raceTypeData, function (country) {
                    sum += country.num_horses;
                });

                return sum;
            }
        }

        function link(scope, elem, attrs) {
            var calendar = elem.find('#m-featuredHorses__calendar'),
                options = {
                    firstDay: 1,
                    dateFormat: 'yy-mm-dd',
                    minDate: new Date(2005, 0, 1),
                    maxDate: '+1 week',
                    autosize : false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    altField: elem.find('#m-featuredHorses__calendarInput'),
                    altFormat: 'D, ' + RaceBetsJS.i18n.data.dateFormatDate.toLowerCase().replace('y', 'yy'),
                    onSelect: onSelectHandler
                },
                maxDate, minDate;

            scope.vm.setDate = setDate;

            // Wait for raceBetsJS
            RaceBetsJS.isInitDone().then(initDatepicker);

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#initDatepicker
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Init jQuery datepicker
             */
            function initDatepicker() {
                $document.on('click.featuredHorsesDatepicker', onClickOutside);

                // Init datepicker
                calendar.datepicker(options);
                calendar.datepicker('setDate', scope.filters.date);

                // Calculate date limits
                maxDate = getDateLimit('maxDate');
                minDate = getDateLimit('minDate');
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#getDateLimit
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Get date limit from datepicker and return formatted
             *
             * @param {String} option Datepicker option value
             *
             * @return {Function} raceBetsJS date format
             */
            function getDateLimit(option) {
                var limit = $.datepicker._determineDate(calendar, calendar.datepicker('option', option), new Date());

                return RaceBetsJS.format.date('Y-m-d', limit.getTime() / 1000);
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#onSelectHandler
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Select date
             */
            function onSelectHandler() {
                // Update model
                scope.filters.date = calendar.datepicker({ dateFormat: 'yy-mm-dd' }).val();

                // Hide calendar
                scope.vm.showCalendar = false;

                // Set button states
                scope.vm.disableNextDay = (scope.filters.date === maxDate);
                scope.vm.disablePrevDay = (scope.filters.date === minDate);
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#setDate
             * @public
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Set date
             */
            function setDate(newDate) {
                calendar.datepicker('setDate', newDate);
                onSelectHandler();
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesFilter#onClickOutside
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesFilter
             *
             * @description
             *         Watch clicks outside datepicker
             *
             * @param {Object} event Angular click event
             */
            function onClickOutside(event) {
                var target = angular.element(event.target);

                if (scope.vm.showCalendar &&
                    !target.is('#m-featuredHorses__calendarInput') &&
                    !target.parents().filter('.ui-datepicker-header').length  ) {
                        scope.$apply(function () {
                            scope.vm.showCalendar = false;
                        });
                }
            }
        }

    }
})(angular, _);

(function (angular) {
    'use strict';

    /**
     * @ngdoc directive
     * @name
     */

    angular
        .module('sidebar')
        .directive('rbSidebar', rbSidebar);

    function rbSidebar() {
        return {
            controller: controller,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {},
            templateUrl: '/angular/modules/sidebar/views/rb-sidebar.html'
        };

        function controller($scope, $filter, $interval, $timeout, sidebarService, sidebarPushService, RaceBetsJS) {
            /*jshint validthis: true */
            var vm = this,
                loadingTimer;

            vm.countries = RaceBetsJS.i18n.data.countries;
            vm.date = RaceBetsJS.date.today();
            vm.dateLoaded = null;
            vm.events = null;
            vm.filteredEvents = {
                countries: {}
            };

            vm.eventsList = {
                antepost: {},
                current: {},
                date: {},
                countries: {
                    current: [],
                    antepost: [],
                    date: []
                }
            };

            vm.eventsIdsList = {
                antepost: {},
                current: {},
                date: {},
                countries: {
                    current: {},
                    antepost: {},
                    date: {}
                }
            };

            vm.filterEvents = filterEvents;
            vm.isNodeJSReady = false;
            vm.selectedDateEvents = null;
            vm.loading = false;

            activate();

            function activate() {
                getEvents().then(cb);

                $scope.$watchCollection(activeTabWatchFor, activeTabWatchHandler);
                $scope.$watchCollection(nodeJSWatchFor, nodeJSWatchHandler);

                checkNodeJSStatus();

                function cb() {
                    vm.filterEvents();

                    setUpdateTimer();
                }
            }

            function activeTabWatchFor() {
                return [vm.date, vm.activeTab];
            }

            function activeTabWatchHandler(newWatchForArr) {
                var date = newWatchForArr[0],
                    activeTab = newWatchForArr[1];

                if (activeTab === 'date' && date && date !== vm.dateLoaded) {

                    getEvents(date).then(function () {
                        vm.filterEvents();
                        vm.dateLoaded = date;
                    });
                } else if (activeTab) {
                    vm.filterEvents();
                }
            }

            function checkNodeJSStatus() {
                var timer = $interval(cb, 100, 100);

                function cb() {
                    var isOnline = RaceBetsJS.webSockets.nodeJS.isOnline();

                    if (isOnline) {
                        vm.isNodeJSReady = true;
                        $interval.cancel(timer);
                    }
                }
            }

            function filterCurrentAntepostEvents() {
                var antePostEvents,
                    antePostIdEvents,
                    currentEvents,
                    currentIdEvents,
                    events = getActiveEventsByCountry(),
                    eventsL = vm.eventsList,
                    eventsIdL = vm.eventsIdsList,
                    preFiltered = getAntePostFilter(),
                    filteredBy = getActiveFilters();

                for (var country in events) {
                    if (events.hasOwnProperty(country) && events[country].length) {
                        currentEvents = $filter('eventsByType')(events[country], 'current');
                        if (currentEvents && currentEvents.length) {
                            eventsL.current[country] = {};
                            eventsL.current[country].running = $filter('eventsByStartTime')(currentEvents, 'running');
                            eventsL.current[country].coming = $filter('eventsByStartTime')(currentEvents, 'coming');
                            eventsL.countries.current.push(country);

                            currentIdEvents = $filter('eventsByRaceType')(currentEvents, filteredBy);
                            if (currentIdEvents && currentIdEvents.length) {
                                eventsIdL.current[country] = {};
                                eventsIdL.current[country].running = getIds(
                                    $filter('eventsByStartTime')(currentIdEvents, 'running')
                                );
                                eventsIdL.current[country].coming = getIds(
                                    $filter('eventsByStartTime')(currentIdEvents, 'coming')
                                );
                                eventsIdL.countries.current[country] = true;
                                eventsIdL.countries.current.length += 1;
                            }
                        }

                        antePostEvents = $filter('eventsByType')(events[country], 'antepost');
                        if (antePostEvents && antePostEvents.length) {
                            eventsL.antepost[country] = antePostEvents;
                            eventsL.countries.antepost.push(country);

                            antePostIdEvents = $filter('eventsByRaceType')(antePostEvents, filteredBy);
                            antePostIdEvents = $filter('eventsBySpecials')(antePostIdEvents, preFiltered);

                            if (antePostIdEvents && antePostIdEvents.length) {
                                eventsIdL.antepost[country] = getIds(antePostIdEvents);
                                eventsIdL.countries.antepost[country] = true;
                                eventsIdL.countries.antepost.length += 1;
                            }
                        }
                    }
                }
                /* Sort countries array */
                eventsL.countries.current = sortCountries(eventsL.countries.current);
                eventsL.countries.antepost = sortCountries(eventsL.countries.antepost);
            }

            function filterDateEvents() {
                var dateEvents,
                    dateIdEvents,
                    events = getActiveEventsByCountry(),
                    filteredBy = getActiveFilters();

                for (var country in events) {
                    if (events.hasOwnProperty(country) && events[country].length) {
                        dateEvents = $filter('eventsByType')(events[country], 'date');
                        if (dateEvents && dateEvents.length) {
                            vm.eventsList.date[country] = dateEvents;
                            vm.eventsList.countries.date.push(country);

                            dateIdEvents = $filter('eventsByRaceType')(dateEvents, filteredBy);
                            if (dateIdEvents && dateIdEvents.length) {
                                vm.eventsIdsList.date[country] = getIds(dateIdEvents);
                                vm.eventsIdsList.countries.date[country] = true;
                                vm.eventsIdsList.countries.date.length += 1;
                            }
                        }
                    }
                }

                /* Sort countries array */
                vm.eventsList.countries.date = sortCountries(vm.eventsList.countries.date);
            }

            function filterVirtualEvents() {
                var dateEvents,
                    dateIdEvents,
                    events = getActiveEvents(),
                    filteredBy = getActiveFilters();

                vm.eventsList.virtuals = Object.keys(events).map(function(eventId) {
                    return events[eventId];
                });

                vm.eventsList.virtuals = $filter('eventsByType')(vm.eventsList.virtuals, 'virtuals');
                vm.eventsList.virtuals = $filter('eventsByRaceType')(vm.eventsList.virtuals, filteredBy);

            }

            function filterEvents() {
                var events = getActiveEventsByCountry();

                if (!events) {
                    return;
                }

                resetFilteredEvents(vm.activeTab);

                if (vm.activeTab === 'date') {
                    filterDateEvents();
                } else if (vm.activeTab === 'virtuals') {
                    filterVirtualEvents();
                } else {
                    filterCurrentAntepostEvents();
                }
            }

            function getIds(events) {
                var i = 0, l = events.length,
                    ids = {
                        length: 0
                    };

                for(; i < l; i++) {
                    ids[ events[i].id ] = true;
                    ids.length++;
                }

                return ids;
            }

            function getEvents(date) {
                if (loadingTimer) {
                    $timeout.cancel(loadingTimer);
                }
                vm.loading = true;

                return sidebarService.getEvents(date).then(function (events) {
                    if (date) {
                        vm.selectedDateEvents = events;
                    } else {
                        vm.events = events;
                    }

                    loadingTimer = $timeout(function () {
                        vm.loading = false;
                    }, 300);

                    return events;
                });
            }

            function getActiveEvents() {
                return vm.activeTab === 'date' ? vm.selectedDateEvents : vm.events;
            }

            function getActiveEventsByCountry() {
                var events = getActiveEvents();
                return groupEventsByCountry(events);
            }

            function getActiveFilters() {
                //if (vm.activeTab === 'antepost' && _.indexOf(vm.activeFilters, 'X') > -1) {
                    //return ['X'];
                //} else {
                    return vm.activeFilters;
                //}
            }

            function getAntePostFilter() {
                return vm.antePostFilter === 'specials';
            }

            function groupEventsByCountry(events) {
                var eventsByCountry = null;

                if (events) {
                    eventsByCountry = _.groupBy(events, function (eventObj) {
                        return eventObj.country;
                    });
                }

                return eventsByCountry;
            }

            function joinSidebarEventsCb(data) {
                if (!(data && data.events)) {
                    return;
                }

                $scope.$apply(function () {
                    sidebarService.updateCurrentAntepostEvents(data);
                    vm.filterEvents();
                });
            }

            function joinDateSidebarEventsCb(data) {
                if (!(data && data.events)) {
                    return;
                }

                $scope.$apply(function () {
                    sidebarService.updateDateEvents(data);
                    vm.filterEvents();
                });
            }


            function nodeJSWatchFor() {
                return [
                    sidebarService.getLastJSONGeneratedAt(),
                    sidebarService.getLastDateJSONGeneratedAt(),
                    vm.isNodeJSReady
                ];
            }

            function nodeJSWatchHandler(watchForArr) {
                var lastTimestamp = watchForArr[0],
                    lastDateTimestamp = watchForArr[1],
                    isNodeReady = watchForArr[2];

                if (isNodeReady) {
                    if (lastTimestamp) {
                        sidebarPushService.joinChannel(lastTimestamp, joinSidebarEventsCb);
                    }

                    if (lastDateTimestamp) {
                        sidebarPushService.joinDateChannel(lastDateTimestamp, joinDateSidebarEventsCb);
                    }
                }
            }

            function resetFilteredEvents(type) {
                if (type === 'date') {
                    vm.eventsList.date = {};
                    vm.eventsList.countries.date = [];

                    vm.eventsIdsList.date = {};
                    vm.eventsIdsList.countries.date = [];
                    vm.eventsIdsList.countries.date.length = 0;
                } else {
                    vm.eventsList.antepost = {};
                    vm.eventsList.current = {};
                    vm.eventsList.countries.current = [];
                    vm.eventsList.countries.antepost = [];

                    vm.eventsIdsList.antepost = {};
                    vm.eventsIdsList.current = {};
                    vm.eventsIdsList.countries.current = {};
                    vm.eventsIdsList.countries.current.length = 0;
                    vm.eventsIdsList.countries.antepost = {};
                    vm.eventsIdsList.countries.antepost.length = 0;
                }
            }

            function setUpdateTimer() {
                var clock = new Date($.now() - RaceBetsJS.application.user.timeDiff),
                    firstDelay = 60000 - clock.getSeconds() * 1000;

                $interval(function () {
                    update();
                    $interval(update, 60000);
                }, firstDelay, 1);

                function update() {
                    sidebarService.updateNextTimeRace(vm.events);
                    vm.filterEvents();
                }
                update();
            }

            function sortCountries(countries) {
                var countryOrder = RaceBetsJS.application.assets.countryOrder.getOrder(),
                    unsortedCountries = [];

                $.each(countries, function(key, country) {
                    if ($.inArray(country, countryOrder) === -1) {
                        unsortedCountries.push(countries[key]);
                        delete countries[key];
                    }
                });

                var result = _.sortBy(
                    countries,
                    $.proxy(function(val) {
                        return $.inArray(val, countryOrder);
                    }, this)
                );

                return _.union(result, unsortedCountries);
            }
        }

    }
})(angular);

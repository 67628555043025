(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbMinage', rbMinage);

    /**
     * @ngdoc directive
     * @name common.directive:rbMinage
     *
     * @restrict A
     * @requires ngModel
     * @element input
     *
     * @description
     *     Check date field against minimaum legal age
     *
     * @ngInject
     */
    function rbMinage(Settings, User){
        var directive = {
            require: '^ngModel',
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, elem, attrs, ctrls) {
            var currentDate = new Date(),
                minLegalAge,
                dateOfBirth,
                age;

            ctrls.$validators.minage = function(modelValue, viewValue) {
                if (!viewValue) {
                    return;
                }

                minLegalAge = attrs.rbMinage || Settings.minLegalAge( User.country() );
                dateOfBirth = viewValue.split('-');
                age = currentDate.getFullYear() - parseInt(dateOfBirth[0], 10);

                if (currentDate.getMonth() + 1 < dateOfBirth[1] ||
                    (currentDate.getMonth() + 1 === parseInt(dateOfBirth[1], 10) &&
                        currentDate.getDate() < parseInt(dateOfBirth[2], 10))) {
                    age -= 1;
                }

                return age >= parseInt(minLegalAge, 10);
            };
        }
    }
})();

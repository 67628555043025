(function () {
    'use strict';

    angular
        .module('common')
        .controller('AppLevelErrorCtrl', AppLevelErrorCtrl);

    /**
     * @ngdoc controller
     * @name common.controller:AppLevelErrorCtrl
     *
     * @requires $scope
     * @requires $routeParams
     *
     * @description
     *
     * @author Sandor Turanszky
     *
     * @ngInject
     */
    function AppLevelErrorCtrl ($scope, $routeParams) {
        $scope.code = $routeParams.code;
    }
})(angular);

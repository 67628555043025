(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbDisableAnimate', disableAnimate);

    /**
     * @ngdoc directive
     * @name  common.directive:rbDisableAnimate
     *
     * @restrict A
     *
     * @description
     *     Disable ngAnimate on an element
     *     (Related issue MNT-1399)
     *
     * @ngInject
     */
    function disableAnimate($animate) {
        var directive = {
            scope: false,
            restrict: 'A',
            link: link
        };

        return directive;

        function link(scope, element) {
            $animate.enabled(element, false);
        }
    }
})();

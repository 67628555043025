(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .service('sidebarState', sidebarState);

    /**
     * @ngdoc service
     * @name sidebar.service:sidebarState
     *
     * @requires  RaceBetsJS
     *
     * @ngInject
     */
    function sidebarState($rootScope, User, UserSession) {
        var session = UserSession.get('sidebar'),
            defaultSettings = {
                activeTab: 'current',
                filters: User.getRaceTypeFilters(),
                antePostFilter: 'general',
                current: [],
                antepost: [],
                date: []
            };

        activate();

        return {
            getActiveTab: getActiveTab,
            getAntePostFilter: getAntePostFilter,
            getFilters: getFilters,
            isCollapsed: isCollapsed,
            setActiveTab: setActiveTab,
            setFilters: setFilters,
            setAntePostFilter: setAntePostFilter,
            toggleCollapsed: toggleCollapsed
        };

        function activate() {
            if (!session || !session.expires || session.expires < new Date().getTime()) {
                session = defaultSettings;
                session.expires = expireDate();

                setCookie(session);
            }
        }

        function clearDateCollapsed() {
            session.date = [];
            setCookie(session);
        }

        function expireDate() {
            return (new Date().setHours(0, 0, 0, 0)) + 86400000;
        }

        function getActiveTab() {
            return defaultSettings.activeTab;
        }

        function getAntePostFilter() {
            return session.antePostFilter || defaultSettings.antePostFilter;
        }

        function getFilters() {
            return User.getRaceTypeFilters();
        }

        function isCollapsed(country, tab) {
            var index = session[tab] && _.indexOf(session[tab], country);

            if (tab === 'current') {
                return index === -1;
            }

            return index !== -1;
        }

        function setActiveTab(tab) {
            session.activeTab = tab;

            if (tab === 'date') {
                clearDateCollapsed();
            }

            setCookie(session);
        }

        function setCookie(cookie) {
            UserSession.set('sidebar', cookie);
        }

        function setFilters(filters) {
            session.filters = filters;
            setCookie(session);

            // Emit filter change
            $rootScope.$emit('Sidebar:filters:set', filters);
        }

        function setAntePostFilter(filter) {
            session.antePostFilter = filter;
            setCookie(session);
        }

        function toggleCollapsed(country, tab) {
            var index = session[tab] && _.indexOf(session[tab], country);

            if (index !== -1) {
                session[tab].splice(index, 1);
            } else {
                session[tab].push(country);
            }

            setCookie(session);

            return isCollapsed(country, tab);
        }
    }

})(angular);

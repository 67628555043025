(function () {
    'use strict';

    angular
        .module('common')
        .service('FileUpload', fileUpload);

    /**
     * @ngdoc factory
     * @name common.service:fileUpload
     *
     * @requires $q
     * @requires $rootScope
     * @reqiures $cookies
     * @requires $filter
     *
     * @description
     *     Handles API calls to server
     *
     * @ngInject
     */
    function fileUpload($q, $rootScope, $cookies, $filter) {
        var service = {
            post: post,
            validateType: validateType,
            validateSize: validateSize
        };

        return service;

        /**
         * @name common.service:fileUpload#post
         * @methodOf common.service:fileUpload
         *
         * @description
         *      XHR reuqest the classic way
         *
         * @param {Object} xhr XMLHttpRequest
         * @param {Object} files FileList
         * @param {Object} data Additional form data
         * @param {String} url API url
         *
         * @returns {Object} Promise object
         */
        function post(xhr, files, data, url) {
            var defer = $q.defer();

            if (!files || !files.length) {
                defer.reject({});
                return defer.promise;
            }

            var formData = new FormData();

            xhr.onload = function (e) {
                $rootScope.$apply(function () {
                    var ret = {
                        files: files
                    };
                    if (xhr.status === 201) {
                        defer.resolve(ret);
                    } else {
                        var respText = {
                            error: {
                                message: $filter('translate')('h_generic_error')
                            }
                        };
                        ret.data = xhr.responseText ? angular.fromJson(xhr.responseText) : respText;
                        defer.reject(ret);
                    }
                    return defer.promise;
                });
            };

            xhr.upload.onerror = function (e) {
                var msg = xhr.responseText ? xhr.responseText : $filter('translate')('h_generic_error');
                $rootScope.$apply(function () {
                    defer.reject(msg);
                    return defer.promise;
                });
            };

            xhr.upload.onprogress = function (e) {
                $rootScope.$apply(function () {
                    var percentCompleted;
                    if (e.lengthComputable) {
                        percentCompleted = Math.round(e.loaded / e.total * 100);
                        if (defer.notify) {
                            defer.notify(percentCompleted);
                        }
                    }
                });
            };

            if (data) {
                Object.keys(data).forEach(function (key) {
                    formData.append(key, data[key]);
                });
            }

            for (var flx = 0; flx < files.length; flx++) {
                var file = files[flx];
                for (var idx = 0; idx < file.length; idx++) {
                    formData.append('files[]', file[idx]);
                }
            }

            xhr.open('POST', url);

            // Include XSRF token
            xhr.setRequestHeader('X-XSRF-TOKEN', $cookies.get('XSRF-TOKEN'));

            xhr.send(formData);

            return defer.promise;
        }

        /**
         * @name common.service:fileUpload#validateType
         * @methodOf common.service:fileUpload
         *
         * @description
         *      Checks FileList if file matches give MIME type
         *
         * @param {Object} files FileList
         * @param {String} fileType MIME type
         *
         * @returns {Boolean} True if matches
         */
        function validateType(files, fileType) {
            var keys = Object.keys(files),
                i = 0,
                l = keys.length;

            for (i; i < l; i++) {
                if (fileType.includes(files[keys[i]].type)) {
                    return true;
                }
            }
            return false;
        }

        /**
         * @name common.service:fileUpload#validateSize
         * @methodOf common.service:fileUpload
         *
         * @description
         *      Checks FileList if file matches give MIME type
         *
         * @param {Object} files FileList
         * @param {String} limit File size in bytes
         *
         * @returns {Boolean} True if matches
         */
        function validateSize(files, limit) {
            var keys = Object.keys(files),
                i = 0,
                l = keys.length;

            for (i; i < l; i++) {
                if (files[keys[i]].size <= limit) {
                    return true;
                }
            }
            return false;
        }
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .filter('translateCountry', translateCountry);

    /**
     * @ngdoc filter
     * @name common.filter:translateCountry
     *
     * @requires RaceBetsJS
     *
     * @description
     *      Translates country code into country label
     *
     * @param iso Contry iso code
     *
     * @return {String} Country label
     */
    function translateCountry(RaceBetsJS) {
        return function(iso) {
            if (!iso) {
                return;
            }
            return RaceBetsJS.i18n.data.countries[iso];
        };
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('user')
        .factory('UserResource', UserResource);

    /**
     * @ngdoc service
     * @name user.service:UserResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Users API resource.
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function UserResource($resource, $location) {
        return $resource(
            '//' + $location.host() + '/rest/v2/users/me\/:group\/:action\/:method\/:detail',
            {},
            {
                updateEmail: {
                    method: 'PUT',
                    params: {
                        group: 'email'
                    }
                },

                confirmEmail: {
                    method: 'PUT',
                    params: {
                        group: 'email',
                        action: 'confirm'
                    }
                }
            }
        );
    }
})(angular);

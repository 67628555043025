(function (angular, _) {
    'use strict';

    angular
        .module('featuredHorses')
        .controller('HorsesAbroadCtrl', HorsesAbroadCtrl);

    /**
     * @ngdoc controller
     * @name featuredHorses.controller:HorsesAbroadCtrl
     *
     * @requires $scope
     * @requires $location
     * @requires countriesData
     * @requires FeaturedHorses
     * @requires HorsesAbroad
     * @requires RaceBetsJS
     *
     * @description
     *      Forgetten account details
     *
     * @ngInject
     */
    function HorsesAbroadCtrl($scope, $location, countriesData, FeaturedHorses, HorsesAbroad, RaceBetsJS) {
        var vm = this;

        /**
         * @ngdoc property
         * @name featuredHorses.controller:HorsesAbroadCtrl#loading
         * @propertyOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Loading indicator
         *
         * @type {Boolean}
         */
        vm.loading = true;

        /**
         * @ngdoc property
         * @name featuredHorses.controller:HorsesAbroadCtrl#countriesData
         * @propertyOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Countries data
         *
         * @type {Object|null}
         */
        vm.countriesData = null;

        /**
         * @ngdoc property
         * @name featuredHorses.controller:HorsesAbroadCtrl#filters
         * @propertyOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Active filters
         *
         * @type {Object|null}
         */
        vm.filters = null;

        /**
         * @ngdoc property
         * @name featuredHorses.controller:HorsesAbroadCtrl#noHorses
         * @propertyOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Show no horses state
         *
         * @type {Boolean|null}
         */
        vm.noHorses = null;

        /**
         * @ngdoc property
         * @name featuredHorses.controller:HorsesAbroadCtrl#selectedCountry
         * @propertyOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Country to be used in translations, updates only after horses requests
         *
         * @type {String|null}
         */
        vm.selectedCountry = null;


        // Wait for raceBetsJS
        RaceBetsJS.isInitDone().then(activate);

        /**
         * @ngdoc method
         * @name featuredHorses.controller:HorsesAbroadCtrl#activate
         * @private
         * @methodOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Activate view
         */
        function activate() {
            if (countriesData.error) {
                vm.error = countriesData.error.message;
                return;
            }

            // Set data
            vm.countriesData = countriesData;

            // Set filters
            vm.filters = setDefaultFilters(countriesData);

            // Check no horses
            vm.noHorses = checkHorsesNum(vm.filters);

            // Watch filter changes
            $scope.$watch('vm.filters', watchFilters, true);

            // Highlight main menu item
            RaceBetsJS.application.header.navigation.activateItemByKey('racing internationalrunners');

            // Get horses
            HorsesAbroad.getHorses(vm.filters.raceType, vm.filters.country, vm.filters.date)
                .then(
                    function(resp) {
                        vm.horsesData = resp;
                        vm.error = null;
                        vm.loading = false;
                    },
                    function(resp) {
                        vm.error = resp.error ? resp.error.message : resp;
                    }
                );
        }

        /**
         * @ngdoc method
         * @name featuredHorses.controller:HorsesAbroadCtrl#setDefaultFilters
         * @private
         * @methodOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Set filters with available options
         */
        function setDefaultFilters(data) {
            var availableRaceTypes = _.map(data, function(num, key) {
                        return key;
                    }
                ),
                selectedRaceType = FeaturedHorses.selectRaceType(HorsesAbroad.raceType(), availableRaceTypes),
                availableCountries = _.map(data[selectedRaceType], function(country) {
                   return country.iso2;
                }),
                selectedCountry = FeaturedHorses.selectCountry(HorsesAbroad.country(), availableCountries);

            vm.selectedCountry = selectedCountry;

            return {
                raceType: HorsesAbroad.raceType( selectedRaceType ),
                country: HorsesAbroad.country( selectedCountry ),
                date: HorsesAbroad.date()
            };
        }

        /**
         * @ngdoc method
         * @name featuredHorses.controller:HorsesAbroadCtrl#watchFilters
         * @private
         * @methodOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Filter changes callback
         *
         * @param {Object} newFilters Filters' state
         */
        function watchFilters(newFilters, oldFilters) {
            // Show loading
            vm.loading = true;

            // Cache filters
            HorsesAbroad.raceType(newFilters.raceType);
            HorsesAbroad.country(newFilters.country);
            HorsesAbroad.date(newFilters.date);

            // Update query params
            $location.search('type', newFilters.raceType);
            $location.search('country', newFilters.country);
            $location.search('date', newFilters.date);

            // Update countries data if date changes
            if (newFilters.date !== oldFilters.date) {
                HorsesAbroad.getCountries(newFilters.date)
                    .then(
                        function(resp) {
                            vm.countriesData = resp;
                            vm.noHorses = checkHorsesNum(newFilters);
                        },
                        function(resp) {
                            vm.error = resp.error ? resp.error.message : resp;
                        }
                    );
            }

            // Update horses data
            if (!angular.equals(newFilters, oldFilters)) {
                HorsesAbroad.getHorses(newFilters.raceType, newFilters.country, newFilters.date)
                    .then(
                        function(resp) {
                            vm.horsesData = resp;
                            vm.noHorses = checkHorsesNum(newFilters);
                            vm.selectedCountry = newFilters.country;
                            vm.error = null;
                            vm.loading = false;
                        },
                        function(resp) {
                            vm.error = resp.error ? resp.error.message : resp;
                        }
                    );
            }
        }

        /**
         * @ngdoc method
         * @name featuredHorses.controller:HorsesAbroadCtrl#checkHorsesNum
         * @private
         * @methodOf featuredHorses.controller:HorsesAbroadCtrl
         *
         * @description
         *     Check if selected country has horses
         *
         * @param {Object} filters Filters' state
         */
        function checkHorsesNum(filters) {
            var filteredCountry = _.filter(vm.countriesData[filters.raceType], function(country) {
                return country.iso2 === filters.country;
            });

            return (filteredCountry.length && filteredCountry[0].num_horses === 0);
        }

    }
})(angular, _);

(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbMaxamount', rbMaxamount);

    /**
     * @ngdoc directive
     * @name  common.directive:rbMaxamount
     *
     * @restrict A
     * @requires ngModel
     *
     * @description
     *     Validates field value bigger than or equal to the value
     *     given to this directive. If no value given validates as true.
     *
     */
    function rbMaxamount() {
        var directive = {
            scope: false,
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, elem, attrs, ctrls) {
            ctrls.$validators.maxamount = function(modelValue, viewValue) {
                if (!viewValue) {
                    return true;
                }
                var vValue = parseFloat(viewValue.toString().replace(/,/g,'.')),
                    maxAmount = parseFloat(attrs.rbMaxamount);
                
                return isNaN(maxAmount) ? true : (vValue <= maxAmount);
            };
        }
    }
})(angular);

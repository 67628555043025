(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbJustCurrency', justCurrency);

    /**
     * @ngdoc directive
     * @name common.directive:rbJustCurrency
     *
     * @restrict A
     * @requires ngModel
     * @element input
     *
     * @description
     *     Allow typing just integers with ',' and '.'
     *
     * @author Norbert Varga
     */
    function justCurrency(User) {
        var directive = {
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
            },
            numberFormats = User.numberFormats();

        return directive;

        function linkFunc($scope, element, attrs, ngModelCtrl) {

            ngModelCtrl.$parsers.unshift(function (newValue) {

                if (newValue) {
                    var digits = newValue.replace(/[^0-9\,\.]/g,'').replace(/[.,]/g, numberFormats.decimal_sep),
                        isValidCurrency = /^\d+[\.\,]?\d\d?$/.test(newValue),
                        dotPos = digits.indexOf(numberFormats.decimal_sep);

                    if (dotPos > -1 && !isValidCurrency) {
                        var replace = digits.substr(dotPos).replace(/[.,]/g,'').substr(0,2);
                        digits = digits.substr(0, dotPos+1) + replace;
                    }

                    ngModelCtrl.$viewValue = digits;
                    ngModelCtrl.$render();

                    return digits;
                }

                return newValue;
            });

            //element.on('blur', function (e) {
            //    if (this.value.indexOf(numberFormats.decimal_sep)+1 === this.value.length) {
            //        ngModelCtrl.$setViewValue(this.value.substr(0, this.value.length-1));
            //    }
            //});
        }
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbSupportWidget', rbSupportWidget);

    /**
     * @ngdoc directive
     * @name common.directive:rbSupportWidget
     *
     * @requires RaceBetsJS
     *
     * @description
     *     Support widget with live and other links
     *
     * @ngInject
     */
    function rbSupportWidget(RaceBetsJS) {
        return {
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/components/views/rb-support-widget.html',
            link: link
        };

        function link(scope, elem) {
            scope.helpLink = RaceBetsJS.application.assets.settings.getBrandSettings('supportURLs', 'contact');
        }
    }
})(angular);

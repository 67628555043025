(function () {
    'use strict';

    angular
        .module('common')
        .factory('SettingsResource', SettingsResource);

    /**
     * @ngdoc service
     * @name common.service:SettingsResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Users API resource.
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function SettingsResource ($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v2/settings/:group',
                {},
                {
                    registration: {
                            method: 'GET',
                            params: {
                                group: 'registration'
                            }
                        }
                });
    }
})();

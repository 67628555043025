(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbErrorTooltip', tooltip);

    /**
     * @ngdoc directive
     * @name  common.directive:rbErrorTooltip
     *
     * @restrict A
     * @requires ngModel
     * @requires $window
     * @requires $filter
     *
     * @description
     *
     *
     * @author Norbert Varga
     */
    function tooltip($window, $filter) {
        var directive = {
            scope: false,
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, elem, attrs, ngModelCtrl) {
            var Tipped = $window.Tipped,
                errorName = 'rb-error-tooltip: ',
                data,
                defaultOptions = {
                    hook: 'righttop',
                    hideOthers: true,
                    offset: { x: 2, y: 0 },
                    stem: {
                        offset: { x: 0, y: 6 },
                        width: 14,
                        height: 7
                    },
                    skin: 'dark form-field-hint',
                    padding: 0,
                    fadeIn: true,
                    showOn: false,
                    hideOn: false,
                    maxWidth: 250
                },
                // As we cannot use isolate scope for this directive, the attribute needs to be
                // evaluated as an object. scope.$eval does this quickly.
                // NOTE: This is not the same as native eval()!
                // For more info: http://stackoverflow.com/a/15671573/3420014
                tooltipMsgs = scope.$eval(attrs.rbErrorTooltip),
                userOptions = scope.$eval(attrs.tooltipOptions),
                form = elem.parents('form'),
                tippedElem;

            if ( !attrs.required && !tooltipMsgs) {
                console.log('Element: ', elem);
                throw new Error(errorName + 'Error message array cannot be empty if no required attribute provided!');
            }

            if ( userOptions && angular.isObject(userOptions)) {
                defaultOptions = angular.extend(defaultOptions, userOptions );
            }

            form.on('submit', function () {
                var firstInvalid = form.find('.ng-invalid').first();

                createTooltip();

                if (firstInvalid) {
                    firstInvalid.trigger('focus');
                }
            });

            elem.parent()
                .on('focus', '.submitted', function () {
                    if ( tippedElem && ngModelCtrl.$invalid) {
                        tippedElem.show();
                    }
                })
                .on('blur', '.submitted', function () {
                    Tipped.get(elem).hide();
                });

            elem.on('keyup change', function () {
                if ( elem.hasClass('ng-invalid') ) {
                    createTooltip();

                    if ( elem.hasClass('submitted') ) {
                        tippedElem.show();
                    }
                }
                else {
                    Tipped.get(elem).hide();
                }
            });

            function createTooltip() {
                // @TODO: refactor this, 'value' is not reusable in other cases where aditional information needed
                // reevaluate attributes if data.value is undefined
                if ( tooltipMsgs && tooltipMsgs.data ) {
                    tooltipMsgs = scope.$eval(attrs.rbErrorTooltip); // refresh passed options
                    data = tooltipMsgs.data;
                }

                if ( ngModelCtrl.$invalid ) {
                    tippedElem = Tipped.create(elem, buildList(tooltipMsgs), defaultOptions);

                    return tippedElem;
                }
            }

            function buildList (obj) {
                var list = [];

                list.push('<ul class="angular error-container">');

                if ( attrs.required && ngModelCtrl.$error.required ) {
                    list.push('<li class="error-message">' + $filter('translate')('msg_error_required_field', data) + '</li>');
                }
                else {
                    for (var type in tooltipMsgs) {
                        if ( tooltipMsgs.hasOwnProperty(type) ) {
                            if ( ngModelCtrl.$error[type] ) {

                                list.push('<li class="error-message">' + $filter('translate')(obj[type], data) + '</li>');
                            }
                        }
                        else {
                            throw new Error('Error tooltip: message key not defined!');
                        }
                    }
                }

                list.push('</ul>');

                return list.join('');
            }
        }
    }
})();

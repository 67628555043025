(function (angular) {
    'use strict';

    angular
        .module('user')
        .factory('UserMarketing', Service);

    /**
     * @ngdoc service
     * @name user.service:UserMarketing
     *
     * @requires $q
     * @requires $cookies
     * @requires User
     * @requires UserMarketingResource
     * @requires Settings
     * @requires RaceBetsJS
     *
     * @description
     *     User service to handle user marketing settings actions
     *
     * @ngInject
     */
    function Service($q, $cookies, User, UserMarketingResource, Settings, RaceBetsJS) {
        var se = this;

        se.consentCookie = 'CONSENT';
        se.useThirdPartyConsent = !raceBetsJS.application.globals.rbConsent;
        se.sendEvent = false;
        se.globals = raceBetsJS.application.globals;
        se.user = raceBetsJS.application.user;
        se.consentYears = User.isLoggedIn() ? 5 : 2;
        se.defaultConsents = {
            brand: null,
            group: null,
            thirdparty: null,
            cookie: null,
            mailings: null,
            sms: null,
            phone: null
        };
        se.thirdPartyCookieNames = {
            targeting: se.globals.targetingCookieNameMap,
            functional: se.globals.functionalCookieNameMap,
            performance: se.globals.performanceCookieNameMap,
        }
        se.countries = se.globals.performanceCookieDefaultValueOverride.map(function(country) {
            return country.toUpperCase();
        });
        se.countryMatch = se.countries.indexOf(se.user.ipCountry.toUpperCase()) > -1;

        var service = {
            getSettings: getSettings,
            updateSettings: updateSettings,
            getCookie: getCookie,
            setCookie: setCookie,
            getDefaultConsents: getDefaultConsents,
            setCookieFromThirdParty: setCookieFromThirdParty
        };

        return service;

        /**
         * @name common.service:UserMarketing#getSettings
         * @methodOf common.service:UserMarketing
         *
         * @description
         *     Get user's marketing settings
         *
         * @returns {Object} Promise object
         */
        function getSettings() {
            var deferred = $q.defer();

            UserMarketingResource.get().$promise.then(
                function (resp) {
                    deferred.resolve(resp.data);
                },
                function (resp) {
                    deferred.reject(resp.data);
                }
            );

            return deferred.promise;
        }

        /**
         * @name common.service:UserMarketing#updateSettings
         * @methodOf common.service:UserMarketing
         *
         * @description
         *          Update user's marketing settings
         *
         * @returns {Object} Promise object
         */
        function updateSettings(settings) {
            var deferred = $q.defer();

            UserMarketingResource.update(settings).$promise.then(
                function (resp) {
                    deferred.resolve(resp.data);
                },
                function (resp) {
                    deferred.reject(resp.data);
                }
            );

            return deferred.promise;
        }

        /**
         * @name common.service:UserMarketing#getCookie
         * @methodOf common.service:UserMarketing
         *
         * @description
         *          Get marketing consent cookie
         *
         * @returns {Object}
         */
        function getCookie() {
            var marketingCookie = $cookies.get(se.consentCookie);
            if (_.isUndefined(marketingCookie)) {
                return {}
            } else {
                return JSON.parse(decodeURIComponent(marketingCookie))
            }
        }

        /**
         * @name common.service:UserMarketing#isAllRejected
         * @methodOf common.service:isAllRejected
         *
         * @description
         *          Check if all cookies rejected
         *
         * @returns {Boolean}
         */
         function isAllRejected(consents) {
            var allRejected = false;
            var result = Object.keys(consents).filter(function(key) {
                return consents[key] === 1;
            });

            // For countries specified in performanceCookieDefaultValueOverride, e.g. Germany, performance cookie can be rejected. It means that all values can be set to "0"
            if(se.countryMatch) {
                allRejected = result.length === 0;
            } else {
                // For the rest EU countries performance cookie is alway "1"
                allRejected = result.length === 1;
            }

            return allRejected;
        }

        /**
         * @name common.service:UserMarketing#trackEvents
         * @methodOf common.service:trackEvents
         *
         * @description
         *          Send GA events
         */
        function trackEvents(consents, accepted) {
            var allRejected = isAllRejected(consents);
            // Track event - will be deprecated after OneTrust is implemented
            if (consents.marketing === 1) {
                dataLayer.push({
                    'event': 'MarketingConsent'
                });
            }

            // Track event - active starting from the 1st of April 2022
            // Resend event only if settings changed.

            if(se.useThirdPartyConsent && se.sendEvent) {
                var data = {
                    'gtm_is_cookie_consent_disabled': RaceBetsJS.application.assets.settings.isGDPRdisabled().toString(),
                    'gdpr_performance_enabled': consents.performance.toString(),
                    'gdpr_targeting_enabled': consents.targeting.toString(),
                    'gdpr_functional_enabled': consents.functional.toString()
                };

                var defaults = overridePerformanceCookieValue(getCookieObjectDefaultValues());

                if(allRejected) {
                    dataLayer.push(data);
                } else {
                    if(accepted.performance && accepted.performance !== defaults.performance) {
                        data.GDPRPerformanceAccepted = '1';
                    }

                    if(accepted.targeting && accepted.targeting !== defaults.targeting) {
                        data.GDPRTargetingAccepted = '1';
                    }

                    if(accepted.functional && accepted.functional !== defaults.functional) {
                        data.GDPRFunctionalAccepted = '1';
                    }

                    data.event = 'GDPRConsent';

                    dataLayer.push(data);
                }
            }
        }

        /**
         * @name common.service:UserMarketing#consentDiff
         * @methodOf common.service:consentDiff
         *
         * @description
         *          Find which cookies were accepted compared to the previous state
         *
         * @returns {Object}
         */
        function consentDiff(existingCookie, consents) {
            var accepted = {};
            Object.keys(consents).forEach(function(key) {
                if(existingCookie[key] !== consents[key] && consents[key] === 1) {
                    accepted[key] = 1
                }
            });

            return accepted;
        }

        /**
         * @name common.service:UserMarketing#setCookie
         * @methodOf common.service:UserMarketing
         *
         * @description
         *          Update marketing consent cookie
         *
         * @returns {Object}
         */
        function setCookie(consents) {
            var existingCookie = getCookie();
            consents = prepareCookieObject(consents, existingCookie);
            var date = new Date();
            var accepted = {};
            date.setYear(date.getFullYear() + se.consentYears)
            se.sendEvent = !angular.equals(existingCookie, consents);

            // If the previous selection is different from th new one, we need to se what changes
            if(se.sendEvent) accepted = consentDiff(existingCookie, consents);

            // set marketing property to be same as targeting
            consents.marketing = consents.targeting;

            if (existingCookie) {
                angular.extend(existingCookie, consents);
            } else {
                existingCookie = consents;
            }

            $cookies.put(se.consentCookie, JSON.stringify(existingCookie), {
                path: '/',
                domain: RaceBetsJS.application.assets.settings.getTldCookieDomain(),
                expires: date,
                secure: true
            });

            // Track event
            trackEvents(consents, accepted);
        }

        /**
         * @name common.service:UserMarketing#getDefaultConsents
         * @methodOf common.service:getDefaultConsents
         *
         * @description
         *          Get default marketing consent object
         *
         * @returns {Object}
         */
        function getDefaultConsents() {
            return se.defaultConsents;
        }


        /**
         * @name common.service:UserMarketing#getCookieObjectDefaultValues
         * @methodOf common.service:getCookieObjectDefaultValues
         *
         * @description
         *          Returns default values for cookies from the settings
         *
         * @returns {Object}
         */
         function getCookieObjectDefaultValues() {
            return { 'functional':se.globals.functionalCookieDefaultValue,
                     'marketing':se.globals.marketingCookieDefaultValue,
                     'performance':se.globals.performanceCookieDefaultValue,
                     'targeting':se.globals.targetingCookieDefaultValue };
        }

        /**
         * @name common.service:UserMarketing#overridePerformanceCookieValue
         * @methodOf common.service:overridePerformanceCookieValue
         *
         * @description
         *          Overrides default performance cookie value for the selected countries
         *
         * @returns {Object}
         */
         function overridePerformanceCookieValue(consents) {
            var newValue = 0;

            return se.countryMatch ? angular.extend(consents, { 'performance': newValue } ) : consents;
        }

        /**
         * @name common.service:UserMarketing#prepareCookieObject
         * @methodOf common.service:prepareCookieObject
         *
         * @description
         *          Generate cookie object with the default necessary properties and values
         *          and override default values if needed
         *
         * @returns {Object}
         */
        function prepareCookieObject(consents, existingCookie) {
            return angular.extend(overridePerformanceCookieValue(getCookieObjectDefaultValues()), existingCookie, consents || {});

        }

        /**
         * @name common.service:UserMarketing#setCookieFromThirdParty
         * @methodOf common.service:setCookieFromThirdParty
         *
         * @description
         *          Reads cookie values from third party cookie provider
         *
         * @returns {Object}
         */
         function setCookieFromThirdParty(props) {
            var updatedConsent = {};

            Object.keys(se.thirdPartyCookieNames).forEach(function(key) {
                updatedConsent[key] = props.indexOf(se.globals[key+'CookieNameMap']) > -1 ? 1 : 0;
            });

            setCookie(updatedConsent);
        }
    }
})(angular);

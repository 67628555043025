(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbCaretControl', caretControl);

    /**
     * @ngdoc directive
     * @name  common.directive:rbCaretControl
     *
     * @restrict A
     * @requires ngModel
     * @element input
     * @element textarea
     *
     * @description
     *     Controls the caret position, when the field has formatters and we would like to
     *     allow continious modification within the value.
     *     Covers caret move by click, arrows, delete and backspace modifications,
     *     but not CTRL+C, CTRL+V.
     *
     *     Note: This cannot be used on number type element!!!
     *
     * @author Norbert Varga
     */
    function caretControl() {
        var directive = {
            scope: false,
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, elem, attrs, ngModelCtrl) {
            var field = elem[0],
                newPos = false,
                lastCaretPos = 0;

            // push to $parsers, be to sure, it's the last one to run
            ngModelCtrl.$parsers.push(setCaretPosition);

            function setCaretPosition(viewValue) {
                if (newPos) {
                    if ('selectionStart' in field) {
                        field.selectionStart = lastCaretPos;
                        field.selectionEnd = lastCaretPos;
                    }
                    else if (field.createTextRange) { // IE < 9
                        var fieldRange = field.createTextRange();

                        fieldRange.move('character', lastCaretPos);
                        fieldRange.select ();
                    }

                    lastCaretPos++;
                }

                return viewValue;
            }

            elem.on('keydown', function (e) {
                switch ( e.which ) {
                    case 1: // Left mouse button
                    case 39: // Right arrow
                        if (ngModelCtrl.$dirty) {
                            lastCaretPos = field.selectionStart+1;
                            newPos = true;
                        }
                        break;
                    case 8: // Backspace
                        lastCaretPos = field.selectionStart-1;
                        newPos = true;
                        break;
                    case 37: // Left arrow
                        lastCaretPos = field.selectionStart;
                        newPos = true;
                        break;
                    case 46: // Delete
                        lastCaretPos = field.selectionStart+ ( field.value[field.selectionStart] === ' ' ? 1 : 0 );
                        newPos = true;
                        break;
                    default:
                        break;
                }
            });

            elem.on('keyup', function (e) {
                if ( [1,8,37,39,46].indexOf(e.which) === -1 && field.value[field.selectionStart-1] === ' ' ) {
                    lastCaretPos++;
                    field.selectionStart++;
                }

                // if field is cleared (making sure not occure when just selected)
                if ( field.value.length === 0 && !field.value[field.selectionStart-1] ) {
                    newPos = false;
                    lastCaretPos = 0;
                }
            });

            $scope.$on('$destroy', function () {
                elem.off('keydown click');
            });
        }
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('user')
        .factory('UserBonuses', UserBonusesService);

    /**
     * @ngdoc service
     * @name user.service:UserBonuses
     *
     * @requires RaceBetsJS
     *
     * @description
     *     User service to handle Bonuses
     *
     * @ngInject
     */
    function UserBonusesService ($filter, $window, $location, RaceBetsJS) {
        var service = {
            set: setBonus,
            hasBonus: hasBonus,
            hasBonusAvailable: hasBonusAvailable
        };

        return service;

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#setBonus
         * @public
         * @methodOf user.service:UserBonuses
         */
        function setBonus(data) {
            if (!data.bonus) {
                // Remove Bonuses
                setSession();

                return;
            }

            var session = getSession();

            // Show new bonus dialog except on bonus page to prevent bonus update message if user entered a bonus code
            if (session && data.bonus.pending > session.pending && !(/offers\/bonuses/).test($location.path())) {
                var dialogContent = $filter('translate')('msg_bonus_received') + ' ';
                dialogContent += (data.bonus.status === '') ? $filter('translate')('msg_redeem_now_q') : $filter('translate')('msg_view_bonuses_q');

                showDialog(dialogContent);
            }

            // Saves pending bonuses to session
            // Unlike UserFreebets service, getSession always returns this object
            setSession({
                pending: data.bonus.pending,
                generated_at: data.generated_at
            });
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#hasBonus
         * @public
         * @methodOf user.service:UserBonuses
         *
         * @return {Boolean} - boolean if user has any bonuses
         */
        function hasBonus(data) {
            if (data) {
                return (data.bonus.pending > 0 || data.bonus.balance > 0);
            } else {
                var session = getSession();
                return (session.pending > 0 || session.balance > 0);
            }
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#hasBonusAvailable
         * @public
         * @methodOf user.service:UserBonuses
         *
         * @return {Boolean} - boolean if user has pending bonuses and no bonus is active
         */
        function hasBonusAvailable(data) {
            if (data) {
                return (data.bonus.pending > 0 && data.bonus.status === '');
            } else {
                var session = getSession();
                return (session.pending > 0 && session.status === '');
            }
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#getSession
         *
         * @methodOf user.service:UserBonuses
         */
        function getSession() {
            return RaceBetsJS.application.assets.session.get('bonus');
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#setSession
         *
         * @methodOf user.service:UserBonuses
         */
        function setSession(session) {
            RaceBetsJS.application.assets.session.set('bonus', session || false);
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#showDialog
         *
         * @methodOf user.service:UserBonuses
         */
        function showDialog(content) {
            RaceBetsJS.application.assets.modalDialog.show({
                type: 'attention',
                content: content || '',
                closeButton: false,
                buttons: [
                    {
                        label: $filter('translate')('label_no'),
                        action: function() {
                            RaceBetsJS.application.assets.overlay.close();
                        }
                    },
                    {
                        label: $filter('translate')('label_yes'),
                        action: function() {
                            RaceBetsJS.browser.history.navigateTo(RaceBetsJS.application.globals.urlPrefix + '/offers/bonuses');
                            RaceBetsJS.application.assets.overlay.close();
                        },
                        active: true
                    }
                ]
            });
        }
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .factory('ToolsResource', ToolsResource);

    /**
     * @ngdoc service
     * @name common.service:ToolsResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Registration API resource.
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function ToolsResource ($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v2/tools/:tool/:action', {},
            {
                iban: {
                    method: 'GET',
                    params: {
                        tool: 'iban',
                        action: '@action'
                    }
                }

            });
    }
})();

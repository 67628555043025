(function () {
    'use strict';

    angular
        .module('common')
        .factory('UsersResourceV2', UsersResource);

    /**
     * @ngdoc service
     * @name common.service:UsersResourceV2
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Users API resource.
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function UsersResource ($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v2/users', {},
            {
                register: {
                    method: 'POST'
                },

                exist: {
                    method: 'GET',
                    params: {
                        username: '@username'
                    }
                }
            }
        );
    }
})();

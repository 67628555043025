(function (angular) {
    'use strict';

    angular
        .module('featuredHorses')
        .factory('HorsesAbroad', HorsesAbroadService);

    /**
     * @ngdoc service
     * @name featuredHorses.service:horsesAbroadService
     *
     * @requires $q
     * @requires HorsesAbroadResource,
     * @requires RaceBetsJS
     *
     * @description
     *     Handles Next Race API call to server, and NodeJS join
     *
     * @ngInject
     */
    function HorsesAbroadService($q, HorsesAbroadResource, RaceBetsJS) {
        var se = this;

        var service = {
            getCountries: getCountries,
            getHorses: getHorses,
            raceType: raceType,
            country: country,
            date: date
        };

        se.selectedDate = RaceBetsJS.format.date('Y-m-d', Math.round(new Date().getTime()/1000));

        return service;

        /**
         * @name featuredHorses.service:horsesAbroadService#getCountries
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Gets horses abroad countries available
         *
         * @param {String} date Date string in YYYY-MM-DD format
         *
         * @returns {Object} Promise object
         */
        function getCountries(date) {
            var _countries = $q.defer();

            HorsesAbroadResource
                .getCountries({
                    date: date || RaceBetsJS.format.date('Y-m-d', Math.round(new Date().getTime()/1000))
                }).$promise
                .then(
                    function (resp) {
                        _countries.resolve(JSON.parse(angular.toJson(resp)));
                    },
                    function (resp) {
                        _countries.reject(resp.data);
                    }
                );

            return _countries.promise;
        }

        /**
         * @name featuredHorses.service:horsesAbroadService#getHorses
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Gets horses abroad list
         *
         * @param {String} raceType
         * @param {String} country
         * @param {String} date
         *
         * @returns {Object} Promise object
         */
        function getHorses(raceType, country, date) {
            var _horses = $q.defer();

            HorsesAbroadResource
                .get({
                    race_type: raceType,
                    country: country,
                    date: date,
                })
                .$promise
                .then(
                    function (resp) {
                        _horses.resolve(JSON.parse(angular.toJson(resp)));
                    },
                    function (resp) {
                        _horses.reject(resp.data);
                    }
                );

            return _horses.promise;
        }

        /**
         * @name featuredHorses.service:horsesAbroadService#raceType
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Gets or sets selected race type filter
         *
         * @param {String} raceType
         *
         * @returns {String}
         */
        function raceType(raceType) {
            if (raceType) {
                se.selectedRaceType = raceType;
                return se.selectedRaceType;
            }

            return se.selectedRaceType;
        }

        /**
         * @name featuredHorses.service:horsesAbroadService#country
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Gets or sets selected country filter
         *
         * @param {String} country
         *
         * @returns {String}
         */
        function country(country) {
            if (country) {
                se.selectedCountry = country;
                return se.selectedCountry;
            }

            return se.selectedCountry;
        }

        /**
         * @name featuredHorses.service:horsesAbroadService#date
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Gets or sets selected date filter
         *
         * @param {String} date Date string in YYYY-MM-DD format
         *
         * @returns {String}
         */
        function date(date) {
            if (date) {
                se.selectedDate = date;
                return se.selectedDate;
            }

            return se.selectedDate;
        }
    }
})(angular);

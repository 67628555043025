(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbFormGroup', rbFormGroup);

    /**
     * @ngdoc directive
     * @name common.directive:rbFormGroup
     *
     * @restrict E
     * @element form
     *
     * @description
     *
     *  # rbFormGroup
     *      The {@link common.directive:rbFormGroup rbFormGroup} directive is used to a specific behavior to a group of form fields.
     *      The content inside the directive will be wrapped into a 'div' element with a 'form-group' class.
     *
     * @ngInject
     */
    function rbFormGroup() {
        return {
            require: '^form',
            replace: true,
            restrict: 'E',
            template: '<div class="c-form__group" ng-transclude></div>',
            transclude: true,
            link: link
        };

        function hasErrors(errors) {
            for (var error in errors) {
                if (errors.hasOwnProperty(error) && errors[error]) {
                    return true;
                }
            }

            return false;
        }

        function link(scope, elem, attrs, ctrl) {
            var formName = ctrl.$name,

                fieldName = attrs['for'],
                fieldExp = formName + '.' + fieldName,

                watchFieldExp = fieldExp + '.$invalid && ' + fieldExp + '.$dirty',
                watchSubmitExp = formName + '.$submitted';

            scope.$watch(watchFieldExp, watchForErrors);
            scope.$watch(watchSubmitExp, watchSubmit);

            function watchForErrors() {
                var field = scope[formName][fieldName],
                    errors = field && field.$error;

                if (field && field.$pristine === false) {
                    showErrors(elem, errors);
                }
            }

            function watchSubmit(newValue) {
                var field = scope[formName][fieldName],
                    errors = field && field.$error;

                if (field && newValue) {
                    field.$dirty = true;
                    showErrors(elem, errors);
                }
            }
        }

        function showErrors(elem, errors) {
            if (hasErrors(errors)) {
                elem.addClass('hasError');
            } else {
                elem.removeClass('hasError');
            }
        }
    }
})(this.angular);

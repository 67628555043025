(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .service('sidebarPushService', sidebarPushService);

    /**
     * @ngdoc service
     * @name sidebar.service:sidebarPushService
     *
     * @requires sidebarService
     * @requires RaceBetsJS
     *
     * @author Bogdan Gersak
     */
    function sidebarPushService(sidebarService, RaceBetsJS) {
        /*jshint validthis: true */
        var sp = this;

        sp.currAnteChannelTimestamp = null;

        sp.dataChannelTimestamp = null;
        sp.dataChannelStarttime = null;
        sp.dataChannelSubscription = null;

        return {
            joinChannel: join,
            joinDateChannel: joinDateChannel
        };

        function join(timestamp, cb) {
            var options = {
                channel: 'node_sidebarEvents',
                callback: cb,
                timestamp: timestamp
            };

            if (sp.currAnteChannelTimestamp !== timestamp) {
                RaceBetsJS.webSockets.nodeJS.join(options);

                sp.currAnteChannelTimestamp = timestamp;
            }
        }

        function joinDateChannel(dateTimestamp, cb) {
            var timestamp = dateTimestamp.split('_'),
                jsonTimestamp = timestamp[0],
                dateStartTime = timestamp[1],
                options = {
                    channel: 'node_sidebarEvents_' + dateStartTime,
                    callback: cb,
                    timestamp: jsonTimestamp
                };

            if (sp.dataChannelTimestamp !== jsonTimestamp || sp.dataChannelStarttime !== dateStartTime) {
                leaveDateChannel();

                sp.dataChannelSubscription = RaceBetsJS.webSockets.nodeJS.join(options);
                sp.dataChannelTimestamp = jsonTimestamp;
            }
        }

        function leaveDateChannel() {
            if (sp.dataChannelSubscription) {
                RaceBetsJS.webSockets.nodeJS.leave(sp.dataChannelSubscription);
                sp.dataChannelSubscription = null;
            }
        }
    }
})(this.angular);

(function (angular) {
    'use strict';

    angular
        .module('user')
        .service('UserBalance', userBalance);

    /**
     * @ngdoc service
     * @name user.service:UserBalance
     *
     * @requires $rootScope
     * @requires $q
     * @requires $timeout
     * @requires UserBalanceResource
     * @requires UserBalancePushEvents
     * @requires UserFreebets
     * @requires UserBonuses
     * @requires RaceBetsJS
     *
     * @ngInject
     */
    function userBalance($rootScope, $q, $timeout, $interval, $injector, UserBalanceResource, UserBalancePushEvents,
        UserFreebets, RaceBetsJS, Auth) {
        var se = this;
        var deferred,
            balanceData = {};

        if (!$rootScope.isB2B) {
            se.userBonuses = $injector.get('UserBonuses');
        }

        se.userBalancePushEvents = $injector.get('UserBalancePushEvents');

        return {
            getBalance: getBalance
        };

        /**
         * @ngdoc method
         * @name user.service:UserBalance#getBalance
         * @public
         * @methodOf user.service:UserBalance
         *
         * @description
         *     Gets user's balance, and returns a promise (cached from the second call, or forced).
         *
         * @returns {Object} Promise object
         */
        function getBalance(force) {
            if (deferred && !force) {
                return returnCachedBalance();
            }

            deferred = $q.defer();

            UserBalanceResource
                .get().$promise
                .then(
                    function (resp) {
                        // Save balance data
                        balanceData = resp.data;

                        // Resolve response
                        deferred.resolve(resp.data);

                        // Set Freebets
                        UserFreebets.set(resp.data);

                        // Subscribe to user balance updates
                        if (!force) {
                            checkNodeJSStatus();
                        }

                        if (!$rootScope.isB2B) {
                            // Set Bonuses
                            se.userBonuses.set(resp.data);

                            // Backward compatibility: publish first loginbox info
                            RaceBetsJS.application.header.login.updateLoginInfo(resp, (!force) ? true : false);
                        }

                        // Emit balance update if it was a forced update call
                        if (force) {
                            $rootScope.$emit('User:balance:update', balanceData);
                        }
                    },
                    function(resp) {
                        // Reject response
                        deferred.reject(resp.data);

                        // Handle response messages
                        handleBalanceErrorResponse(resp.data);
                    }
                );

            return deferred.promise;
        }

        /**
         * @ngdoc method
         * @name user.service:UserBalance#returnCachedBalance
         * @private
         * @methodOf user.service:UserBalance
         *
         * @description
         *     Creates a dummy promise to return balance data
         *
         * @returns {Object} Promise object
         */
        function returnCachedBalance() {
            var deferredMockUp = $q.defer();

            deferred.promise.then(function() {
                deferredMockUp.resolve(balanceData);
            });

            return deferredMockUp.promise;
        }

        /**
         * @ngdoc method
         * @name user.service:UserBalance#checkNodeJSStatus
         * @private
         * @methodOf user.service:UserBalance
         *
         * @description
         *     Connects to user's channel if Node is ready
         *
         */
        function checkNodeJSStatus() {
            var timer = $interval(cb, 100, 600);

            function cb() {
                var isOnline = RaceBetsJS.webSockets.nodeJS.isOnline();

                if (isOnline) {
                    se.userBalancePushEvents.join(updateCallback);
                    $interval.cancel(timer);
                }
            }
        }

        /**
         * @ngdoc method
         * @name user.service:UserBalance#updateCallback
         * @private
         * @methodOf user.service:UserBalance
         *
         * @description
         *     Callback for push updates
         */
        function updateCallback(channel, message) {

            switch (channel) {
                case 'node_balanceInfo':
                    // Cancel fallback call
                    if ($rootScope.balanceRequest) {
                        $timeout.cancel($rootScope.balanceRequest);
                    }

                    // Update balanca data with new data
                    balanceData = message;

                    // Set Freebets
                    UserFreebets.set(message);

                    if (!$rootScope.isB2B) {
                        // Set Bonuses
                        se.userBonuses.set(message);
                    }

                    if (RaceBetsJS.application.header.navigation.updateBonusInfo) {
                        RaceBetsJS.application.header.navigation.updateBonusInfo(balanceData)
                    }

                    // Emit change of user's balance
                    $rootScope.$emit('User:balance:update', balanceData);
                    break;

                case 'node_nextBetslip':
                    // Cancel fallback call
                    if ($rootScope.balanceRequest) {
                        $timeout.cancel($rootScope.balanceRequest);
                    }

                    // Update or remove next bet object
                    if (message.next) {
                        balanceData.bets.next = message.next;
                    } else {
                        delete balanceData.bets.next;
                    }

                    // Emit change of user's balance
                    $rootScope.$emit('User:balance:update', balanceData);
                    break;

                case 'logout':
                    // Log out user
                    if (!RaceBetsJS.application.assets.overlay.isOpen('success lockout')) {
                        RaceBetsJS.application.assets.dialogs.loginDialog.show({
                            type: 'loginTimeout'
                        });
                    }
                    break;
            }
        }

        /**
         * @ngdoc method
         * @name user.service:UserBalance#handleBalanceErrorResponse
         * @private
         * @methodOf user.service:UserBalance
         *
         * @description
         *     Handles error responses
         */
        function handleBalanceErrorResponse(resp) {
            if (!resp || !resp.data) {
                return;
            }

            if ($rootScope.isB2B) {
                return;
            }

            switch (resp.data.code) {
                // Account not active
                case 131:
                    Auth.logout();
                    break;
            }
        }
    }
})(angular);

(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbTooltip', rbTooltip);

    /**
     * @ngdoc directive
     * @name common.directive:rbTooltip
     *
     * @requires $window
     * @restrict A
     *
     * @description
     *      The `rbTooltip` directive opens a Tipped tooltip when mouseenter
     *      event is triggered and hide the tooltip when mouseleave is triggered.
     *
     *      The `rbTooltip` directive accepts a message as parameter to be displayed inside the tooltip.
     *
     * @param {Object} rbTooltip Tipped tooltip
     *
     * @ngInject
     */
    function rbTooltip($window, $timeout, $translate) {
        return {
            link: link,
            restrict: 'A'
        };

        function link(scope, elem, attrs) {
            var tipped = $window.Tipped || window.Tipped,
                options = {
                    hook: 'topmiddle',
                    skin: 'racebets',
                    zIndex: 10000
            };

            createTipped();

            function createTipped() {
                $timeout(function () {
                    tipped.create(elem, attrs.rbTooltip, options);
                }, 2000);
            }
        }
    }
})(angular);

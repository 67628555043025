(function (angular) {
    'use strict';

    angular
        .module('common')
        .factory('Auth', AuthService);

    /**
     * @ngdoc service
     * @name account.service:AuthService
     *
     * @requires $q
     * @requires $window
     * @requires $cookies
     * @requires AuthResource
     * @requires User
     * @requires RaceBetsJS
     * @requires Settings
     * @requires GoogleTagManager
     *
     * @ngInject
     */
    function AuthService($q, $window, $filter, $cookies, AuthResource, User, RaceBetsJS, Settings, GoogleTagManager) {

        //store login data temporary to resend it when user accepts the new terms.
        var dataToResend;
        return {
            login: login,
            logout: logout,
            login2fa: login2fa
        };

        function login (data) {
            if (RaceBetsJS.application.globals.isB2B) {
                return;
            }

            var deferred = $q.defer();
            dataToResend = data;
            // Show full page loading layer
            RaceBetsJS.application.assets.overlay.showPleaseWait();

            // Append ThreadMatrix session ID
            data.tmSessionId = User.getTMSessionID();

            // Call resource
            AuthResource
                .login({}, data).$promise
                .then(
                    function(resp) {
                        // Resolve response
                        deferred.resolve(resp.data);

                        if(RaceBetsJS.application.globals.brandName === 'racebets') {
                            $.ajax({
                                url: '/rest/v2/switch',
                                type: 'POST',
                                data: JSON.stringify({category: 'old-UI', action: 'login'}),
                                contentType: 'application/json; charset=utf-8',
                                dataType: 'json',
                            });
                        }  

                        // Handle response messages
                        handleLoginSuccessResponse(resp.data);
                    },
                    function(resp) {
                        // Reject response
                        deferred.reject(resp.data);
                        // Handle response messages
                        handleLoginErrorResponse(resp.data);
                    }
                );

            return deferred.promise;
        }

        function logout () {
            // Disconnect socket
            RaceBetsJS.webSockets.nodeJS.disconnect();

            // Show full page loading layer
            RaceBetsJS.application.assets.overlay.showPleaseWait();

            // Logout
            if (RaceBetsJS.application.globals.isB2B) {
                $window.location = '/';
            } else {
                AuthResource.logout().$promise
                .then(
                    function() {
                        $window.location = '/';
                    });
            }
        }

        function login2fa (data) {
            if (RaceBetsJS.application.globals.isB2B) {
                return;
            }

            var deferred = $q.defer();
            dataToResend = angular.extend(dataToResend, data);
            // Show full page loading layer
            RaceBetsJS.application.assets.overlay.showPleaseWait();

            // Append ThreadMatrix session ID
            data.tmSessionId = User.getTMSessionID();

            // Call resource
            AuthResource
                .secondStep({}, data).$promise
                .then(
                    function(resp) {
                        // Resolve response
                        deferred.resolve(resp.data);

                        // Handle response messages
                        handleLoginSuccessResponse(resp.data);
                    },
                    function(resp) {
                        // Reject response
                        deferred.reject(resp.data);
                        // Handle response messages
                        handleLoginErrorResponse(resp.data);
                    }
                );

            return deferred.promise;
        }

        function handleLoginSuccessResponse(resp) {
            // Track event
            GoogleTagManager.customEvent({
                'GUID': resp.idAccount,
                'event': 'LoggedInExisting'
            });

            // Successful login, response is null
            if (!resp.msg) {
                locationReload();
                // Return to prevent further run before reload happens
                return;
            }

            // Close loading
            RaceBetsJS.application.assets.overlay.hidePleaseWait();

            // Set dialog defaults
            var dialogOptions = {
                type: 'attention',
                msg: '',
                action: function() {
                    locationReload();
                },
                close: function() {
                    locationReload();
                }
            };

            // Successful login, reponse has message
            switch (resp.msg) {
                case 'VERIFY_ONLY':
                    dialogOptions.type = 'attention';
                    dialogOptions.msg = $filter('translate')('msg_account_verify_only', {url: '/account/verification'});
                    break;

                case 'LOGIN_GAME_WINNER':
                    dialogOptions.type = 'success';
                    dialogOptions.msg = $filter('translate')('msg_login_logingame_winner');
                    break;

                case 'EMAIL_INCORRECT':
                    dialogOptions.msg = $filter('translate')('msg_login_incorrect_email');
                    dialogOptions.action = dialogOptions.close = function() {
                        $window.location = '/particulars/email';
                    };
                    break;

                case 'ACTIVE_SESSIONS':
                    sessionsResponseDialog(resp.sessions_list || []);
                    return;

                case 'REQUEST_VERIFICATION':
                    dialogOptions.msg = $filter('translate')('msg_verification_dialog_content');
                    dialogOptions.btnLabel = $filter('translate')('label_verify_account')
                    dialogOptions.action = dialogOptions.close = function() {
                        $window.location = 'account/verification';
                    };
                    break;

                case 'REQUEST_RE_VERIFICATION':
                    dialogOptions.msg = $filter('translate')('msg_reverification_dialog_content');
                    dialogOptions.btnLabel = $filter('translate')('label_verify_account')
                    dialogOptions.action = dialogOptions.close = function() {
                        $window.location = 'account/verification';
                    };
                    break;
                case 'RE_VERIFICATION_SUCCESS':
                    dialogOptions.msg = $filter('translate')('msg_reverification_success_dialog_content');
                    dialogOptions.btnLabel = $filter('translate')('label_close');
                    dialogOptions.type = 'success';
                    dialogOptions.title = $filter('translate')('label_success');
                    dialogOptions.action = dialogOptions.close = function() {
                        $window.location.reload();
                    };
                    break;
                default:
                    dialogOptions.msg = $filter('translate')('msg_unknown_error_with_errorcode', {
                                            code: resp.msg
                                        });
            }

            // Show dialog with content of the response
            loginResponsDialog(dialogOptions);
        }

        function handleLoginErrorResponse (resp) {
            if (!resp || !resp.data) {
                return;
            }

            // Close loading
            RaceBetsJS.application.assets.overlay.hidePleaseWait();

            // Set dialog defaults
            var dialogOptions = {
                    type: 'error',
                    msg: '',
                    action: function() {
                        RaceBetsJS.application.assets.overlay.close();
                    },
                    close: function() {
                        RaceBetsJS.application.assets.overlay.close();
                    }
                };

            function getSelfExclusionMessage(data) {
                var userTimestamp = User.currentTimestamp(),
                    msg = '';

                // Self-Exclusion active forever
                if (data.locked_until === 0 && data.exclusion_active_till === 0) {
                    msg = RaceBetsJS.i18n.data.msgAccountSE;

                    // Self-Exclusion active, period elapsed
                } else if (data.locked_until === 0 && data.exclusion_active_till < userTimestamp) {
                    msg = RaceBetsJS.i18n.print('msgAccountSEElapsed', {
                        supportUrl: RaceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact")
                    });

                    // Self-Exclusion active, period active
                } else if (data.locked_until === 0 && data.exclusion_active_till >= userTimestamp) {
                    msg = RaceBetsJS.i18n.print('msgAccountSEActiveTill', {
                        date: RaceBetsJS.application.assets.timezone.date(
                            RaceBetsJS.i18n.data.dateFormatDateTime,
                            data.exclusion_active_till)
                    });

                    // Self-Exclusion active, cooling off
                } else if (data.locked_until) {
                    msg = RaceBetsJS.i18n.print('msgAccountSECoolingOffElapsed', {
                        date: RaceBetsJS.application.assets.timezone.date(RaceBetsJS.i18n.data.dateFormatDate, data.locked_until),
                        time: RaceBetsJS.application.assets.timezone.date(RaceBetsJS.i18n.data.dateFormatTime, data.locked_until)
                    });
                }

                return msg;
            }

            function getSelfExclusionOasisMessage(data) {
                var userTimestamp = User.currentTimestamp(),
                    msg = '';

                // Self-Exclusion active, period active
                if (data.exclusion_active_till >= userTimestamp) {
                    msg = RaceBetsJS.i18n.print('msgAccountExcludedExternalOasis', {
                        date: RaceBetsJS.application.assets.timezone.date(
                            RaceBetsJS.i18n.data.dateFormatDateTime,
                            data.exclusion_active_till)
                    });

                // Self-Exclusion active, period elapsed
                } else if (data.exclusion_active_till < userTimestamp) {
                    msg = RaceBetsJS.i18n.print('msgAccountExcludedExternalOasisElapsed', {
                        supportUrl: RaceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact")
                    });
                }

                return msg;
            }

            // Track event
            GoogleTagManager.customEvent({
                'Error': resp.data.code,
                'event': 'LoginFailed'
            });

            switch (resp.data.code) {
                // Wrong credentials
                case 132:
                    // Show login dialog instead of error dialog
                    dialogOptions = null;
                    RaceBetsJS.application.assets.overlay.close();
                    RaceBetsJS.application.assets.dialogs.loginDialog.show({ type: 'loginFailed' });
                    break;

                // Account locked
                case 134:
                    dialogOptions.msg = RaceBetsJS.i18n.data.msgAccountLocked;
                    dialogOptions.action = dialogOptions.close = function() {
                            $window.location = '/';
                        };
                    break;

                // Self restricted
                case 135:
                    dialogOptions.msg = getSelfExclusionMessage(resp.data);
                    break;

                // Time-Out
                case 136:
                    dialogOptions.msg = RaceBetsJS.i18n.print('msgAccountTO', {
                        date: RaceBetsJS.format.date(RaceBetsJS.i18n.data.dateFormatDate, resp.data.locked_until),
                        time: RaceBetsJS.format.date(RaceBetsJS.i18n.data.dateFormatTime, resp.data.locked_until)
                    });
                    break;

                // Already logged in
                case 155:
                    dialogOptions.msg = RaceBetsJS.i18n.data.msgAlreadyLoggedIn;
                    dialogOptions.action = dialogOptions.close = function() {
                            RaceBetsJS.application.assets.overlay.close();
                            locationReload();
                        };
                    break;

                // Accept news terms
                case 159:
                    $.extend(dialogOptions, {
                        title: $filter('translate')('h_new_terms'),
                        action: function() {
                            //add property to data which will be sent by login function
                            dataToResend.accept_terms = true;
                            login(dataToResend);
                        },
                        labelAccept: $filter('translate')('label_accept'),
                        labelViewFullTerms: $filter('translate')('label_view_full_terms_conditions'),
                        labelViewTermsChanges: $filter('translate')('label_view_terms_changes'),
                        labelTermsChanges: $filter('translate')('label_terms_changes'),
                    });

                    raceBetsJS.application.assets.overlay.close();
                    raceBetsJS.application.assets.dialogs.acceptNewTerms.show(dialogOptions);
                    break;

                // Account closed
                case 186:
                    dialogOptions.msg = $filter('translate')('msg_error_account_closed');
                    dialogOptions.action = dialogOptions.close = function() {
                        $window.location = raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact");
                    };
                    break;

                // External exclusion
                case 187:
                    dialogOptions.msg = RaceBetsJS.i18n.data.msgAccountExcludedExternal;
                    dialogOptions.action = dialogOptions.close = function() {
                        $window.location = raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact");
                    };
                    break;

                // Jurisdiction redirect to DE
                case 192:
                    dialogOptions.msg = $filter('translate')('msg_jurisdiction_redirect_de');
                    dialogOptions.btnLabel = $filter('translate')('label_go_to_racebets', { redirectTarget: 'de' });
                    dialogOptions.action = function() {
                        removeRedirectCookie();
                        $window.location.href = 'https://' + Settings.getHostname('de');
                    };
                    dialogOptions.close = function() {
                        RaceBetsJS.application.assets.overlay.close();
                    };
                    break;

                // Jurisdiction redirect to COM
                case 193:
                    dialogOptions.msg = $filter('translate')('msg_jurisdiction_redirect_com');
                    dialogOptions.btnLabel = $filter('translate')('label_go_to_racebets', { redirectTarget: 'com' });
                    dialogOptions.inputLabel = $filter('translate')('label_remember_choice');
                    dialogOptions.action = function(rememberChoice) {
                        removeRedirectCookie();
                        $window.location.href = 'https://' + Settings.getHostname('com') + '?redirectSource=de' + (rememberChoice ? '&redirectSourcePersist=true' : '');
                    };
                    raceBetsJS.application.assets.overlay.close();
                    raceBetsJS.application.assets.dialogs.jurisdictionRedirect.show(dialogOptions);
                    break;

                // 2 factor autentification required
                case 336:
                    raceBetsJS.application.assets.dialogs.login2faDialog.show({idAccount: resp.data.id})
                    break;
                    //2 factor autentification code expired
                case 337:
                    // Show login dialog instead of error dialog
                    dialogOptions = null;
                    RaceBetsJS.application.assets.overlay.close();
                    RaceBetsJS.application.assets.dialogs.loginDialog.show({ type: 'codeExpired' });
                    break;
                case 344:
                    dialogOptions.msg = $filter('translate')('msg_external_access_not_allowed');
                    break;
                // Self restricted externally (GamStop)
                case 802:
                    dialogOptions.msg = RaceBetsJS.i18n.data.msgAccountExcludedExternalGS;
                    dialogOptions.action = dialogOptions.close = function () {
                        $window.location = raceBetsJS.application.assets.settings.getBrandSettings("supportURLs", "contact");
                    };
                    break;

                // Self restricted externally (OASIS)
                case 803:
                    dialogOptions.msg = getSelfExclusionOasisMessage(resp.data);
                    break;


                // Login failed (133)
                default:
                    dialogOptions.msg = RaceBetsJS.i18n.print('msgUnknownError', {
                                            errorCode: resp.data.code
                                        });
                    dialogOptions.action = dialogOptions.close = function() {
                            $window.location = '/';
                        };
                    break;
            }

            // Show dialog with content of the response
            if (dialogOptions && [159, 193, 336].indexOf(resp.data.code) < 0) {
                loginResponsDialog(dialogOptions);
            }
        }

        function loginResponsDialog(options) {
            RaceBetsJS.application.assets.overlay.close();
            RaceBetsJS.application.assets.modalDialog.show({
                type: options.type,
                content: options.msg,
                title: options.title || '',
                buttons: [{
                    label: options.btnLabel || RaceBetsJS.i18n.data.buttonOK,
                    action: options.action,
                    active: true
                }],
                closeCallback: options.close
            });
        }

        function sessionsResponseDialog(sessions) {
            RaceBetsJS.application.assets.dialogs.sessionsDialog.show({ sessions: sessions || [] });
        }

        function locationReload() {
            RaceBetsJS.application.assets.overlay.showPleaseWait();
            if (RaceBetsJS.application.globals.isPopup && $window.opener) {
                $window.opener.location.reload();
            }
            $window.location.reload();
        }

        function removeRedirectCookie() {
            $cookies.remove('redirectSource', {
                path: '/',
                domain: Settings.getCookieDomain()
            });
        }
    }

})(angular);

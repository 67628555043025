(function () {
    'use strict';

    angular
        .module('common')
        .filter('formatDistance', formatDistance);

    /**
     * @ngdoc filter
     * @name common.filter:formatDistance
     *
     * @requires $filter
     * @requires User
     *
     * @description
     *      Formats distance number into readable string based on user's settings
     *
     * @return {String} formatted time
     *
     * @ngInject
     */
    function formatDistance($filter, User) {
        return function(distance, system) {
            if (!distance) {
                return;
            }

            if (!system) {
                system = User.unitSystem();
            }

            if (system === 'imperial') {
                var formattedDistance = '';
                var yards = Math.round(distance / 0.9144);
                var furlong = Math.floor(yards / 220);
                var miles = Math.floor(furlong / 8);

                if (miles > 0) {
                    formattedDistance += miles + $filter('translate')('label_miles_short');
                }

                if ((furlong % 8) > 0) {
                    formattedDistance += Math.floor((furlong % 8)) + $filter('translate')('label_furlong_short');
                }

                if ((yards % 220) > 0) {
                    formattedDistance += Math.floor((yards % 220)) + $filter('translate')('label_yards_short');
                }

                return formattedDistance;
            }
            else {
                return $filter('formatNumber')(distance, {trailingZeros: false}) + ' ' + $filter('translate')('label_meter_short');
            }
        };
    }
})();

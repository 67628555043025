(function () {
    'use strict';

    angular
        .module('common')
        .factory('AuthResource', AuthResource);

    /**
     * @ngdoc service
     * @name sidebar.service:AuthResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *
     * @ngInject
     */
    function AuthResource($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v1/users/me/:action',
                {},
                {
                    login: {
                        method: 'POST',
                        params: {
                            action: 'login'
                        }
                    },

                    logout: {
                        method: 'POST',
                        params: {
                            action: 'logout'
                        }
                    },

                    secondStep: {
                        method: 'POST',
                        params: {
                            action: '2fa'
                        }
                    }
                });
    }

})(angular);

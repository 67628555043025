(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbEmail', email);

    /**
     * @ngdoc directive
     * @name common.directive:rbEmail
     *
     * @restrict A
     * @requires ngModel
     * @element input
     *
     * @description
     *     Check field agains email address recognition pattern and sets 'pattern' and 'email' validity.
     *
     * @author Sandor Turanszky
     * @author Norbert Varga
     *
     * @ngInject
     */
    function email(){
        var directive = {
            require: '^ngModel',
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, element, attrs, ngModelCtrl) {
            var regex = new RegExp(
                            '^[a-zA-Z0-9.!#$%&\'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?' +
                            '(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*[\.]([a-zA-Z]{2,})$'
                        );

            if ( attrs.type !== 'email' && attrs.type !== 'text' ) {
                throw new Error('rb-email directive: Must be used on email or text typed input field!');
            }

            /**
             * Notice:
             * Default email validity for "" is set as valid by angular validator:
             * https://github.com/angular/angular.js/issues/7694
             *
             * The simple
             *     ngModelCtrl.$setValidity('email', false);
             * not working in formatting phase, it's gonna be valid anyway.
             *
             * @author Norbert Varga
             */

            // ngModelCtrl.$setValidity('email', false);
            ngModelCtrl.$setValidity('pattern', false);


            ngModelCtrl.$parsers.unshift(function (newVal) {

                var validity = regex.test(newVal);

                // ngModelCtrl.$setValidity('email', validity);
                ngModelCtrl.$setValidity('pattern', validity);

                return newVal;
            });
        }
    }
})();

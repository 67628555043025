(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbAppMessage', rbAppMessage);

    /**
     * @ngdoc directive
     * @name common.directive:rbAppMessage
     *
     * @restrict E
     *
     * @requires $scope
     * @requires AppMessage
     *
     * @description
     *         App notification display
     *
     * @ngInject
     */
    function rbAppMessage() {
        return {
            controller: Controller,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                message: '='
            },
            templateUrl: '/angular/components/views/rb-app-message.html'
        };

        /* @ngInject */
        function Controller($scope, AppMessage, $timeout, $window) {
            var vm = this;

            vm.close = close;
            vm.messageClick = messageClick;

            /**
             * @ngdoc method
             * @name common.directive:rbAppMessage#close
             * @public
             * @methodOf common.directive:rbAppMessage
             *
             * @description
             *      Close notification
             */
            function close(callback) {
                AppMessage
                    .remove()
                    .then(function() {
                        if (callback) {
                            callback($scope.message.acceptance ? $scope.message.acceptance.selected : null);
                        }

                        // TODO: As discussed with the GTM team, ther can be an event implemented on their side
                        // Wait 3 sec for the xtremepush script to be injected
                        $timeout(function(){
                            $.publish('xtremepush:injected');
                         }, 3000);
                    });
            }

            function messageClick(e) {
                if ($scope.message.messageEvent && _.isFunction($scope.message.messageEvent)) {
                    $scope.message.messageEvent(e);
                }
            }

        }
    }
})(angular);

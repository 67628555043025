(function () {
    'use strict';

    angular
        .module('common')
        .filter('groupBy', groupBy);

    /**
     * @ngdoc filter
     * @name common.filter:groupBy
     *
     * @description
     *     Groups array of objects by the given value.
     *
     * @example
     *     Produces the following result:
           Cards
            - visa
             - mastercard
             Bank
              - Sofort
              - GiroPay
             Wallet
              - PayPal
     *
     * @return {Array} array of grouped objects
     *
     * @author Sandor Turanszky
     */
    function groupBy () {
        return function (collection, key) {
            if (!key) {
                throw new Error('Filter error: groupBy - Grouping key must be specified!');
            }

            if (!collection ||
                ['string','function'].indexOf(typeof collection) > -1 ||
                (typeof collection === 'object' && !collection.length) ||
                collection.length < 1) { return; }

            return uniqueItems(collection, key);
        };

        function uniqueItems(data, key) {
            var i, value,
                result = [];

            for (i = 0; i < data.length; i++) {
                value = data[i][key];
                //skip if category is empty or doesn't even exist
                if (!!value && result.indexOf(value) === -1) {
                    result.push(value);
                }
            }

            return result;
        }
    }
})();

(function () {
    'use strict';

    /**
     * @ngdoc overview
     * @name common
     */
    angular
        .module('common', [])
        .constant('brandName', document.getElementsByTagName('html')[0].getAttribute('data-brand'))
        .config(routesConfig);

    /**
     * @ngdoc function
     * @name common.config:routesConfig
     *
     * @description
     *     Configures routes for the hole app.
     *
     * @author Sandor Turanszky
     */
    function routesConfig ($routeProvider) {
        $routeProvider
            .when('/error/:code', {
                templateUrl: '/angular/components/views/error.html',
                controller: 'AppLevelErrorCtrl'
            });
    }
})();

(function () {
    'use strict';

    angular
        .module('common')
        .filter('convertFormattedNumber', convertFormattedNumber);

    /**
     * @ngdoc filter
     * @name common.filter:convertFormattedNumber
     *
     * @requires User
     *
     * @description
     *     Returns the original number from the locale formatted string
     *
     * @param {String} numberString Input to filter
     *
     * @return {Number} number Number
     *
     * @ngInject
     */
    function convertFormattedNumber(User) {
        return function (numberString) {
            var numberFormats = User.numberFormats();

            return (!angular.isNumber(numberString) && isNaN(numberString)) ?
                        convert(numberString, numberFormats) :
                        numberString;
        };

        function convert(numberString, numberFormats) {
            var number = [];

            number = numberString.split(numberFormats.decimal_sep);
            number[0] = number[0].replace(/[.,]/g, '');
            number = parseFloat(number.join('.'));

            return isNaN(number) ? numberString : number;
        }
    }
})();

(function (angular) {
    'use strict';

    /**
     * @ngdoc module
     * @name runner
     *
     * @requires common
     *
     * @description
     *
     *     Runner module for common runner services
     *
     */
    angular
        .module('runner', ['common']);

})(this.angular);

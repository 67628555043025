(function () {
    'use strict';

    angular
        .module('common')
        .factory('Tools', ToolsFactory);

    /**
     * @ngdoc factory
     * @name common.service:Tools
     *
     * @requires $q
     * @requires ToolsResource
     *
     * @description
     *     Handles API calls to server
     *
     * @ngInject
     */
    function ToolsFactory ($q, ToolsResource) {
        var service = {
            calculateIban: calculateIban,
            verifyIban: verifyIban
        };

        return service;

        /**
         * @name common.service:Tools#calculateIban
         * @methodOf common.service:Tools
         *
         * @description
         *     Calculate IBAN
         *
         * @returns {Object} - Promise object
         */
        function calculateIban(data) {
            var defer = $q.defer();

            ToolsResource
                .iban(angular.extend({}, { action: 'calculate'}, data))
                .$promise
                .then(
                    function (resp) {
                        defer.resolve(resp);
                    },
                    function (resp) {
                        defer.reject(resp.data);
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:Tools#verifyIban
         * @methodOf common.service:Tools
         *
         * @description
         *     Verify IBAN number
         *
         * @param {Number} iban IBAN
         *
         * @returns {Object} - Promise object
         */
        function verifyIban(iban) {
            var defer = $q.defer();

            ToolsResource
                .iban({
                    action: 'validate',
                    iban: iban
                })
                .$promise
                .then(
                    function (resp) {
                        defer.resolve(resp);
                    },
                    function (resp) {
                        defer.reject(resp.data);
                    }
                );

            return defer.promise;
        }
    }
})(angular);

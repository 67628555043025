(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbInputTooltip', rbInputTooltip);

    /**
     * @ngdoc directive
     * @name common.directive:rbInputTooltip
     *
     * @requires $window
     * @requires $filter
     * @restrict A
     *
     * @description
     *      The {@link common.directive:rbInputTooltip rbInputTooltip} directive opens a Tipped tooltip when focus
     *      event is triggered and hide the tooltip when blur is triggered.
     *      The {@link common.directive:rbInputTooltip rbInputTooltip} directive accepts an object argument with messages to be displayed inside the tooltip.
     *      The possible value for the object attributes are:
     *          - hint: this is always displayed when focus event is triggered
     *          - any form validation directive (required, minlength, rbMinalphabeti): this is displayed only if
     *          target input form is invalid and specific form validation directive is set.
     *      The directive is restricted to be used only as an argument and if you want to display input form error
     *      messages it needs to be used inside of a form element.
     *
     *      Tooltip object example:
     *      ```js
     *      {
     *          hint: 'This message is always displayed at focus',
     *          required: 'This message is displayed when input is required but now its empty',
     *          minlength: 'This message is displayed when input value is less than minlength argument'
     *      }
     *      ```
     *
     * @param {Object} rbInputTooltip Tooltip messages
     *
     * @ngInject
     */
    function rbInputTooltip($window, $filter, User) {
        return {
            link: link,
            require: ['^ngModel', '^form'],
            restrict: 'A'
        };

        function link(scope, elem, attrs, ctrls) {
            var tipped = $window.Tipped,
                ngModelCtrl = ctrls[0],
                formCtrl = ctrls[1],
                formInput = elem.parents('.c-form__input'),
                tooltipOptions = {
                    hook: attrs.rbInputTooltipHook || 'righttop',
                    hideOthers: true,
                    // offset: { x: 2, y: -4 },
                    stem: { offset: { x: 0, y: 6 } },
                    skin: 'racebets',
                    padding: 0,
                    fadeIn: false,
                    showOn: false,
                    hideOn: [
                        { element: 'tooltip', event: 'mouseenter' },
                        { element: 'target', event: 'blur' }
                    ],
                    maxWidth: 250,
                    zIndex: 10000
            };

            scope.rbInputTooltip = scope.$eval(attrs.rbInputTooltip);

            elem
                .on('focus keyup', elemFocus)
                .on('blur', elemBlur);

            if (elem.hasClass('c-input--selectGroup')) {
                elem
                    .on('mouseenter', elemFocus)
                    .on('mouseleave', elemBlur);
            }

            if (elem.hasClass('c-input__radio') || elem.hasClass('c-input__checkbox')) {
                elem.parent('.c-input')
                    .on('mouseenter', elemFocus)
                    .on('mouseleave', elemBlur);
            }

            function elemFocus() {
                var show = !ngModelCtrl.$valid && (ngModelCtrl.$dirty || formCtrl.$submitted),
                    errors = ngModelCtrl.$error,
                    hintMsg = $filter('translate')(scope.rbInputTooltip.hint),
                    tooltipMsgs = {};

                if (hintMsg) {
                    tooltipMsgs.hint = hintMsg;
                }

                if (show && errors) {
                    for (var error in errors) {
                        if (errors.hasOwnProperty(error) && errors[error] && scope.rbInputTooltip[error]) {
                            switch (error) {
                                case 'minlength':
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], { length: attrs.ngMinlength });
                                    break;
                                case 'maxlength':
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], { length: attrs.ngMaxlength });
                                    break;
                                case 'minalphabetic':
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], { number: attrs.rbMinalphabetic });
                                    break;
                                case 'minage':
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], { age: attrs.rbMinage });
                                    break;
                                case 'minamount':
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], { amount: attrs.rbMinamount, value: attrs.rbMinamount });
                                    break;
                                case 'maxamount':
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], { amount: attrs.rbMaxamount });
                                    break;
                                case 'inrange':
                                    var range = scope.$eval(attrs.rbInrange);
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], {
                                        range: range.minAmount + '–' + range.maxAmount + ' ' + User.currency()
                                                        });
                                    break;
                                case 'differ':
                                    if (scope.rbInputTooltip[error] === 'msg_password_contains_username') {
                                        tooltipMsgs.error = $filter('translate')('msg_password_contains_username');
                                    } else {
                                        tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error], {
                                            field: $filter('translate')(attrs.rbDifferField),
                                            containedField: $filter('translate')(attrs.rbDifferContained)
                                                                });
                                    }
                                    break;
                                default:
                                    tooltipMsgs.error = $filter('translate')(scope.rbInputTooltip[error]);
                            }
                        }
                    }
                    showTooltip(formInput, tooltipMsgs);
                } else if (hintMsg) {
                    tooltipMsgs.hint = hintMsg;
                    showTooltip(formInput, tooltipMsgs);
                } else {
                    removeTooltip(formInput);
                }


            }

            function elemBlur() {
                removeTooltip(formInput);
            }

            function createTooltip(messages){
                var tipError, tipHint, tipContent = angular.element('<div class="field-hint">');

                if (messages.error) {
                    tipError = angular.element('<div class="error">').text(messages.error);

                    tipContent.append(tipError);
                    tipContent.addClass('hasError');
                }

                if (messages.hint) {
                    tipHint = angular.element('<div class="hint">').html(messages.hint);

                    tipContent.append(tipHint);
                }

                return tipContent;
            }

            function showTooltip(elem, messages){
                if (!(messages.hint || messages.error)) {
                    return;
                }

                return tipped.create(elem, cb, tooltipOptions).show();

                function cb() {
                    return createTooltip(messages);
                }
            }

            function removeTooltip(elem){
                tipped.get(elem).remove();
            }
        }
    }
})(angular);

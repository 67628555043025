'use strict';
/**
 * raceBets application
 *
 * @author Sandor Turanszky
 */

angular.module('raceBetsApp', (function() {
        var htmlElem = document.getElementsByTagName('html')[0];
        var moduleList = [
            'ngResource',
            'ngRoute',
            'ngCookies',
            'ngSanitize',
            'ngAnimate',
            'pascalprecht.translate',
            'common',
            'user',
            'sidebar',
            'runner',
            'featuredHorses',
            'templates',
        ];
        if (htmlElem.getAttribute('data-client') === 'standalone') {
            moduleList.push('deposit', 'account', 'registration', 'offers');

            if (htmlElem.getAttribute('data-brand') === 'racebets') {
                moduleList.push('nextrace', 'seo');
            }

            if (htmlElem.getAttribute('data-brand') === 'suaposta') {
                moduleList.push('seo');
            }
        }
        return moduleList;
    })())
    .config(function ($routeProvider, $locationProvider, $httpProvider, $translateProvider) {
        var defaultLanguage = document.getElementsByTagName('html')[0].getAttribute('lang') || 'en',
            rb_i18n_angular_json = window.rb_i18n_angular_json || {};

        $routeProvider
            .otherwise({
                // id: 'nativeRaceBetsJsApp',
                // templateUrl: '/angular/components/views/blank.html',
                // controller: 'NativeLevelRouteHandlerCtrl'
            });

        $locationProvider.html5Mode(true);

        // Set sanitize strategy
        $translateProvider.useSanitizeValueStrategy('escaped');

        // Load dictionary
        $translateProvider.translations(defaultLanguage, rb_i18n_angular_json);

        // Set default language
        $translateProvider.preferredLanguage(defaultLanguage);

        // Intercept error codes (401s, 404 ... and redirect to login or error page
        $httpProvider.interceptors.push(['$q', '$location', '$filter', function ($q, $location, $filter) {
            return {
                'responseError': function (response) {

                    // Response error handling for bad request (e.g. xsrf token header missing)
                    if (_.isEmpty(response.data)) {

                        if (!raceBetsJS.application.globals.ajaxErrorOverlay) {
                            switch (response.status) {
                                case 400:
                                    raceBetsJS.application.globals.ajaxErrorOverlay = true;
                                    raceBetsJS.application.assets.modalDialog.show({
                                        type: 'error',
                                        title: $filter('translate')('h_generic_error'),
                                        content: $filter('translate')('msg_error_request_reload'),
                                        buttonAction: function () {
                                            raceBetsJS.application.globals.ajaxErrorOverlay = false;
                                            raceBetsJS.application.assets.overlay.close();
                                        }
                                    });
                                    break;
                                case -1:
                                    raceBetsJS.application.globals.ajaxErrorOverlay = true;
                                    raceBetsJS.application.assets.modalDialog.show({
                                        type: 'error',
                                        title: $filter('translate')('h_generic_error'),
                                        content: $filter('translate')('msg_generic_error'),
                                        buttonAction: function () {
                                            raceBetsJS.application.globals.ajaxErrorOverlay = false;
                                            raceBetsJS.application.assets.overlay.close();
                                        }
                                    });
                                    break;
                            }
                        }
                        return $q.reject(response);

                    // Response error handling for exceptions V1
                    } else if (response.data && response.data.status === 'error') {
                        //redirect if http error code is 404
                        if (response.status === 404) {
                            $location.path('static/error404');
                        } else {
                            //show message in a modal for any other http errors
                            raceBetsJS.application.assets.modalDialog.show({
                                title: $filter('translate')('h_generic_error'),
                                type: 'error',
                                content: $filter('translate')('msg_generic_error') + ' ('+ response.data.code + ' '  + response.data.title + ')',
                                buttons: [
                                    {
                                        label: $filter('translate')('label_reload'),
                                        action: function() {
                                            raceBetsJS.application.assets.overlay.close();
                                            window.location.reload();
                                        }
                                    },
                                    {
                                        label: $filter('translate')('label_close'),
                                        action: function() {
                                            raceBetsJS.application.assets.overlay.close();
                                            $location.path('home');
                                        },
                                        active: true
                                    }
                                ]
                            });
                        }
                        return $q.reject(response);

                    // Response error handling for exceptions V2
                    } else if (response.data && response.data.error) {

                        switch (response.status) {
                            case 401:
                                raceBetsJS.application.assets.dialogs.loginDialog.show({
                                    type: 'loginRequired'
                                });
                                break;
                            // Don't handle 404 from API
                            // case 404:
                            //     $location.path(url + '/static/error404');
                            //     break;
                        }
                        return $q.reject(response);

                    } else {

                        return $q.reject(response);
                    }
                }
            };
        }]);

        // Intercept ajax requests
        $httpProvider.interceptors.push('AjaxFallbackInterceptor');

    })

    .run(function ($rootScope, $location, $window, $timeout, $injector, RaceBetsJS, User, UserBalance, GoogleTagManager, $cookies, $filter) {
        var htmlElem = document.getElementsByTagName('html')[0];
        $rootScope.isB2B = htmlElem.getAttribute('data-client') !== 'standalone';

        $rootScope.brandName = RaceBetsJS.application.globals.brandName || htmlElem.getAttribute('data-brand')

        // inject additional services for not B2B
        if (!$rootScope.isB2B) {
            var PaymentMethod = $injector.get('PaymentMethod');
        }

        if ($rootScope.brandName === 'racebets' || $rootScope.brandName === 'suaposta') {
            var Seo = $injector.get('Seo');
        }

        if (RaceBetsJS.application.globals.isFantasy && !User.isLoggedIn()) {
            $timeout(function() {
                RaceBetsJS.application.assets.dialogs.fantasyWelcomeDialog.show({content: $filter('translate')('msg_fantasy_welcome')})
            }, 300);
        }

        /**
         * Store baseUrl as global. This is needed to have an access to current base url with dynamic params
         * which depend on languages within cashier/deposit section
         * This base url has to be included in all links in pages other than the one under /:lang/:p1/cashier/deposit/
         * TODO: Make it universal. This is a temporary solution
         * because it works only for a one particular url '/cashier/deposit'
         */
        // var parts = $location.path().split('/');
        // $rootScope.baseUrl = 'cashier/deposit';
        // $rootScope.baseUrl = parts[1] + '/' + parts[2] + '/cashier/deposit';

        $rootScope.nativeBaseUrl = '';
        //$rootScope.nativeBaseUrl = parts[1] + '/' + parts[2];

        /**
         * Contains current app message to display
         * @type {Null|Object}
         */
        $rootScope.appMessage = null;

        /**
         * Shows if app is loaded in the html dom
         * @type {boolean}
         */
        $rootScope.appDomLoaded = false;

        /**
         * Array of module ids with hidden sidebar layout
         * @type {Array}
         */
        $rootScope.hiddenSidebar = ['registration', 'verification', 'cashier', 'account'];

        /**
         * Array of module ids with hidden rightbar layout
         * @type {Array}
         */
        $rootScope.hiddenRightbar = ['registration', 'verification', 'cashier', 'account'];

        $rootScope.depositOpened = false;

        $rootScope.navigate = function(url) {
            $location.path(url);
        };

        //todo: move to helpers or add to js prototype
        $rootScope.getLength = function(obj) {
            return Object.keys(obj).length;
        };

        //todo: move to helpers or add to js prototype
        $rootScope.isFloat = function isFloat(n) {
            /*jslint bitwise: true */
            return n === +n && n !== (n|0);
        };

        //todo: move to helpers or add to js prototype
        $rootScope.isInteger = function(n) {
            /*jslint bitwise: true */
            return n === +n && n === (n|0);
        };

        // Watch for user balance change after bet placed
        $rootScope.$on('Bet:placed:success', function(e, bet) {
            // B2B integration have no balance subscriptions, update the balance immediately when freebet was used
            var t = $rootScope.isB2B && bet && bet.idFreebet ? 0 : 5000;

            if ($rootScope.balanceRequest) {
                $timeout.cancel($rootScope.balanceRequest);
            }
            // Force update balance after 5 sec
            $rootScope.balanceRequest = $timeout(function() {
                UserBalance.getBalance(true);
            }, t);
        });

        // Watch for user balance change after deposit
        $rootScope.$on('Deposit:success', function() {
            if ($rootScope.balanceRequest) {
                $timeout.cancel($rootScope.balanceRequest);
            }
            // Force update balance after 5 sec
            $rootScope.balanceRequest = $timeout(function() {
                UserBalance.getBalance(true);
            }, 5000);
        });

        $rootScope.$on('$locationChangeStart', function ($event, current) {
            var locationUrl = $location.url();

            // Reload page if requested
            if (raceBetsJS.application.globals.reloadPage) {
                $window.location = current;
                return;
            }

            if (!$rootScope.isB2B) {
                // No guest allowed to visit bonus page.
                if( !User.isLoggedIn && RaceBetsJS.application.comesFromAngular && locationUrl === '/offers/bonuses' ) {
                    $event.preventDefault();
                    raceBetsJS.application.assets.dialogs.loginDialog.show();
                    $location.path('cashier/deposit');
                }
            }
        });

        $rootScope.$on('$locationChangeSuccess', function ($event, newState, oldState) {
            if ($rootScope.isB2B && newState !== oldState){
                // TODO: remove after bonus routes are implemented in OBG
                if($location.url().includes('bonuses')) return

                RaceBetsJS.browser.crossFrame.send('navigationHash', $location.url());
            }

            if (['racebets', 'suaposta'].indexOf($rootScope.brandName) > -1 && newState !== oldState) {
                Seo.updateMetaData(newState);
            }
        });

        $rootScope.$on('$routeChangeStart', function ($event, next, current) {
            if(next.id !== 'nativeRaceBetsJsApp') {
                RaceBetsJS.application.comesFromAngular = true;
            }

            if(next.id !== undefined && next.id !== 'nativeRaceBetsJsApp') {
                $rootScope.section = next.id;
            }

            if (!$rootScope.isB2B) {
                PaymentMethod.deleteEmptyMethod();

                if (User.isConsentRequired() === true && User.emailVerified()) {

                    if (!$location.path().includes('/register') && !$location.path().includes('/static')) {
                        $location.path('register/consent');
                    }
                }
            }

        });

        $rootScope.$on('$routeChangeError', function ($event, current, previous, rejection) {
            if (rejection && rejection.data) {
                switch (rejection.data.code) {
                    // Login Required
                    case 101:
                        RaceBetsJS.isInitDone('wait').then(function(){
                            RaceBetsJS.application.assets.dialogs.loginDialog.show({
                                type: 'loginRequired'
                            });
                            if (rejection.data.redirect) {
                                $location.path(rejection.data.redirect).replace();
                            }
                        });
                        break;
                    // Already logged in
                    case 155:
                        RaceBetsJS.isInitDone('wait').then(function(){
                            RaceBetsJS.application.globals.contentMain.empty().append($('<div />').addClass('loading-content'));
                            $location.path(rejection.data.redirect || 'home');
                        });
                        break;
                }
            }
        });

        $rootScope.$on('$routeChangeSuccess', function ($event, current) {
            // Broadcast global event
            $.publish('$routeChangeSuccess');

            var lobby = GoogleTagManager.getLobby($location.path());

            GoogleTagManager.customEvent(_.extend({
                'VirtualUrl': window.location.pathname + window.location.search,
                'VirtualTitle': window.document.title,
                'event': 'VirtualPageView',
            }, lobby));
            var consentCookie = $cookies.getObject('CONSENT');
            var consentGiven = consentCookie && parseInt(consentCookie.marketing) === 1;

            // send user ID to XtremePush for RaceBets only
            if(User.isLoggedIn() && $rootScope.brandName === 'racebets' && consentGiven && RaceBetsJS.application.assets.settings.getXtremePushEnabledState()) {
                // Wait 3 sec because on full page reload, the "window.xtremepush" object is undefined
                $timeout(function() {
                    if(window.xtremepush) {
                        window.xtremepush('set', 'user_id', RaceBetsJS.application.user.idAccount);
                    }
                }, 3000)

                // Check if the dialog was already shown
                // var xtremePushOptInDialogShown = $cookies.get(RaceBetsJS.application.assets.settings.xtremePushCookieName);
                // if(!xtremePushOptInDialogShown && RaceBetsJS.application.assets.settings.showXtremePushOptIn()) {
                //     $timeout(function() {
                //         RaceBetsJS.application.assets.dialogs.xtremePushOptInDialog.show();
                //     }, RaceBetsJS.application.globals.xtremePushOptInDelay);
                // }
            }

            function setSowCookie() {
                var date = new Date();
                date.setHours(date.getHours() + 1);

                $.cookie(raceBetsJS.application.assets.settings.showSowPopUpCookieName, JSON.stringify({ seen: true }), {
                    secure: true,
                    path: '/',
                    expires: date
                });
            }

            var sowPopUpShown = $cookies.get(RaceBetsJS.application.assets.settings.showSowPopUpCookieName);
            if(User.isLoggedIn() && RaceBetsJS.application.user.sowPopUp && !sowPopUpShown) {
                raceBetsJS.application.assets.modalDialog.show({
                    title:  $filter('translate')('label_sow_required'),
                    content: $filter('translate')('text_sow_popup'),
                    buttons: [
                        {
                            label: $filter('translate')('label_upload_documents'),
                            action: function() {
                                setSowCookie();
                                raceBetsJS.application.assets.overlay.close();
                                raceBetsJS.browser.history.navigateTo('/account/sow');
                            },
                            active: true
                        },
                        {
                            label: $filter('translate')('label_close'),
                            action: function() {
                                setSowCookie();
                                raceBetsJS.application.assets.overlay.close();
                            }
                        }
                    ]
                });
            }

            function setLugasCookie() {
                var date = new Date();
                date.setHours(date.getHours() + 24);

                $.cookie(raceBetsJS.application.assets.settings.showLugasPopUpCookieName, JSON.stringify({ seen: true }), {
                    secure: true,
                    path: '/',
                    expires: date
                });
            }
            var lugasPopUpShown = $cookies.get(RaceBetsJS.application.assets.settings.showLugasPopUpCookieName);
            if(User.isLoggedIn() && RaceBetsJS.application.user.lugasDepositLimitReminderPopUp && !lugasPopUpShown) {
                raceBetsJS.application.assets.modalDialog.show({
                    title:  $filter('translate')('label_lugas_popup_title'),
                    content: $filter('translate')('text_lugas_deposit_popup'),
                    buttons: [
                        {
                            label: $filter('translate')('label_set_deposit_limit'),
                            action: function() {
                                setLugasCookie();
                                raceBetsJS.application.assets.overlay.close();
                                raceBetsJS.browser.history.navigateTo('/account/restrictions');
                            },
                            active: true
                        },
                        {
                            label: $filter('translate')('label_close'),
                            action: function() {
                                setLugasCookie();
                                raceBetsJS.application.assets.overlay.close();
                            }
                        }
                    ]
                });
            }

            // Payment Funnel: Open Deposit Page
            if (/cashier\/deposit/.test($location.path())) {
                if (!$rootScope.depositOpened) {
                    GoogleTagManager.customEvent({
                        'GUIDConfirm': User.getGUID(),
                        'event': 'OpenDepPage'
                    });
                }
                $rootScope.depositOpened = true;
            } else {
                $rootScope.depositOpened = false;
            }

            var locationUrl = $location.url()
            if(locationUrl.indexOf('/betssonfc') > -1) {
                RaceBetsJS.application.globals.hideSidebar = true;
                RaceBetsJS.application.content.hideSidebar(true);
            }

            if (!current.logout) {
                if (current.regexp) {

                    if (RaceBetsJS.application.contentController && RaceBetsJS.application.contentController.onUnload) {
                        RaceBetsJS.application.contentController.onUnload();
                    }

                    RaceBetsJS.isInitDone('wait').then(function(){
                        RaceBetsJS.application.globals.contentMain.empty().append($('<div />').addClass('loading-content'));
                        RaceBetsJS.application.globals.angularApp = true;
                    });

                    // hide/show sidebar
                    if ($rootScope.hiddenSidebar.indexOf(current.id) > -1 || $rootScope.hiddenSidebar.indexOf(current.urlLastPart) > -1) {
                        RaceBetsJS.application.globals.hideSidebar = true;
                        RaceBetsJS.application.content.hideSidebar(true);
                    } else {
                        RaceBetsJS.application.globals.hideSidebar = false;
                        RaceBetsJS.application.content.hideSidebar(false);
                    }

                    RaceBetsJS.application.content.toggleFantasy(false);

                    if ($rootScope.hiddenRightbar.indexOf(current.id) > -1 || $rootScope.hiddenRightbar.indexOf(current.urlLastPart) > -1) {
                        RaceBetsJS.application.content.hideRightbar(true);
                    } else {
                        RaceBetsJS.application.content.hideRightbar(false);
                    }

                    if (current.id === 'cashier') {
                        $rootScope.baseUrl = 'cashier/deposit';
                    }

                } else {
                    if (!$rootScope.isB2B) {
                        //user navigates away from angular so next time when he is back to the cashier, we need to load all data
                        PaymentMethod.updateLoadAllData(true);
                    }

                    RaceBetsJS.application.globals.angularApp = false;

                    if (RaceBetsJS.application.contentController) {
                        RaceBetsJS.application.contentController.navigate($location.path());
                    }
                }
            }

            if (current.navigationKey) {
                RaceBetsJS.application.header.navigation.activateItemByKey(current.navigationKey);
            }

            if (current.id) {
                RaceBetsJS.application.globals.angularModuleId = current.id;
            }

        });
    });

/**
 * Templates modules - used for cache
 */
angular.module('templates', []);

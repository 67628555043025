(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbDobPicker', rbDobPicker);

    /**
     * @ngdoc directive
     * @name common.directive:rbDobPicker
     *
     * @restrict E
     *
     * @requires ngModel
     * @requires form
     * @requires $timeout
     * @requires $scope
     * @requires $filter
     * @requires RaceBetsJS
     *
     * @description
     *     The {@link common.directive:rbDobPicker rbDobPicker} directive can be used to generate a simple three dropdown date picker boxes.
     *
     * @ngInject
     */
    function rbDobPicker($timeout) {
        return {
            controller: Controller,
            require: ['ngModel', '^form'],
            restrict: 'E',
            replace: true,
            templateUrl: '/angular/components/views/rb-dob-picker.html',
            link: link
        };

        /* @ngInject */
        function Controller($scope, $filter, RaceBetsJS) {
            var currentDate = new Date();

            $scope.getMonthLabel = getMonthLabel;

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#dobDaysRange
             * @public
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Available days range
             *
             * @type {Array}
             */
            $scope.dobDaysRange = $filter('range')([1, 31], true);

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#dobMonthsRange
             * @public
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Available month range
             *
             * @type {Array}
             */
            $scope.dobMonthsRange = _getLocalizedMonthsArray();

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#dobYearsRange
             * @public
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Available years range
             *
             * @type {Array}
             */
            $scope.dobYearsRange = $filter('range')([currentDate.getFullYear() - 100, currentDate.getFullYear() - 18]);

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#fields
             * @public
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Date of birth input fields
             *
             * @type {Array}
             */
            $scope.fields = [
                'date_of_birth_day',
                'date_of_birth_month',
                'date_of_birth_year'
            ];

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#selectedDate
             * @public
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Selected date of birth values
             *
             * @type {Object}
             */
            $scope.selectedDate = {};

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#getMonthLabel
             * @public
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Return the label of selected month
             *
             * @param {String} val String representaiton of month with leading zero
             *
             * @return {String} Label of month
             */
            function getMonthLabel(val) {
                var matchedMonth = _.filter($scope.dobMonthsRange, function(month) {
                    return month.value === val;
                });

                return matchedMonth.length ? matchedMonth[0].label : false;
            }

            /**
             * @ngdoc property
             * @name common.directive:rbDobPicker#_getLocalizedMonthsArray
             * @private
             * @propertyOf common.directive:rbDobPicker
             *
             * @description
             *          Localized months names with values
             *
             * @return {Array} Array of month objects
             */
            function _getLocalizedMonthsArray() {
                var localArray = [],
                    months = RaceBetsJS.localize.getMonthNames(),
                    monthsRange = $filter('range')([1, 12], true);

                angular.forEach(months, function(value, key) {
                    this.push({
                        value: monthsRange[key],
                        label: months[key]
                    });
                }, localArray);

                return localArray;
            }
        }

        function link(scope, elem, attrs, ctrls) {
            var ngModelCtrl = ctrls[0],
                formCtrl = ctrls[1];

            // Watch date object
            scope.$watch('selectedDate', elemChange, true);

            // Set error if marked as
            scope.dobError = attrs.dobError && attrs.dobError === 'true';

            // Preselect date if provided
            if (attrs.dobValue) {
                $timeout(function() {
                    selectDate(attrs.dobValue);
                });
            }

            // Disable if attribute is provided
            if (attrs.dobDisabled && attrs.dobDisabled === 'true') {
                $timeout(function() {
                    scope.dobDisabled = true;
                });
            }

            /**
             * @ngdoc method
             * @name common.directive:rbDobPicker#selectDate
             * @private
             * @methodOf common.directive:rbDobPicker
             *
             * @description
             *     Prefill date of birth
             *
             * @param {String} dateOfBirth Date string in format of "1982-03-08"
             */
            function selectDate(dateOfBirth) {
                var dob = dateOfBirth.split('-');

                // Set values
                scope.selectedDate.year = dob[0];
                scope.selectedDate.month = dob[1];
                scope.selectedDate.day = dob[2];

                // Manually setting dirty/invalid states
                if (scope.dobError) {
                    $timeout(function() {
                        setFieldsValidity(scope.fields, 'required');
                    }, 100);
                } else if (!attrs.dobDisabled) {
                    $timeout(function() {
                        setFieldsDirty(scope.fields);
                    });
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbDobPicker#elemChange
             * @private
             * @methodOf common.directive:rbDobPicker
             *
             * @description
             *     Select callback
             */
            function elemChange() {
                // Check if all fields selected
                if (scope.selectedDate.day && scope.selectedDate.month && scope.selectedDate.year) {
                    // Set controller value
                    ngModelCtrl.$setViewValue( datepickerParser(scope.selectedDate) );

                    // Minimum age validation
                    if (formCtrl.$error && formCtrl.$error.hasOwnProperty('minage')) {
                        setFieldsValidity([scope.fields[0], scope.fields[1]], 'required');
                    }

                    // If was preset to error it's enough to change just one field
                    else if (formCtrl.$error.hasOwnProperty('required') && scope.dobError) {
                        setFieldsValidity(scope.fields, 'required', true);
                    }

                    // Valid day by month validation
                    else {
                        setFieldsValidity([scope.fields[0], scope.fields[1]], 'required', true);
                        getDaysRangeByMonth(scope.selectedDate);
                    }
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbDobPicker#getDaysRangeByMonth
             * @private
             * @methodOf common.directive:rbDobPicker
             *
             * @description
             *     Update days range for selected month and year
             *
             * @param {Object} selectedDate Selected date
             */
            function getDaysRangeByMonth(selectedDate) {
                var daysNr = 31,
                    month = parseInt(selectedDate.month, 10),
                    year = parseInt(selectedDate.year, 10);

                if (month && year) {
                    daysNr = new Date(year, month, 0).getDate();
                }

                if (selectedDate.day > daysNr) {
                    setFieldsValidity([scope.fields[0]]);
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbDobPicker#setFieldsDirty
             * @private
             * @methodOf common.directive:rbDobPicker
             *
             * @description
             *     Sets passed fields as dirty
             *
             * @param {Array} fields Fields to set
             */
            function setFieldsDirty(fields) {
                for (var i = fields.length - 1; i >= 0; i--) {
                    if (formCtrl[fields[i]]) {
                        formCtrl[fields[i]].$setDirty(true);
                    }
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbDobPicker#setFieldsValidity
             * @private
             * @methodOf common.directive:rbDobPicker
             *
             * @description
             *     Sets passed fields' validity and make them dirty
             *
             * @param {Array} fields Fields to set
             * @param {String?} error Validator to unvalidate
             * @param {Boolean?} state Validity
             */
            function setFieldsValidity(fields, error, state) {
                for (var i = fields.length - 1; i >= 0; i--) {
                    if (formCtrl[fields[i]]) {
                        formCtrl[fields[i]].$setValidity(error || 'required', state || false);
                        if (!state) {
                            formCtrl[fields[i]].$setDirty(true);
                        }
                    }
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbDobPicker#datepickerParser
             * @private
             * @methodOf common.directive:rbDobPicker
             *
             * @description
             *     Parse DoB values to dash seperated string
             *
             * @param {Object} viewValue Selected date
             *
             * @return {string} Date string (ex. 1982-03-98)
             */
            function datepickerParser(viewValue) {
                if (viewValue.day && viewValue.month && viewValue.year) {
                    return [viewValue.year, viewValue.month, viewValue.day].join('-');
                }
            }
        }
    }
})(angular);

(function (angular, $) {
    'use strict';

    angular
        .module('sidebar')
        .directive('rbSidebarCalendar', rbSidebarCalendar);

    /**
     * @ngdoc directive
     * @name sidebar.directive:rbSidebarCalendar
     *
     * @restrict E
     *
     * @requires ngModel
     * @requires rbSidebar
     * @requires $timeout
     * @requires $document
     * @requires RaceBetsJS
     *
     * @description
     *         Sidebar calendat
     *
     * @ngInject
     */
    function rbSidebarCalendar($timeout, $document, RaceBetsJS) {
        return {
            link: link,
            require: ['ngModel', '^rbSidebar'],
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/modules/sidebar/views/rb-sidebar-calendar.html'
        };

        function link(scope, elem, attrs, ctrls) {
            var calendar = elem.find('#m-sidebar__calendar'),
                options = {
                    firstDay: 1,
                    dateFormat: 'yy-mm-dd',
                    minDate: new Date(2005, 0, 1),
                    maxDate: '+1 year',
                    autosize : false,
                    showOtherMonths: true,
                    selectOtherMonths: true,
                    onChangeMonthYear: onChangeMonthYear,
                    altField: angular.element('#m-sidebar__calendarInput'),
                    altFormat: 'D, ' + RaceBetsJS.i18n.data.dateFormatDate.toLowerCase().replace('y', 'yy'),
                    onSelect: onSelectHandler
                },
                modelCtrl = ctrls[0];

            scope.toggleCalendar = toggleCalendar;
            scope.setDate = setDate;
            scope.showCalendar = false;

            RaceBetsJS.isInitDone().then(initDatepicker);

            $document.on('click', onClickOutside);

            function hideCalendar() {
                scope.showCalendar = false;
                updateDatepicker(calendar);
                /* Update selected date */
                modelCtrl.$setViewValue(currentDatepickerValue());
            }

            function initDatepicker() {
                calendar.datepicker(options);
                updateDatepicker(calendar);
            }

            function onChangeMonthYear() {
                updateDatepicker(calendar);
            }

            function onClickOutside(evt) {
                var target = angular.element(evt.target);

                if (scope.showCalendar &&
                    !target.is('#m-sidebar__calendarToggleBtn') &&
                    !target.is('#m-sidebar__calendarInput') &&
                    target.parent().attr('id') !== 'm-sidebar__calendarToggleBtn' &&
                    !target.parents().filter('.ui-datepicker-header').length  )
                {
                    scope.$apply(function () {
                        scope.showCalendar = false;
                    });
                }
            }

            function onSelectHandler() {
                scope.$apply(function () {
                    hideCalendar();
                });
            }

            function currentDatepickerValue() {
                return calendar.datepicker({dateFormat: 'yy-mm-dd'}).val();
            }

            function setDate(newDate) {
                calendar.datepicker('setDate', newDate);
                hideCalendar();
            }

            function toggleCalendar() {
                scope.showCalendar = !scope.showCalendar;
            }
        }

        function updateDatepicker(calendar) {
            var prevHtml = '<a href="#" class="ui-datepicker-prev-year"><span class="c-arrow toLeft"></span>' +
                            '<span class="c-arrow toLeft"></span></a>',
                nextHtml = '<a href="#" class="ui-datepicker-next-year"><span class="c-arrow toRight"></span>' +
                            '<span class="c-arrow toRight"></span></a>';

            /* Add prev, next year buttons to calendar */
            $timeout(addPrevNextYearBtns, 1);

            function addPrevNextYearBtns() {
                var prevBtn = calendar.find('.ui-datepicker-prev'),
                    nextBtn = calendar.find('.ui-datepicker-next'),
                    prevYear = $(prevHtml).insertAfter(prevBtn),
                    nextYear = $(nextHtml).insertBefore(nextBtn);

                prevYear.on('click', prevClickHandler);
                nextYear.on('click', nextClickHandler);
                calendar.addClass('hasYearButtons');
            }


            function prevClickHandler(evt) {
                evt.preventDefault();
                $.datepicker._adjustDate(calendar, -1, 'Y');
            }

            function nextClickHandler(evt) {
                evt.preventDefault();
                $.datepicker._adjustDate(calendar, +1, 'Y');
            }
        }
    }
})(angular, $);

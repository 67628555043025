(function () {
    'use strict';

    angular
        .module('common')
        .factory('RaceBetsJS', raceBetsJs);

    /**
     * @ngdoc service
     * @name common.service:RaceBetsJS
     *
     * @requires $window
     * @requires $q
     * @requires $rootScope
     * @requires $interval
     *
     * @description
     *     Makes `raceBetsJS` framework available in Angular
     *
     * @ngInject
     */
    function raceBetsJs($window, $q, $rootScope, $interval) {
        var raceBetsJS = $window.raceBetsJS;

        return angular.extend(raceBetsJS, {
            isInitDone: isInitDone,
            isNodeOnline: isNodeOnline,
            betSuccess: betSuccess,
            emailVerifySuccess: emailVerifySuccess,
            userLogout: userLogout,
            date: {
                today: today
            }
        });

        /**
         * @name common.service:RaceBetsJS#isInitDone
         * @public
         * @methodOf common.service:RaceBetsJS
         *
         * @description
         *     Checks if raceBetsJS init is ready
         *
         * @returns {Object} Promise object
         */
        function isInitDone(param) {
            var timer, deferred = $q.defer();

            if (raceBetsJS.application && raceBetsJS.application.initDone) {
                deferred.resolve(param);
            } else {
                timer = $interval(function () {
                    if (raceBetsJS.application && raceBetsJS.application.initDone) {
                        deferred.resolve(param);
                        $interval.cancel(timer);
                    }
                }, 100);
            }

            return deferred.promise;
        }

        /**
         * @name common.service:RaceBetsJS#isNodeOnline
         * @public
         * @methodOf common.service:RaceBetsJS
         *
         * @description
         *     Checks if connected to websockets
         *
         * @returns {Object} Promise object
         */
        function isNodeOnline(param) {
            var timer,
                deferred = $q.defer();

            timer = $interval(function () {
                var isOnline = raceBetsJS.webSockets.nodeJS.isOnline();

                if (isOnline) {
                    deferred.resolve(param);
                    $interval.cancel(timer);
                }
            }, 100, 100);

            return deferred.promise;
        }

        /**
         * @name common.service:RaceBetsJS#betSuccess
         * @public
         * @methodOf common.service:RaceBetsJS
         *
         * @description
         *     Emits bet success event
         */
        function betSuccess(bet) {
            $rootScope.$emit('Bet:placed:success', bet);
        }

        /**
         * @name common.service:RaceBetsJS#emailVerifySuccess
         * @public
         * @methodOf common.service:RaceBetsJS
         *
         * @description
         *     Emits email verification success event
         */
        function emailVerifySuccess() {
            $rootScope.$emit('Verification:email:success', {});
        }

        /**
         * @name common.service:RaceBetsJS#userLogout
         * @public
         * @methodOf common.service:RaceBetsJS
         *
         * @description
         *     Emits logout event
         */
        function userLogout() {
            $rootScope.$emit('User:logout');
        }

        function today() {
            var currDate = new Date(),
                dd = currDate.getDate(),
                mm = currDate.getMonth() + 1,
                yy = currDate.getFullYear();

            dd = dd < 10 ? '0' + dd : dd;
            mm = mm < 10 ? '0' + mm : mm;

            return [yy, mm, dd].join('-');
        }
    }
})(angular);

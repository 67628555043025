(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .factory('SidebarResource', SidebarResource);

    /**
     * @ngdoc service
     * @name sidebar.service:SidebarResource
     *
     * @requires $resource
     * @requires $window
     * @requires RaceBetsJS
     *
     * @author Bogdan Gersak
     *
     * @description
     *     A resource "class" object which contains the default sets of sidebar resource actions
     *
     *     #Usage
     *         SidebarResource.get([date])
     */
    function SidebarResource($resource, $window, RaceBetsJS) {
        var host = '//' + $window.location.host,
            callHost = getCallHost(),

            baseUrl = '/rest/v1/events/:eventId/sidebar/:date/:offset',
            externalUrl = callHost + baseUrl,

            localUrl = host + baseUrl;

        return $resource(externalUrl, {}, {
            getRaces: {
                url: localUrl,
                method: 'GET'
            },
            // used as a fallback if the ajax host is down
            getFallback: {
                url: localUrl,
                method: 'GET'
            }
        });

        function getCallHost() {
            var rbHost = '//' + $window.location.host,
                ajxHost = RaceBetsJS.application.globals.ajaxHost;

            if ($window.XDomainRequest && !('withCredentials' in new XMLHttpRequest())) {
                return rbHost; // return current location host for IE9
            } else {
                return ajxHost || rbHost; // ajax host if available
            }
        }
    }
})(this.angular);

(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .service('sidebarService', sidebarService);

    /* @ngInject */
    function sidebarService($interval, SidebarResource, RaceBetsJS) {
        /*jshint validthis: true */
        var se = this;

        se.events = {};

        se.lastJSONGeneratedAt = 0;
        se.lastDateJSONGeneratedAt = 0;

        se.selectedDateEvents = {};

        return {
            getEvents: getEvents,
            getLastJSONGeneratedAt: getLastJSONGeneratedAt,
            getLastDateJSONGeneratedAt: getLastDateJSONGeneratedAt,
            updateCurrentAntepostEvents: updateCurrentAntepostEvents,
            updateDateEvents: updateDateEvents,
            updateNextTimeRace: updateNextTimeRace
        };

        function getEvents(date) {
            var parameters = {
                    date: date,
                    offset: date ? getTimezoneOffset() : null
                },
                promise = SidebarResource.get(parameters).$promise;

            return promise.then(RaceBetsJS.isInitDone, getFallback).then(processResponse(date));

            function getFallback() {
                return SidebarResource.getFallback(parameters).$promise;
            }
        }

        function getLastDateJSONGeneratedAt() {
            return se.lastDateJSONGeneratedAt ? se.lastDateJSONGeneratedAt.join('_') : null;
        }

        function getLastJSONGeneratedAt() {
            return se.lastJSONGeneratedAt;
        }

        function getTimezoneOffset() {
            var timeOffset = -(new Date().getTimezoneOffset() / 60);

            if (timeOffset > 0) {
                return '+' + timeOffset;
            }

            return timeOffset;
        }

        function processEvents(events) {
            var language = RaceBetsJS.application && RaceBetsJS.application.user && RaceBetsJS.application.user.lang;

            if (!events) {
                return {};
            }

            for(var id in events) {
                if (events.hasOwnProperty(id)) {
                    events[id].badges = 0;
                    events[id].id = id;
                    events[id].time = eventTime(events[id].is_ante_post, events[id].first_start);
                    events[id].url = eventUrl(id, events[id].id_only_race);
                    events[id].next_race_url = eventUrl(id, events[id].id_next_race);

                    if (events[id].next_race) {
                        events[id].next_race_time = eventNextTime(events[id].next_race);
                    }

                    if (_.indexOf(events[id].race_types, 'S') > -1) {
                        events[id].race_types.push('J');
                    }

                    //if (events[id].is_special) {
                    //    events[id].race_types = ['X'];
                    //}

                    //events[id].race_types_class = raceTypeCssClass(events[id]);

                    if (events[id].title_i18n && events[id].title_i18n[language]) {
                        events[id].title = events[id].title_i18n[language];
                    }

                    if (+events[id].id_event_h2h > 0) {
                        events[id].h2hUrl = eventUrl(events[id].id_event_h2h);
                        events[id].badges++;
                    }

                    if (+events[id].id_event_specials > 0) {
                        events[id].specialsUrl = eventUrl(events[id].id_event_specials);
                        events[id].badges++;
                    }
                }
            }

            return events;
        }

        function processResponse(date) {
            return function process(response) {
                var events, dateStartTimestamp;

                events = processEvents(response.data.events);

                if (!date) {
                    se.lastJSONGeneratedAt = +response.data.generated_at;
                    se.events = events;
                } else {
                    dateStartTimestamp = Math.floor(new Date(date).setHours(0,0,0,0) / 1000);

                    se.lastDateJSONGeneratedAt = [+response.data.generated_at, +dateStartTimestamp];
                    se.selectedDateEvents = events;
                }

                return events;
            };
        }

        function eventNextTime(time) {
            var date = new Date(),
                secDiff = date.getSeconds(),
                seconds = Math.ceil((time * 1000 - (date.getTime() - (secDiff * 1000) - RaceBetsJS.application.user.timeDiff)) / 1000);

            return raceBetsJS.time.toString(seconds, true);
        }

        function eventTime(isAntePost, time) {
            var timezoneDate = RaceBetsJS.application.assets.timezone.date,
                data = RaceBetsJS.i18n.data;

            return timezoneDate(
                (isAntePost) ? data.dateFormatDateShort : data.dateFormatTime, time
            );
        }

        function eventUrl(eventId, raceId) {
            if (+raceId) {
                return 'race/details/id/' + raceId;
            }
            return 'event/details/id/' + eventId;
        }

        function updateEvents(data, eventsList) {
            var processedEvents = processEvents(data.events);

            for(var id in processedEvents) {
                if (processedEvents.hasOwnProperty(id)) {
                    if (processedEvents[id].is_offline || processedEvents[id].is_over) {
                        deleteEvent(id);
                    } else {
                        addEvent(id);
                    }
                }
            }

            function deleteEvent(id) {
                delete eventsList[id];
            }

            function addEvent(id) {
                if (eventsList[id]) {
                    angular.copy(processedEvents[id], eventsList[id]);
                } else {
                    eventsList[id] = processedEvents[id];
                }
            }
        }

        function updateCurrentAntepostEvents(data) {
            return updateEvents(data, se.events);
        }

        function updateDateEvents(data) {
            return updateEvents(data, se.selectedDateEvents);
        }

        function updateNextTimeRace(events) {
            if (!events) {
                return;
            }

            for (var id in events) {
                if (events[id].next_race) {
                    events[id].next_race_time = eventNextTime(events[id].next_race);
                }
            }

            return events;
        }
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('user')
        .factory('UserFreebets', userFreebetsService);

    /**
     * @ngdoc service
     * @name user.service:UserFreebets
     *
     * @requires RaceBetsJS
     *
     * @description
     *     User service to handle Freebets
     *
     * @ngInject
     */
    function userFreebetsService (RaceBetsJS) {
        var service = {
            set: setFreebet,
            hasFreebet: hasFreebet
        };

        return service;

        /**
         * @ngdoc method
         * @name user.service:UserFreebets#set
         * @public
         * @methodOf user.service:UserFreebets
         */
        function setFreebet(data) {
            if (!data.freebets) {
                // Backward compatibility: publish freebets
                RaceBetsJS.application.header.login.updateLoginInfo(data);

                // Remove Freebets
                setSession('freebets');

                return;
            }

            if (RaceBetsJS.application.header.navigation.updateBonusInfo) {
                RaceBetsJS.application.header.navigation.updateBonusInfo(data)
            }

            // Save Freebets
            setSession('freebets', data.freebets);

            // Save Freebets Races
            if (data.races_with_freebets) {
                setSession('freeBetsRaces', data.races_with_freebets);
            }

            // Backward compatibility: publish freebets
            RaceBetsJS.application.header.login.updateLoginInfo(data);
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#hasFreebet
         * @public
         * @methodOf user.service:UserBonuses
         */
        function hasFreebet(data) {
            if (data) {
                return (data.freebets);
            } else {
                return getSession('freebets');
            }
        }

        /**
         * @ngdoc method
         * @name user.service:UserFreebets#getSession
         *
         * @methodOf user.service:UserFreebets
         */
        function getSession(group) {
            return RaceBetsJS.application.assets.session.get(group);
        }

        /**
         * @ngdoc method
         * @name user.service:UserBonuses#getCookie
         *
         * @methodOf user.service:UserBonuses
         */
        function setSession(group, session) {
            RaceBetsJS.application.assets.session.set(group, session || false);
        }

    }

})();

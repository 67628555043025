(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .filter('eventsByType', eventsByType);

    /**
     * @ngDoc filter
     *
     * @description
     *     Filters a list of events by type (current or antepost)
     *
     *
     * @return {Array} Events
     *
     * @author Bogdan Gersak
     */
    function eventsByType() {
        return function (events, type) {
            if (!(events && events.length)) {
                return [];
            }

            return events.filter(function(event) {
                if (type === 'virtuals') {
                    return event.is_virtual;
                } else if (type === 'antepost') {
                    return event.is_ante_post;
                } else {
                    return !event.is_ante_post && !event.is_virtual;
                }
            });
        };
    }
})(this.angular);

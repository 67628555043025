(function () {
    'use strict';

    angular
        .module('common')
        .filter('formatTimezone', formatTimezone);

    /**
     * @ngdoc filter
     * @name common.filter:formatTimezone
     *
     * @requires Settings
     *
     * @description
     *      Returns the label of the timezone. Defaults to the passed Olson ID if not found.
     *
     * @param {string} olsonID Olson ID
     *
     * @return {string} Timezone label or Olson ID
     */
    function formatTimezone(Settings) {
        var timezones = Settings.timezoneSettings();

        return function(olsonID) {
            if (!olsonID) {
                return;
            }

            var matchedLabel = _.filter(timezones, function(timezone) {
                return timezone.olson_id === olsonID;
            });

            return matchedLabel.length ? matchedLabel[0].utc_label : olsonID;
        };
    }
})();

(function (angular) {
    'use strict';

    /**
     * @ngdoc directive
     * @name
     */

    angular
        .module('sidebar')
        .directive('rbSidebarTabs', rbSidebarTabs);

    /**
     * @ngdoc directive
     * @name sidebar.directive:rbSidebarTabs
     *
     * @requires sidebarState
     *
     * @description
     *         Sidebar tabs
     *
     * @ngInject
     */
    function rbSidebarTabs(sidebarState) {
        return {
            controller: controller,
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/modules/sidebar/views/rb-sidebar-tabs.html'
        };

        /* @ngInject */
        function controller($scope, User, RaceBetsJS) {
            var vm = $scope.vm;

            vm.activeTab = sidebarState.getActiveTab();
            vm.activateTab = activateTab;
            vm.dateTabLabel = dateTabLabel;
            vm.isTabActive = isTabActive;
            vm.isVirtualAvailable = User.isVirtualAvailable();

            function activateTab(tabName) {
                vm.activeTab = tabName;
                sidebarState.setActiveTab(tabName);
            }

            function dateTabLabel(date) {
                var d = date ? date.split('-') : [];

                if (+d[2]) {
                    return +d[2];
                }
            }

            function isTabActive(tabName) {
                return vm.activeTab === tabName;
            }
        }
    }
})(angular);

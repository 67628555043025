(function () {
    'use strict';

    /**
     * @ngdoc overview
     * @name registration
     * @requires common
     */
    angular
        .module('registration', ['common'])
        .config(routesConfig);
    /**
     * @ngdoc function
     * @name registration.config:routesConfig
     *
     * @requires $routeProvider
     * @requires DynamicRoutesProvider
     *
     * @description
     *      List of routes provided by {@link module:registration} module
     *
     * @ngInject
     */
    function routesConfig($routeProvider, DynamicRoutesProvider) {
        var moduleURI = '/register',
            templatePath = '/angular/modules/registration/views';

        $routeProvider
            .when(moduleURI, {
                id: 'registration',
                templateUrl: templatePath + '/registration.html',
                controller: 'RegistrationCtrl',
                controllerAs: 'vm',
                resolve: {
                    /* ngInject */
                    userAuth: function($q, User) {
                        var defer = $q.defer();
                        if (User.isLoggedIn()) {
                            defer.reject({
                                data: {
                                    code: 155,
                                    redirect: 'register/email'
                                }
                            });
                        } else {
                            defer.resolve();
                        }
                        return defer.promise;
                    },
                    /* ngInject */
                    registrationSettings: function(Settings) {
                        return Settings.registrationSettings();
                    }
                }
            })

            .when(moduleURI + '/success', {
                id: 'registration',
                templateUrl: templatePath + '/registration.html',
                controller: 'RegistrationSuccessCtrl',
                controllerAs: 'vm',
                resolve: {
                    /* ngInject */
                    userAuth: function($q, User) {
                        var defer = $q.defer();
                        if (User.isLoggedIn()) {
                            defer.reject({
                                data: {
                                    code: 155,
                                    redirect: 'register/email'
                                }
                            });
                        } else {
                            defer.resolve();
                        }
                        return defer.promise;
                    }
                }
            })

            .when(moduleURI + '/email\/:status?', {
                id: 'registration',
                templateUrl: templatePath + '/registration-email.html',
                controller: 'RegistrationEmailCtrl',
                controllerAs: 'vm',
                resolve: {
                    /* ngInject */
                    verificationStatus: function($q, UserVerification) {
                        return UserVerification.getStatus();
                    }
                }
            })

            .when(moduleURI + '/consent', {
                id: 'registration',
                templateUrl: templatePath + '/registration-consent.html',
                controller: 'RegistrationConsentCtrl',
                controllerAs: 'vm',
                resolve: {
                    /* ngInject */
                    marketingData: function ($q, UserMarketing) {
                        var defer = $q.defer(),
                            cookie = UserMarketing.getCookie();

                        UserMarketing.getSettings().then(function (fromServer){
                            if (cookie.marketing === 1){
                                fromServer.cookie = true;
                            }
                            defer.resolve(fromServer);
                        });
                        return defer.promise;
                    },
                    /* ngInject */
                    userAuth: function ($q, User) {
                        var defer = $q.defer();
                        if (!User.isLoggedIn()) {
                            defer.reject({
                                data: {
                                    code: 155,
                                    redirect: 'register'
                                }
                            });
                        } else {
                            defer.resolve();
                        }
                        return defer.promise;
                    }
                }
            })


            .when(moduleURI + '/trustly\/:status?\/:code?', {
                id: 'registration',
                templateUrl: templatePath + '/registration-trustly.html',
                controller: 'RegistrationTrustlyCtrl',
                controllerAs: 'vm',
                resolve: {
                    /* ngInject */
                    userAuth: function ($q, User) {
                        var defer = $q.defer();
                        if (User.isLoggedIn()) {
                            defer.reject({
                                data: {
                                    code: 155,
                                    redirect: 'register/email'
                                }
                            });
                        } else {
                            defer.resolve();
                        }
                        return defer.promise;
                    },
                    /* ngInject */
                    registrationSettings: function(Settings) {
                        return Settings.registrationSettings();
                    },
                    /* ngInject */
                    userAllow: function ($q, User) {
                        var defer = $q.defer();

                        if (User.isTrustlyRegistrationAllowed()) {
                            defer.resolve();
                        } else {
                            defer.reject({
                                data: {
                                    code: 155,
                                    redirect: '/'
                                }
                            });
                        }
                        return defer.promise;
                    }
                }

            });

        var config = {
            baseUrl: moduleURI
        };

        DynamicRoutesProvider.add(config);
    }

})(angular);

(function (angular) {
    'use strict';

    angular
        .module('user')
        .factory('UserMarketingResource', Service);

    /**
     * @ngdoc service
     * @name user.service:UserMarketingResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Marketing Settings API resource.
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function Service($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v1/users/me/marketing', {},
            {
                update: {
                    method: 'PUT'
                }
            }
        );
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbLinksTarget', LinksTarget);

    /**
     * @ngdoc directive
     * @name  common.directive:rbLinksTarget
     *
     * @restrict A
     *
     * @description
     *     Intercepts link clicks, opens links in new pages if rb-links-target="_blank"
     *     Useful with translations with links
     *
     * @author Alessandro Maruccia
     */
    function LinksTarget() {
        var directive = {
            restrict: 'A',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, elem, attrs) {

            if (attrs.rbLinksTarget === '_blank') {
                elem.on('click', 'a', function(e) {
                    e.preventDefault();
                    var url = $(e.currentTarget).attr('href');
                    window.open(url);
                });
            }

            validate();

            function validate() {
                if (!attrs.rbLinksTarget) {
                    throw new Error('rb-links-target directive: rbLinksTarget must be specified!');
                }
            }
        }
    }
})(angular);

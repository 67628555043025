(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .directive('rbSidebarEventTooltip', rbSidebarEventTooltip);

    /**
     * @ngdoc directive
     * @name sidebar.directive:rbSidebarEventTooltip
     */

    /* @ngInject */
    function rbSidebarEventTooltip($window, $timeout, $compile, $http, $templateCache, SidebarResource, RaceBetsJS) {
        return {
            controller: controller,
            link: link,
            restrict: 'A',
            scope: {
                event: '=rbSidebarEventTooltip'
            }
        };

        function controller($scope) {
            $scope.tooltip = false;
            $scope.getEventDetails = getEventDetails;
            $scope.getNextRace = getNextRace;

            $scope.getTemplate = getTemplate;

            function getEventDetails(eventId) {
                return SidebarResource.getRaces({eventId: eventId}).$promise.then(function (response) {
                    var races = processRaces(response.data.races);

                    return races;
                });
            }

            function getNextRace(races) {
                var nextRace = _.find(races, function(race) { return race.status === 'OPN' });
                return nextRace || _.last(races);
            }

            function getTemplate() {
                var template = '/angular/modules/sidebar/views/rb-sidebar-event-tooltip.html';

                return $http.get(template, {cache: $templateCache}).then(function (res) {
                    return res.data;
                });
            }

            function processRaces(races) {
                var i, l, isRule4;

                if (!races || races.length === 0) {
                    return [];
                }

                for (i = 0, l = races.length; i < l; i++) {
                    isRule4 = races[i].is_priced_up && races[i].is_sp_event && (races[i].is_bog !== undefined);
                    races[i].url = RaceBetsJS.application.globals.urlPrefix + '/race/details/id/' + races[i].id_race;
                    races[i].is_bog = races[i].is_bog || (isRule4 && RaceBetsJS.application.user.bestOddsGuaranteed);
                    races[i].time = RaceBetsJS.application.assets.timezone.date('H:i', races[i].post_time);
                    races[i].title = RaceBetsJS.i18n.getRaceTitle(races[i].title, races[i].number);
                }

                return races;
            }
        }

        function link(scope, elem) {
            var tipped = $window.Tipped;

            scope.hideTooltip = hideTooltip;
            scope.mouseover = mouseover;
            scope.mouseout = setNextRace;

            elem.on('click', clickHandler);

            function mouseover(race) {
                scope.currentRace = race;
            }

            function setNextRace() {
                scope.nextRace = scope.currentRace = scope.getNextRace(scope.races);
            }

            function clickHandler() {
                if (tipped.visible(elem)) {
                    return;
                }

                scope.getEventDetails(scope.event.id).then(showRacesTooltip);

                function showRacesTooltip(races) {
                    scope.getTemplate().then(function (template) {
                        var compiledTemplate;

                        scope.races = races || [];
                        compiledTemplate = $compile(template)(scope);

                        setNextRace();

                        $timeout(function () {
                            tipped.create(elem, compiledTemplate.html(), {
                                afterUpdate: function (content) {
                                    var cElem = angular.element(content);
                                    cElem.html(compiledTemplate);
                                    tipped.refresh(elem);
                                },
                                hideDelay: 200,
                                hideOthers: true,
                                onShow: function (content, element) {
                                    var elem = angular.element(element).parents('.c-dataList__row');
                                    elem.addClass('hasTooltip--eventRaces');
                                },
                                onHide: function (content, element) {
                                    var lElem = angular.element(element).parents('.c-dataList__row');
                                    lElem.removeClass('hasTooltip--eventRaces');
                                    tipped.remove(elem);
                                },
                                skin: 'eventRaces',
                                zIndex: 1000
                            }).show();
                        }, 0);

                    });
                }
            }

            function hideTooltip() {
                tipped.hide(elem);
            }
        }
    }
})(angular);

(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbFileUpload', rbFileUpload);

    /**
     * @ngdoc directive
     * @name common.directive:rbFileUpload
     *
     * @restrict E
     *
     * @requires $scope
     * @requires $filter
     * @requires $timeout
     * @requires FileUpload
     * @requires RaceBetsJS
     *
     * @description
     *         File upload
     *
     * @ngInject
     */
    function rbFileUpload($timeout, $filter, FileUpload, RaceBetsJS) {
        return {
            controller: Controller,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                uploadOptions: '=',
                uploadSuccess: '&',
                uploadError: '&',
                uploadStart: '&',
                uploadReset: '&',
                uploadFiles: '='
            },
            templateUrl: '/angular/components/views/rb-file-upload.html',
            link: link
        };

        /* @ngInject */
        function Controller($scope) {
            var vm = this;

            /**
             * @ngdoc property
             * @name common.directive:rbFileUpload#progress
             * @public
             * @propertyOf common.directive:rbFileUpload
             *
             * @description
             *     Uploadd progress
             *
             * @type {Number}
             */
            vm.progress = 0;

            /**
             * @ngdoc property
             * @name common.directive:rbFileUpload#uploaded
             * @public
             * @propertyOf common.directive:rbFileUpload
             *
             * @description
             *     Uploadd finished
             *
             * @type {Number}
             */
            vm.uploaded = false;

            /**
             * @ngdoc property
             * @name common.directive:rbFileUpload#files
             * @public
             * @propertyOf common.directive:rbFileUpload
             *
             * @description
             *     Selected files
             *
             * @type {Array}
             */
            $scope.files = new Array($scope.uploadFiles.length);
        }

        function link(scope, elem) {
            // Check for errors
            if ( !scope.uploadOptions.hasOwnProperty('url') ) {
                throw new Error('Missing option "url"!');
            }
            if ( scope.uploadOptions.hasOwnProperty('data') && !angular.isObject(scope.uploadOptions.data) ) {
                throw new Error('Data must be an Object!');
            }
            if ( !scope.uploadOptions.hasOwnProperty('fileTypes')) {
                throw new Error('Missing option "fileTypes"!');
            }

            scope.xhr = new XMLHttpRequest();

            scope.vm.cancel = cancel;
            scope.vm.reset = reset;

            // Add file input change listener
            elem.on('change', 'input[type=file]', fileSelected);

            // Remove event listener on destroy
            scope.$on('destroy', function () {
                elem.off('change', fileSelected);
            });

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#fileSelected
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      File selected callback
             */
            function fileSelected(e) {
                if (!e.target.files) {
                    return;
                }

                var fileSelected = angular.element(e.target).data('file');

                // Check file types
                if (!FileUpload.validateType(e.target.files, scope.uploadOptions.fileTypes)) {
                    showErrorDialog($filter('translate')(scope.uploadOptions.fileTypeErrorMsg));
                    resetInput(e.target);
                    resetFileSelected(fileSelected);
                    return;
                }

                // Check file size
                if (!FileUpload.validateSize(e.target.files, scope.uploadOptions.fileSize)) {
                    var formattedSize = $filter('formatFilesize')(scope.uploadOptions.fileSize, { decimals: 0 });
                    showErrorDialog($filter('translate')('msg_error_file_too_large', { size: formattedSize }));
                    resetInput(e.target);
                    resetFileSelected(fileSelected);
                    return;
                }

                // Change button to re-upload
                $timeout(function () {
                    scope.uploadFiles[fileSelected].selected = true;
                });

                // Store selected files
                scope.files[fileSelected] = e.target.files;

                // Upload when all files are selected
                if (allFilesSelected(scope.files)) {
                    // Start callback
                    if (scope.uploadStart) {
                        scope.uploadStart();
                    }

                    if (!scope.xhr) {
                        scope.xhr = new XMLHttpRequest();
                    }
                    // Call upload service
                    FileUpload.post(scope.xhr, scope.files, scope.uploadOptions.data, scope.uploadOptions.url)
                        .then(
                            onSuccess,
                            onError,
                            uploadProgress
                        );
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#allFilesSelected
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *    Checks if files array is not empty
             */
            function allFilesSelected(filesSelected) {
                for (var i = 0; i < filesSelected.length; i++) {
                    if (!filesSelected[i]) {
                        return false;
                    }
                }
                return true;
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#onSuccess
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *    Success callback
             */
            function onSuccess(resp) {
                scope.uploadSuccess({ resp: resp });
                scope.vm.uploaded = true;
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#onError
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Error callback
             *
             */
            function onError(resp) {
                reset();

                if (scope.uploadError) {
                    scope.uploadError({ resp: resp });
                }

                showErrorDialog();
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#uploadProgress
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Progress callback
             *
             */
            function uploadProgress(progress) {
                scope.vm.progress = progress;
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#cancel
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Cancel upload
             */
            function cancel() {
                $timeout(function () {
                    scope.xhr.abort();
                    reset();
                });
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#reset
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Reset file upload form
             */
            function reset() {
                // Reset form
                scope.fileUpload.$setPristine();
                // Reset label
                _.each(scope.uploadFiles, function (file) {
                    file.selected = false;
                });
                // Reset values
                scope.files = [];
                scope.xhr = null;
                scope.vm.progress = 0;
                scope.vm.uploaded = false;
                // Callback
                if (scope.uploadReset) {
                    scope.uploadReset();
                }
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#resetInput
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Reset file upload input
             */
            function resetInput(target) {
                var inputElem = angular.element(target);
               // Reset input otherwise change event won't happen
                inputElem.val(null);
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#resetFileSelected
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Reset stored selected file
             */
            function resetFileSelected(fileSelected) {
                scope.files[fileSelected] = null;
                $timeout(function () {
                    scope.uploadFiles[fileSelected].selected = false;
                });
            }

            /**
             * @ngdoc method
             * @name common.directive:rbFileUpload#showErrorDialog
             * @private
             * @methodOf common.directive:rbFileUpload
             *
             * @description
             *      Show error dialog
             *
             * @param {String?} message Error message to translate
             */
            function showErrorDialog(message) {
                RaceBetsJS.application.assets.modalDialog.show({
                    type: 'error',
                    content: message || $filter('translate')('h_generic_error'),
                    buttons: [{
                        label: $filter('translate')('label_ok'),
                        action: function () {
                            RaceBetsJS.application.assets.overlay.close();
                        },
                        active: true
                    }]
                });
            }
        }
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .filter('range', range);

    /**
     * @ngdoc filter
     * @name common.filter:range
     *
     * @description
     *     Generate an array of  numbers in a from to range. If from omitted, it counts from 0.
     *
     * @example
     *      ng-repeat="i in [2,11] | range" - repeat an element 10 times indexed from 2 to 11
     *
     *      ng-repeat="i in [10] | range" - if a lower bound omitted, the first index will be 0 the last 9
     *
     * @param {Array} collection Array of min and max value of the range
     *
     * @return {Array} array of numbers
     */
    function range () {
        return function (collection, leadingZeros) {
            if (!collection) {
                return;
            }

            var minValue,
                maxValue,
                result = [],
                cl = collection.length,
                maxDigit = 0;

            switch (cl) {
                case 1:
                    minValue = 0;
                    maxValue = parseInt(collection[0], 10) - 1;
                    break;
                case 2:
                    minValue = parseInt(collection[0], 10);
                    maxValue = parseInt(collection[1], 10);
                    break;
                default:
                    throw new Error('Filter error','Range filter expects 1 or 2 parameters. Got ' + cl + '!');
            }

            if (leadingZeros) {
                maxDigit = parseInt('1'+ (maxValue.toString().slice(0, -1).replace(/./gi, '0')));
            }

            for (var i = minValue; i <= maxValue; i++) {
                if (leadingZeros) {
                    if (i < maxDigit) {
                        i = maxValue.toString().slice(0, -(i.toString().length)).replace(/./gi, '0') + i;
                    } else {
                        i = i.toString();
                    }
                }
                result.push(i);
            }

            return result;
        };
    }
})();

(function (angular) {
    'use strict';

    /**
     * @ngdoc directive
     * @name runner.directive:rbRunnerSilk
     *
     * @scope {}
     * @replace true
     * @restrict E
     *
     * @description
     *     Displays the silk for a runner
     *
     */

    angular
        .module('runner')
        .directive('rbRunnerSilk', rbRunnerSilk);

    /* @ngInject */
    function rbRunnerSilk() {
        return {
            controller: Controller,
            controllerAs: 'vm',
            replace: true,
            scope: {
                silkSize: '@',
                silkId: '@',
                silkExtension: '@'
            },
            restrict: 'E',
            templateUrl: '/angular/components/runner/views/rb-runner-silk.html'
        };

        /* @ngInject */
        function Controller($scope) {
            var vm = this;

            switch ($scope.silkSize) {
                case 's':
                    vm.silkWidth = 42;
                    break;
                default:
                    vm.silkWidth = 62;
            }
        }
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .controller('NativeLevelAppCtrl', NativeLevelAppCtrl);

    /**
     * @ngdoc controller
     * @name common.controller:NativeLevelAppCtrl
     *
     * @requires $rootScope
     * @requires $translate
     * @requires $cookies
     * @requires $filter
     * @requires $window
     * @requires RaceBetsJS
     * @requires AppMessage
     *
     * @description
     *     Default Application controller.
     *     '.nativeLevel' stands for temporary solution needed to make angular app work with RaceBets old codebase
     *
     * @author Sandor Turanszky
     *
     * @ngInject
     */
    function NativeLevelAppCtrl ($rootScope, $cookies, $filter, $window, $location, RaceBetsJS, User, UserBalance, Settings, UserMarketing, AppMessage) {
        var ctrl = this;

        ctrl.globals = RaceBetsJS.application.globals;
        ctrl.user = RaceBetsJS.application.user;

        ctrl.message = $rootScope.appMessage;

        $rootScope.$watch('appMessage', watchMessage);

        // Wait for raceBetsJS and detect when translation module is loaded
        RaceBetsJS.isInitDone().then(activate);
        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#activate
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     This method is called when controller is initialized.
         */
        function activate() {
            var consentCookie = $cookies.get('CONSENT'),
                redirectSource = $cookies.get('redirectSource'),
                tldAccepted = $cookies.get('rbTldAccepted'),
                malteseLicenseShown = $cookies.get('rbMalteseLicenseShown');

            // GDPR check for consents
            if (ctrl.globals.gdpr) {
                // Show Snack Bar for visitors with no consent
                if (!User.isLoggedIn() && !consentCookie) {
                    showCookieConsentMessage();
                }

                // Making sure marketing cookie is set with the value of the logged in user who already gave consent
                if (User.isLoggedIn() && !ctrl.user.consentRequired && ctrl.globals.rbConsent) {
                    UserMarketing.setCookie({functional: ctrl.user.consentCookie, marketing: ctrl.user.consentCookie, performance: ctrl.user.consentCookie, targeting: ctrl.user.consentCookie});
                }

                function listenToThirdPartyCookieHandler() {
                    OneTrust.OnConsentChanged(function(data){
                        UserMarketing.setCookieFromThirdParty(data.detail);
                    })
                }

                if(!ctrl.globals.rbConsent) {
                    var thirdPartyCookieHandlerCheckInterval = setInterval( function () {
                        if ( typeof OneTrust !== 'undefined' ) {
                            listenToThirdPartyCookieHandler();
                            clearInterval( thirdPartyCookieHandlerCheckInterval );
                        }
                    }, 100 );
                }
            }

            // B2B: we still need to know user's Freebet balance even if there is no account widget
            if ($rootScope.isB2B && User.isLoggedIn()) {
                // Get user's balance for Freebets
                UserBalance.getBalance();
            }

            // If user was auto-redirected to German domain based on (s)he is located in Germany
            if (redirectSource && redirectSource === 'com' && !tldAccepted && User.ipCountry() === 'DE') {
                showJurisdictionRedirectMessage(redirectSource);
            }

            // If customer under German jurisdiction accesses the site outside from Germany
            if (User.isLoggedIn() && ctrl.globals.websiteTLD === 'com' &&
                !malteseLicenseShown &&
                User.licenseJurisdiction() === 'DE' &&
                User.ipCountry() !== 'DE') {
                    showJurisdictionLicenceMessage();
            }

            // Redirect or show dialog of cookie settings
            if ($location.search()['cookie-settings']) {
                $location.search('cookie-settings', null);
                if (User.isLoggedIn()) {
                    $location.path('account/marketing');
                } else {
                    RaceBetsJS.application.assets.settings.show({ activeTab: 2 });
                }
            }
        }

        function showCookieConsentMessage() {
            if(raceBetsJS.application.globals.rbConsent) {
                showRaceBetsConsentMessage();
            }
        }

        function showRaceBetsConsentMessage() {
            var consentMessage = {
                message: $filter('translate')('txt_cookie_consent', {policyLink: 'static/cookiepolicy', settingsLink: '#cookie-settings'}),
                buttons: [
                    {
                        label: $filter('translate')('label_accept'),
                        btnClass: 'action',
                        action: function () {
                            UserMarketing.setCookie({functional: 1, marketing: 1, performance: 1, targeting: 1});
                        }
                    }
                ],
                messageEvent: function(e) {
                    var target = $(e.target);
                    if (target.attr('href') === '#cookie-settings') {
                        e.preventDefault();
                        e.stopPropagation();
                        RaceBetsJS.application.assets.settings.show({activeTab: 2});
                    }
                }
            };
            AppMessage.add(consentMessage);
        }
        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#showJurisdictionRedirectMessage
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     Shows jurisdiction redirect message
         *
         * @param {String} redirectSource Redirect source
         */
        function showJurisdictionRedirectMessage(redirectSource) {
            var jurisdictionMessage = {
                message: $filter('translate')('msg_jurisdiction_autoredirect', { redirectTarget: ctrl.globals.websiteTLD }),
                acceptance: {
                    label: $filter('translate')('label_remember_preferred_domain'),
                    selected: true
                },
                buttons: [
                    {
                        label: $filter('translate')('label_go_to_racebets', { redirectTarget: redirectSource }),
                        action: function(acceptance) {
                            var persist = acceptance ? '&redirectSourcePersist=true' : '',
                                redirectParam = ctrl.globals.websiteTLD !== 'com' ? '?redirectSource=' + ctrl.globals.websiteTLD : '' ;

                            removeRedirectCookie();

                            RaceBetsJS.application.assets.overlay.showPleaseWait();

                            $window.location.href = 'https://' + Settings.getHostname(redirectSource) + redirectParam + persist;
                        }
                    },
                    {
                        label: $filter('translate')('label_ok'),
                        action: function(acceptance) {
                            if (acceptance) {
                                setTldCookie();
                            }
                            removeRedirectCookie();
                        },
                        active: true
                    }
                ]
            };

            AppMessage.add(jurisdictionMessage);
        }

        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#showJurisdictionLicenceMessage
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     Shows jurisdiction license message
         *
         */
        function showJurisdictionLicenceMessage() {
            RaceBetsJS.application.assets.modalDialog.show({
                content: $filter('translate')('msg_jurisdiction_licence_mt'),
                buttons: [{
                    label: $filter('translate')('label_ok'),
                    action: function() {
                        setJurisdictionCookie();
                        RaceBetsJS.application.assets.overlay.close();
                    },
                    active: true
                }],
                backgroundClose: false,
                closeCallback: function() {
                    setJurisdictionCookie();
                    RaceBetsJS.application.assets.overlay.close();
                }
            });
        }

        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#setTldCookie
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     Set jurisdiction accepted TLD cookie
         */
        function setTldCookie() {
            var date = new Date();

            date.setYear(date.getFullYear() + 1);

            $cookies.put('rbTldAccepted', 'true', {
                path: '/',
                domain: Settings.getCookieDomain(),
                expires: date,
                secure: true
            });
        }

        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#setTldCookie
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     Set jurisdiction accepted TLD cookie
         */
        function setJurisdictionCookie() {
            $cookies.put('rbMalteseLicenseShown', 'true', {
                path: '/',
                domain: Settings.getCookieDomain(),
                secure: true
            });
        }

        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#removeRedirectCookie
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     Remove redirect source
         */
        function removeRedirectCookie() {
            $cookies.remove('redirectSource', {
                path: '/',
                domain: Settings.getCookieDomain()
            });
        }

        /**
         * @ngdoc method
         * @name common.controller:NativeLevelAppCtrl#watchMessage
         * @private
         * @methodOf common.controller:NativeLevelAppCtrl
         *
         * @description
         *     Watch message updates for message directive and sets message to new value
         */
        function watchMessage(newValue) {
            ctrl.message = newValue;
        }
    }
})(angular);

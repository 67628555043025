(function (angular, _) {
    'use strict';

    angular
        .module('sidebar')
        .filter('eventsByStartTime', eventsByStartTime);

    /**
     * @ngDoc filter
     *
     * @description
     *     Filters a list of events by race type
     *
     *
     * @return {Array} Events
     *
     * @author Bogdan Gersak
     */
    function eventsByStartTime() {
        return function (events, type) {
            if (!(events && events.length)) {
                return;
            }

            if (type === 'running') {
                return runningNowEvents(events);
            }

            return comingUpEvents(events);
        };
    }

    function comingUpEvents(events) {
        return _.filter(events, function (event) {
            return !isRunningNow(event);
        });
    }

    function isRunningNow(eventObj) {
        var timeLimit = Math.round((new Date()).getTime() / 1000) + 1800;

        if (eventObj.first_start < timeLimit) {
            return true;
        }

        return false;
    }

    function runningNowEvents(events) {
        return _.filter(events, isRunningNow);
    }
})(this.angular, this._);

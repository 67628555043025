(function (angular) {
    'use strict';

    angular
        .module('common')
        .filter('formatDate', formatDate);

    /**
     * @ngdoc filter
     * @name common.filter:formatDate
     *
     * @requires  RaceBetsJS
     *
     * @description
     *      Formats time into readable string
     *
     * @return {Function} RaceBetsJS formatted time
     *
     * @ngInject
     */
    function formatDate(RaceBetsJS) {
        return function(time, format) {
            if (!time || !format) {
                return;
            }

            return RaceBetsJS.application.assets.timezone.date(format, time);
        };
    }
})(angular);

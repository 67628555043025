(function (angular) {
    'use strict';

    angular
        .module('featuredHorses')
        .service('FeaturedHorses', FeaturedHorses);

    /**
     * @ngdoc service
     * @name featuredHorses.service:FeaturedHorses
     *
     * @requires User
     *
     * @description
     *      Common helper service for all featured horses
     *
     * @ngInject
     */
    function FeaturedHorses(User) {
        var service = {
            selectRaceType: selectRaceType,
            selectCountry: selectCountry
        };

        return service;

        /**
         * @name featuredHorses.service:horsesAbroadService#selectCountry
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Return race type if available, first one if not
         *
         * @param {String} raceType Race type
         * @param {Array} availableRaceTypes Array of available race types
         *
         * @returns {String} Country ISO2 code
         */
        function selectRaceType(raceType, availableRaceTypes) {
            return availableRaceTypes.indexOf(raceType) > 0 ? raceType : availableRaceTypes[0];
        }

        /**
         * @name featuredHorses.service:horsesAbroadService#selectCountry
         * @public
         * @methodOf featuredHorses.service:horsesAbroadService
         *
         * @description
         *     Sets country if available, first country if not
         *
         * @param {String} country Country ISO2 code
         * @param {Array} availableCountries Array of available countries
         *
         * @returns {String} Country ISO2 code
         */
        function selectCountry(country, availableCountries) {
            var userCountry = country || User.country();
            return availableCountries.indexOf(userCountry) > -1 ? userCountry : availableCountries[0];
        }
    }
})(angular);

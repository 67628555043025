(function () {
    'use strict';

    angular
        .module('common')
        .filter('formatNumber', formatNumber);

    /**
     * @ngdoc filter
     * @name common.filter:formatNumber
     *
     * @description
     *     Returns the formatted number based on user's locale settings.
     *
     *     Collection parameter is optional, if not provided, the filter returns just the symbol.
     *
     * @param {Number} number Input to filter
     * @param {Number} fractionSize Number of decimals
     * @param {Boolean} trailingZeros  Show or remove trailing zeros, defaults to true
     *
     * @return {String} Formatted number
     *
     * @ngInject
     */
    function formatNumber($filter, $locale, User) {
        return function (number, options) {
            var numberFormats = User.numberFormats(),
                defaultOptions = {
                    fractionSize: 2,
                    trailingZeros: true
                };

            options = angular.extend(defaultOptions, options || {});

            return (angular.isNumber(number) && !isNaN(number)) ?
                        format(number, options.fractionSize, options.trailingZeros, numberFormats) :
                        number;
        };

        function format(number, fractionSize, trailingZeros, numberFormats) {
            var localeFormats = $locale.NUMBER_FORMATS,
                formattedNumber = '',
                isNegative = false;

            if (localeFormats.DECIMAL_SEP === numberFormats.decimal_sep && localeFormats.GROUP_SEP === numberFormats.group_sep) {
                formattedNumber = $filter('number')(number, fractionSize);
            }
            else {
                if (number < 0) {
                    isNegative = true;
                    number = Math.abs(number);
                }

                formattedNumber = number.toFixed(fractionSize).split(localeFormats.DECIMAL_SEP);
                var ints = formattedNumber[0].split('').reverse(),
                    i = 0;

                for (i = ints.length-1; i >= 0; i--) {
                    if (i % 3 === 0 && i !==0 ) {
                        ints.splice(i, 0, numberFormats.group_sep);
                    }
                }

                if (isNegative) {
                    ints.push(localeFormats.PATTERNS[0].negPre);
                }

                formattedNumber[0] = ints.reverse().join('');
                formattedNumber = formattedNumber.join(numberFormats.decimal_sep);
            }

            if (!trailingZeros) {
                formattedNumber = formattedNumber.replace(numberFormats.decimal_sep + new Array(fractionSize + 1).join('0'), '');
            }

            return formattedNumber;
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('common')
        .service('AppMessage', appMessage);

    /**
     * @ngdoc service
     * @name common.service:appMessage
     *
     * @requires $window
     *
     * @description
     *      Notification message service
     *
     * @ngInject
     */
    function appMessage($q, $rootScope) {
        var messages = [],
            messageId = 0;

        var service = {
            add: add,
            remove: remove
        };

        return service;

        /**
         * @ngdoc method
         * @name common.service:appMessage#add
         * @public
         * @methodOf common.service:appMessage
         *
         * @description
         *         Add a message
         *
         * @param {Object} message Message
         *
         */
        function add(message) {
            var defer = $q.defer();

            message.id = messageId++;

            messages.push(message);

            if (!$rootScope.appMessage) {
                $rootScope.appMessage = message;
            }
            defer.resolve({ id: message.id });

            return defer.promise;
        }

        /**
         * @ngdoc method
         * @name common.service:appMessage#remove
         * @public
         * @methodOf common.service:appMessage
         *
         * @description
         *         Remove first message
         *
         * @return {Object} Empty promise object
         *
         */
        function remove() {
            var defer = $q.defer();

            messages.shift();

            if (messages.length) {
                $rootScope.appMessage = messages[0];
            } else {
                $rootScope.appMessage = null;
            }
            defer.resolve();

            return defer.promise;
        }
    }
})(angular);

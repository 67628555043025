(function (angular) {
    'use strict';

    angular
        .module('common')
        .filter('formatFilesize', formatFilesize);

    /**
     * @ngdoc filter
     * @name common.filter:formatFilesize
     *
     * @description
     *     Returns a formatted file size
     *
     *     Options parameter is optional.
     *
     * @param {Number} bytes Input to filter
     * @param {Object} options Formatting options
     *
     * @return {String} Formatted file size
     *
     * @ngInject
     */
    function formatFilesize() {
        var k = 1024;
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        return function (bytes, options) {
            if (isNaN(bytes)) {
                return;
            }

            if (bytes === 0) {
                return '0 Byte';
            }

            var defaultOptions = {
                decimals: 2
            };

            options = angular.extend(defaultOptions, options || {});

            var i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(options.decimals + 1)) + ' ' + sizes[i];
        };
    }
})(angular);

(function (angular) {
    'use strict';

    angular
        .module('common')
        .filter('formatTime', formatTime);

    /**
     * @ngdoc filter
     * @name common.filter:formatTime
     *
     * @requires  RaceBetsJS
     *
     * @description
     *      Formats time into readable string
     *
     * @return {Function} formatted time
     */
    function formatTime(RaceBetsJS) {
        return function(time, truncate, useAccusative) {
            return RaceBetsJS.time.toString(time, truncate, useAccusative);
        };
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .factory('PagePartsLoading', PagePartsLoading);

    /**
     * @ngdoc service
     * @name common.service:PagePartsLoading
     *
     * @requires $rootScope
     *
     * @description
     *     Displays content after all page parts have been loaded and evaluated.
     *
     * @author Sandor Turanszky
     *
     * @ngInject
     */
    function PagePartsLoading ($rootScope) {
        var parts = [];
        var service = {
            setParts: setParts,
            loaded: loaded
        };

        return service;

        /**
         * @ngdoc method
         * @name common.service:PagePartsLoading#check
         * @public
         * @methodOf common.service:PagePartsLoading
         *
         * @description
         *     Makes ng-view content visible if all values in parts are are 'true'
         *
         */
        function check() {
            var showCashier = true;
            var showPartial = true;
            for (var i = 0, l = parts.length; i < l; i++) {
                if(parts[i].partial === undefined) {
                    showCashier = showCashier && parts[i].status;
                } else {
                    showPartial = showPartial && parts[i].status;
                }
            }
            $rootScope.showCashier = showCashier;
            $rootScope.showPartial = showPartial;
        }

        /**
         * @ngdoc method
         * @name common.service:PagePartsLoading#setPart
         * @public
         * @methodOf common.service:PagePartsLoading
         *
         * @description
         *     Adds part to an array of parts and sets value to false - not loaded
         *
         */
        function setParts (p) {
            parts = [];
            for(var i = 0, l = p.length; i < l; i++) {
                p[i].status = false;
                parts.push(p[i]);
            }
        }

        /**
         * @ngdoc method
         * @name common.service:PagePartsLoading#loaded
         * @public
         * @methodOf common.service:PagePartsLoading
         *
         * @description
         *     Adds part to an array of parts and sets value to true - loaded
         *
         */
        function loaded(part) {
            for (var i = 0, l = parts.length; i < l; i++) {
                if(parts[i].controller === part) {
                    parts[i].status = true;
                }
            }
            check();
        }
    }

})();

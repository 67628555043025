(function (angular) {
    'use strict';

    /**
     * @ngdoc module
     * @name user
     *
     * @requires common
     *
     * @description
     *
     *     User module for common user services
     *
     */
    angular
        .module('user', ['common']);

})(this.angular);

(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbOneWayBind', oneWayBinding);

    /**
     * @ngdoc directive
     * @name common.directive:rbOneWayBind
     *
     * @scope true
     * @requires rbOneWayBind
     * @requires ngModel
     * @priority 1000
     * @restrict A
     *
     * @description
     *     One way bind a model passed to the directive. This means if the
     *     outer (passed) model updates, it will update the local model, but
     *     if the local updates, it won't update the outer model.
     *
     * @author Norbert Varga
     */
    function oneWayBinding() {
        var directive = {
            scope: {
                rbOneWayBind: '@',
                ngModel: '='
            },
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc($scope, elem, attrs, ngModelCtrl) {

            ngModelCtrl.$formatters.unshift(setBindValue);

            $scope.$watch('rbOneWayBind', setBindValue);

            function setBindValue (newVal, oldVal) {
                if (!newVal || newVal !== oldVal) {
                    var binding = $scope.rbOneWayBind;

                    newVal = binding;
                    ngModelCtrl.$modelValue = binding;
                    // @TODO: temporary solution, need to revalidate in angular process
                    if (attrs.required) {
                        ngModelCtrl.$setValidity('required', true);
                    }
                    ngModelCtrl.$setValidity(attrs.name, true);
                }

                return newVal;
            }
        }
    }
})();

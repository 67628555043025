(function (angular, _) {
    'use strict';

    angular
        .module('sidebar')
        .directive('rbSidebarFilters', rbSidebarFilters);

    /**
     * @ngdoc directive
     * @name sidebar.directive:rbSidebarFilters
     *
     * @description
     *         Sidebar filters
     *
     * @ngInject
     */
    function rbSidebarFilters() {
        return {
            controller: controller,
            require: '^rbSidebar',
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/modules/sidebar/views/rb-sidebar-filters.html'
        };

        /* @ngInject */
        function controller($scope, sidebarState, User) {
            var vm = $scope.vm;

            vm.antePostFilter = sidebarState.getAntePostFilter();
            vm.activeFilters = sidebarState.getFilters();

            vm.isAntePostFilterActive = isAntePostFilterActive;
            vm.toggleAntePostFilter = toggleAntePostFilter;
            vm.addFilter = addFilter;
            vm.isFilterActive = isFilterActive;
            vm.removeFilter = removeFilter;
            vm.toggleFilter = toggleFilter;
            vm.hideGreyhoundsFilter = hideGreyhoundsFilter;

            $scope.$watchCollection('vm.activeFilters', watchActiveFilters);
            $scope.$watchCollection('vm.antePostFilter', watchAntePostFilter);

            function isAntePostFilterActive(filter) {
                return vm.antePostFilter === filter;
            }

            function toggleAntePostFilter(filter) {
                vm.antePostFilter = filter;
            }

            function isFilterActive(filter) {
                return _.indexOf(vm.activeFilters, filter) > -1;
            }

            function toggleFilter(filter) {
                var index = _.indexOf(vm.activeFilters, filter);

                if (index > -1) {
                    vm.activeFilters.splice(index, 1);
                } else {
                    vm.activeFilters.push(filter);
                }
            }

            function addFilter(filter) {
                var index = _.indexOf(vm.activeFilters, filter);

                if (index === -1) {
                    vm.activeFilters.push(filter);
                }
            }

            function removeFilter(filter) {
                var index = _.indexOf(vm.activeFilters, filter);

                if (index > -1) {
                    vm.activeFilters.splice(index, 1);
                }
            }

            function hideGreyhoundsFilter() {
                var hideGreyhounds = User.getDogsHidden();

                if (hideGreyhounds) {
                    removeFilter('D');
                }

                return hideGreyhounds;
            }

            function watchAntePostFilter(newAntePostFilter) {
                if (newAntePostFilter) {
                    vm.filterEvents();

                    sidebarState.setAntePostFilter(newAntePostFilter);
                }
            }

            function watchActiveFilters(newActiveFilters) {
                if (newActiveFilters) {
                    vm.filterEvents();

                    sidebarState.setFilters(newActiveFilters);
                }
            }
        }
    }
})(angular, _);

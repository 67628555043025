(function (angular) {
    'use strict';

    /**
     * @ngdoc module
     * @name sidebar
     *
     * @description
     *
     * The `sidebar` module ...
     *
     * @author Andras Matzon
     * @author Bogdan Gersak
     */
    angular
        .module('sidebar', []);

})(this.angular);

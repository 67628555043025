(function () {
    'use strict';

    angular
        .module('common')
        .factory('UsersResource', UsersResource);

    /**
     * @ngdoc service
     * @name common.service:UsersResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     Users API resource.
     *
     * @returns {Object} - Angular Resource Object
     *
     * @ngInject
     */
    function UsersResource ($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v1/users/:action', {
                    id: '@id'
                },
                {
                    retrieveUsername: {
                            method: 'POST',
                            params: {
                                action: 'retrieve_username'
                            }
                        },

                    requestPasswordReset: {
                            method: 'POST',
                            params: {
                                action: 'send_reset_password'
                            }
                        },

                    resetPassword: {
                            method: 'POST',
                            params: {
                                action: 'reset_password'
                            }
                        },

                    userExists: {
                            method: 'GET',
                            params: {
                                action: 'exists'
                            }
                    }
                });
    }
})();

(function () {
    'use strict';

    angular
        .module('common')
        .filter('formatRemoveSpace', formatRemoveSpace);

    /**
     * @ngdoc filter
     * @name common.filter:formatDate
     *
     * @requires  RaceBetsJS
     *
     * @description
     *      Removes spaces from strings
     *
     * @return {String} String withour spaces
     *
     * @ngInject
     */
    function formatRemoveSpace(RaceBetsJS) {
        return function(string) {
            if (!angular.isString(string)) {
                return string;
            }
            return string.replace(/[\s]/g, '');
        };
    }
})(angular);

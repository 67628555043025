(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .directive('rbSidebarContent', rbSidebarContent);

    /**
     * @ngdoc directive
     * @name sidebar.directive:rbSidebarContent
     *
     * @requires $location
     *
     * @description
     *         Sidebar content
     *
     * @ngInject
     */
    function rbSidebarContent($location) {
        return {
            controller: controller,
            link: link,
            require: '^rbSidebar',
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/modules/sidebar/views/rb-sidebar-content.html'
        };

        /* @ngInject */
        function controller($scope, sidebarState) {
            var vm = $scope.vm;

            vm.toggleCollapsed = sidebarState.toggleCollapsed;
            vm.isCollapsed = sidebarState.isCollapsed;
        }

        function link(scope, elem) {
            scope.openUrl = openUrl;

            // Open URL
            function openUrl(url) {
                $location.path(url);
            }
        }
    }
})(angular);

(function (angular) {
    'use strict';

    angular
        .module('common')
        .directive('rbPhoneNumber', rbPhoneNumber);

    /**
     * @ngdoc directive
     * @name common.directive:rbPhoneNumber
     *
     * @element input
     *
     * @requires ngModel
     * @requires Settings
     *
     * @description
     *
     *  # rbPhoneNumber
     *      The {@link common.directive:rbPhoneNumber rbPhoneNumber} directive checks if elements value is matching
     *      any of the existing country dial codes and does not start with 0
     *
     *  # Usage
     *      <input type="tel" value="123456" rb-phone-number>
     *
     * @ngInject
     */
    function rbPhoneNumber(Settings) {
        return {
            link: link,
            require: 'ngModel',
            restrict: 'A'
        };

        function link(scope, elem, attrs, ctrls) {
            var dialCodes = Settings.dialCodes(),
                matchedDialCodes = [],
                minLength = 3,
                maxLength = 18,
                attributes = attrs,
                initialDialCode = false;

            ctrls.$validators.phoneNumber = function (modelValue, viewValue) {
                if (!viewValue) return true;

                var isoCode = attributes.rbPhoneNumber;

                if(!initialDialCode) {
                    initialDialCode = viewValue;
                }

                // for BR phone: first 2 digits should be 55 and length should be 12 (landline) or 13 (mobile) with country code
                if ( isoCode === '55' ) {
                    minLength = 10;
                    maxLength = 11
                }

                return /^(?!0)/.test(viewValue) && viewValue.length >= minLength && viewValue.length <= maxLength;
            };
        }
    }
})(angular);

(function (angular) {
    'use strict';

    /**
     * @ngdoc directive
     * @name
     */

    angular
        .module('sidebar')
        .directive('rbSidebarPrefilters', rbSidebarPrefilters);

    function rbSidebarPrefilters() {
        return {
            require: 'rbSidebar',
            replace: true,
            restrict: 'E',
            templateUrl: '/angular/modules/sidebar/views/rb-sidebar-prefilters.html'
        };
    }
})(this.angular);

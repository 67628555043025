(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbPopup', popUp);

    /**
     * @ngdoc directive
     * @name common.directive:rbPopup
     *
     * @scope {}
     * @requires RaceBetsJS
     *
     * @description
     *     Opens popup
     *
     * @author Bogdan Gersak
     *
     * @ngInject
     */
    function popUp (RaceBetsJS) {
        var directive = {
                scope: {
                    href: '@',
                    name: '@',
                    width: '@',
                    height: '@',
                    scroll: '@'
                },
                link: linkFunc
            };

        return directive;

        function linkFunc(scope, elem) {
            elem.on('click', function (evt) {
                evt.preventDefault();

                /* open popup */
                RaceBetsJS.browser.popup.open({
                    href: (/^(http|https|ftp)/).test(scope.href) ?
                            scope.href :
                            RaceBetsJS.application.globals.urlPrefix + scope.href,
                    name: scope.name,
                    width: scope.width,
                    height: scope.height,
                    scroll: scope.scroll || 'no'
                });

                elem.blur();
            });
        }
    }
})();

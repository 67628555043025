(function (angular) {
    'use strict';

    /**
     * @ngdoc overview
     * @name featuredHorses
     * @requires common
     */
    angular
        .module('featuredHorses', ['common'])
        .config(routesConfig);

    /**
     * @ngdoc function
     * @name featuredHorses.config:routesConfig
     *
     * @requires $routeProvider
     * @requires DynamicRoutesProvider
     *
     * @description
     *     Account module routes
     *
     * @ngInject
     */
    function routesConfig($routeProvider, DynamicRoutesProvider) {
        var moduleURI = '/content/show/module/featuredhorses',
            templatePath = '/angular/modules/featured-horses/views';

        $routeProvider
            .when(moduleURI + '/cat/abroad', {
                id: 'abroad',
                templateUrl: templatePath + '/horses-abroad.html',
                controller: 'HorsesAbroadCtrl',
                controllerAs: 'vm',
                reloadOnSearch: false,
                resolve: {
                    /* ngInject */
                    countriesData: function($q, $route, HorsesAbroad) {
                        // Set filters from query params
                        HorsesAbroad.date($route.current.params.date);
                        HorsesAbroad.country($route.current.params.country);
                        HorsesAbroad.raceType($route.current.params.type);

                        var defer = $q.defer();
                        HorsesAbroad.getCountries($route.current.params.date).then(
                            function(resp) {
                                defer.resolve(resp);

                            },
                            function(resp) {
                                defer.resolve(resp);
                            }
                        );
                        return defer.promise;
                    }
                }
            });

        var config = {
            baseUrl: moduleURI
        };

        DynamicRoutesProvider.add(config);
    }
})(angular);

(function (angular) {
    'use strict';

    angular
        .module('user')
        .factory('UserBalanceResource', userBalanceResource);

    /**
     * @ngdoc service
     * @name user.service:UserAccountResource
     *
     * @requires $resource
     * @requires $location
     *
     * @description
     *     A resource "class" object which contains the default sets of account resource actions
     *
     *     #Usage
     *         UserAccountResource.get()
     *
     * @ngInject
     */
    function userBalanceResource($resource, $location) {
        return $resource('//' + $location.host() + '/rest/v1/users/me/balance');
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbInrange', rbInrange);

    /**
     * @ngdoc directive
     * @name  common.directive:rbInrange
     *
     * @restrict A
     * @requires ngModel
     *
     * @description
     *     Validates field value in range of values given to directive
     *
     */
    function rbInrange() {
        var directive = {
            scope: false,
            restrict: 'A',
            require: '^ngModel',
            link: linkFunc
        };

        return directive;

        function linkFunc(scope, elem, attrs, ctrls) {
            var range;

            ctrls.$validators.inrange = function(modelValue, viewValue) {
                if (!viewValue) {
                    return true;
                }
                var vValue = parseFloat(viewValue.toString().replace(/,/g,'.'));
                range = scope.$eval(attrs.rbInrange);
                return parseFloat(range.minAmount) <= vValue && parseFloat(range.maxAmount) >= vValue;
            };
        }
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('sidebar')
        .filter('eventsByRaceType', eventsByRaceType);

    /**
     * @ngDoc filter
     *
     * @description
     *     Filters a list of events by race type
     *
     *
     * @return {Array} Events
     *
     * @author Bogdan Gersak
     */
    function eventsByRaceType() {
        return function (events, raceTypes) {
            var filteredEvents = [];

            if (!(events && events.length)) {
                return [];
            }

            for (var i = events.length - 1; i >= 0; i--) {
                for(var j = 0, l = events[i].race_types.length; j < l; j++) {
                    if (_.indexOf(raceTypes, events[i].race_types[j]) > -1) {
                        events[i].race_type_class = events[i].race_types[j];
                        filteredEvents.push(events[i]);
                        break;
                    }
                }
            }

            return filteredEvents;
        };
    }
})(angular);

(function () {
    'use strict';

    angular
        .module('common')
        .factory('AjaxFallbackInterceptor', ajaxFallbackInterceptor);

    /**
     * @ngdoc service
     * @name common.service:AjaxFallbackInterceptor
     *
     * @description
     *      Intercept calls to dictionary files and rewrite it using the hashed version of the file if exists
     *      Intercept ajax calls and rewrite it using the current domain if fails
     *
     * @ngInject
     */
    function ajaxFallbackInterceptor($q, $injector, $window, RaceBetsJS) {
        var requestInterceptor = {
            request: request,
            responseError : responseError
        };

        function request(config) {
            var ajaxHost = RaceBetsJS.application.globals.ajaxHost;
            // Default timeout for ajax calls
            config.timeout = 30000;

            if (!ajaxHost) {
                return config;
            }

            if (config.url.indexOf(ajaxHost) > -1) {
                if (RaceBetsJS.application.globals.isAjaxHostOn == false) {
                    config.url = ajaxToDefaultHost(config.url);
                } else {
                    // Timeout for all calls to ajax host
                    config.timeout = RaceBetsJS.application.globals.ajax.timeout || 5000;
                }
            }

            return config;
        }

        function responseError(response) {
            var config = response.config;

            if (isAjaxHostCall(config.url) && !config.regularCall) {
                config.url = ajaxToDefaultHost(config.url);
                config.regularCall = true;
                RaceBetsJS.application.globals.isAjaxHostOn = false;

                var deferred = $q.defer();
                // re-try call
                retryHttpRequest(config, deferred);
                return deferred.promise;
            }

            response.data = response.data || {};
            return $q.reject(response);
        }

        function retryHttpRequest(config, deferred){
            function successCallback(response){
                deferred.resolve(response);
            }

            function errorCallback(response){
                deferred.reject(response);
            }

            var $http = $injector.get('$http');
            $http(config).then(successCallback, errorCallback);
        }

        function ajaxToDefaultHost(url) {
            var newUrl = '//' + $window.location.host;
            return url.replace(RaceBetsJS.application.globals.ajaxHost, newUrl);
        }

        function isAjaxHostCall(url) {
            var ajaxHost = RaceBetsJS.application.globals.ajaxHost;
            if (ajaxHost && url.indexOf(ajaxHost) > -1) {
                return true;
            }
            return false;
        }

        return requestInterceptor;
    }
})();

(function (angular) {
    'use strict';

    angular
        .module('user')
        .service('UserBalancePushEvents', userBalancePushEvents);

    /**
     * @ngdoc service
     * @name account.service:UserBalancePushEvents
     *
     * @requires RaceBetsJS
     *
     * @ngInject
     */
    function userBalancePushEvents(RaceBetsJS) {
        return {
            join: join
        };

        /**
         * @ngdoc method
         * @name user.service:UserBalancePushEvents#join
         * @public
         * @methodOf user.service:UserBalancePushEvents
         *
         * @description
         *     Joins node channel via old framework, calls the passed callback when message received
         */
        function join(cb) {
            if (!cb || !RaceBetsJS.application.globals.webSockets) {
                return;
            }

            RaceBetsJS.webSockets.nodeJS.login(false, function(channel, message) {
                cb(channel, message);
            });
        }
    }
})(angular);

(function (angular, JSON) {
    'use strict';

    angular
        .module('common')
        .factory('Settings', SettingsFactory);

    /**
     * @ngdoc service
     * @name common.service:Settings
     *
     * @requires $q
     * @requires SettingsResource
     *
     * @description
     *     Handles API calls to server
     *
     * @ngInject
     */
    function SettingsFactory ($q, $filter, $location, SettingsResource, RaceBetsJS) {
        var se = this;

        /**
         * @ngdoc property
         * @name common.service:Settings#_dialCodesArray
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      Array of country objects with ISO and dial code
         *
         * @type {Array}
         */
        var _dialCodesArray = [];

        /**
         * @ngdoc property
         * @name common.service:Settings#_dialCodesObject
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      List of countries in ISO format with ISD dial codes as values
         *
         * @type {Object}
         */
        var _dialCodesObject = {
            GB: 44,
            IM: 44,
            JE: 44,
            GG: 44,
            US: 1,
            DZ: 213,
            AD: 376,
            AO: 244,
            AI: 1264,
            AG: 1268,
            AR: 54,
            AM: 374,
            AW: 297,
            AU: 61,
            AT: 43,
            AZ: 994,
            BS: 1242,
            BH: 973,
            BD: 880,
            BB: 1246,
            BY: 375,
            BE: 32,
            BZ: 501,
            BJ: 229,
            BM: 1441,
            BT: 975,
            BO: 591,
            BA: 387,
            BW: 267,
            BR: 55,
            BN: 673,
            BG: 359,
            BF: 226,
            BI: 257,
            KH: 855,
            CM: 237,
            CA: 1,
            CV: 238,
            KY: 1345,
            CF: 236,
            CL: 56,
            CN: 86,
            CO: 57,
            KM: 269,
            CG: 242,
            CK: 682,
            CR: 506,
            HR: 385,
            CU: 53,
            CY: 357,
            CZ: 42,
            DK: 45,
            DJ: 253,
            DM: 1809,
            DO: 1809,
            EC: 593,
            EG: 20,
            SV: 503,
            GQ: 240,
            ER: 291,
            EE: 372,
            ET: 251,
            FK: 500,
            FO: 298,
            FJ: 679,
            FI: 358,
            FR: 33,
            GF: 594,
            PF: 689,
            GA: 241,
            GM: 220,
            GE: 7880,
            DE: 49,
            GH: 233,
            GI: 350,
            GR: 30,
            GL: 299,
            GD: 1473,
            GP: 590,
            GU: 671,
            GT: 502,
            GN: 224,
            GW: 245,
            GY: 592,
            HT: 509,
            HN: 504,
            HK: 852,
            HU: 36,
            IS: 354,
            IN: 91,
            ID: 62,
            IR: 98,
            IQ: 964,
            IE: 353,
            IL: 972,
            IT: 39,
            JM: 1876,
            JP: 81,
            JO: 962,
            KZ: 7,
            KE: 254,
            KI: 686,
            KP: 850,
            KR: 82,
            KW: 965,
            KG: 996,
            LA: 856,
            LV: 371,
            LB: 961,
            LS: 266,
            LR: 231,
            LY: 218,
            LI: 417,
            LT: 370,
            LU: 352,
            MU: 230,
            MO: 853,
            MK: 389,
            MG: 261,
            MW: 265,
            MY: 60,
            MV: 960,
            ML: 223,
            MT: 356,
            MH: 692,
            MQ: 596,
            MR: 222,
            YT: 269,
            MX: 52,
            FM: 691,
            MD: 373,
            MC: 377,
            MN: 976,
            MS: 1664,
            MA: 212,
            MZ: 258,
            NA: 264,
            NR: 674,
            NP: 977,
            NL: 31,
            NC: 687,
            NZ: 64,
            NI: 505,
            NE: 227,
            NG: 234,
            NU: 683,
            NF: 672,
            NO: 47,
            OM: 968,
            PK: 92,
            PW: 680,
            PA: 507,
            PG: 675,
            PY: 595,
            PE: 51,
            PH: 63,
            PL: 48,
            PT: 351,
            PR: 1787,
            QA: 974,
            RE: 262,
            RO: 40,
            RU: 7,
            RW: 250,
            SM: 378,
            ST: 239,
            SA: 966,
            SN: 221,
            RS: 381,
            SC: 248,
            SL: 232,
            SG: 65,
            SK: 421,
            SI: 386,
            SB: 677,
            SO: 252,
            ZA: 27,
            ES: 34,
            LK: 94,
            SH: 290,
            KN: 1869,
            SD: 249,
            SR: 597,
            SZ: 268,
            SE: 46,
            CH: 41,
            SY: 963,
            TW: 886,
            TJ: 7,
            TH: 66,
            TG: 228,
            TO: 676,
            TT: 1868,
            TN: 216,
            TR: 90,
            TM: 993,
            TC: 1649,
            TV: 688,
            UG: 256,
            UA: 380,
            AE: 971,
            UY: 598,
            UZ: 7,
            VU: 678,
            VA: 379,
            VE: 58,
            VN: 84,
            VG: 84,
            VI: 84,
            WF: 681,
            YE: 967,
            ZM: 260,
            ZW: 263
        };

        /**
         * @ngdoc property
         * @name common.service:Settings#_preferredCountries
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      Array of preferred countries
         *
         * @type {Array}
         */
        var _preferredCountries = [
            'GB',
            'DE',
            'IN',
            'SE',
            'IE',
            'IT',
            'AU'
        ];

        /**
         * @ngdoc property
         * @name common.service:Settings#countryIOC
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      Map of iso2 country codes with matching IOC code
         *
         * @type {Array}
         */
        se.countryIOC = {
            DE: 'GER',
            GB: 'GBR',
            IT: 'ITA',
            FR: 'FRA',
            IE: 'IRL',
            ES: 'ESP',
            SE: 'SWE',
            NO: 'NOR',
            BR: 'BRA'
        };

        /**
         * @ngdoc property
         * @name common.service:Settings#_ibanCodeLengths
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      IBAN code length by countries
         *
         * @type {Object}
         */
        var _ibanCodeLengths = {
            AD: 24,
            AE: 23,
            AT: 20,
            AZ: 28,
            BA: 20,
            BE: 16,
            BG: 22,
            BH: 22,
            BR: 29,
            CH: 21,
            CR: 21,
            CY: 28,
            CZ: 24,
            DE: 22,
            DK: 18,
            DO: 28,
            EE: 20,
            ES: 24,
            FI: 18,
            FO: 18,
            FR: 27,
            GB: 22,
            GI: 23,
            GL: 18,
            GR: 27,
            GT: 28,
            HR: 21,
            HU: 28,
            IE: 22,
            IL: 23,
            IS: 26,
            IT: 27,
            JO: 30,
            KW: 30,
            KZ: 20,
            LB: 28,
            LI: 21,
            LT: 20,
            LU: 20,
            LV: 21,
            MC: 27,
            MD: 24,
            ME: 22,
            MK: 19,
            MR: 27,
            MT: 31,
            MU: 30,
            NL: 18,
            NO: 15,
            PK: 24,
            PL: 28,
            PS: 29,
            PT: 25,
            QA: 29,
            RO: 24,
            RS: 22,
            SA: 24,
            SE: 24,
            SI: 19,
            SK: 24,
            SM: 27,
            TN: 24,
            TR: 26
        };

        /**
         * @ngdoc property
         * @name common.service:Settings#_registrationSettings
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      Registration settings for caching
         *
         */
        var _registrationSettings;

        /**
         * @ngdoc property
         * @name common.service:Settings#_countriesArray
         * @private
         * @propertyOf common.service:Settings
         *
         * @description
         *      Countries array for caching
         *
         */
        var _countriesArray;

        var service = {
            registrationSettings: registrationSettings,
            timezoneSettings: timezoneSettings,
            dialCodes: dialCodes,
            countries: countries,
            preferredCountries: preferredCountries,
            minLegalAge: minLegalAge,
            ibanCodeLengths: ibanCodeLengths,
            postcodePattern: postcodePattern,
            postcodeMinLength: postcodeMinLength,
            postcodeMaxLength: postcodeMaxLength,
            bankCodeMinLength: bankCodeMinLength,
            bankCodeMaxLength: bankCodeMaxLength,
            getCountryIOC: getCountryIOC,
            getHostname: getHostname,
            getCookieDomain: getCookieDomain,
            passwordPattern: passwordPattern,
            currenciesAsArrayOfObj: currenciesAsArrayOfObj
        };

        return service;

        /**
         * @name common.service:Settings#registrationSettings
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Request settings data for {@link Registration}
         *
         * @returns {Object} - Promise object
         */
        function registrationSettings(force) {
            if (_registrationSettings && !force) {
                return _registrationSettings.promise;
            }

            _registrationSettings = $q.defer();

            SettingsResource
                .registration().$promise
                .then(
                    function (resp) {
                        _registrationSettings.resolve(JSON.parse(angular.toJson(resp)));
                    },
                    function (resp) {
                        _registrationSettings.reject(JSON.parse(angular.toJson(resp)));
                    }
                );

            return _registrationSettings.promise;
        }

        /**
         * @name common.service:Settings#timezoneSettings
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns available timezones
         *
         * @returns {Array}
         */
        function timezoneSettings() {
            var timezoneList = RaceBetsJS.time.timezone.getTimezoneList();

            return timezonesAsArrayOfObj(timezoneList);
        }

        /**
         * @name common.service:Settings#timezonesAsArrayOfObj
         * @private
         * @methodOf common.service:Settings
         *
         * @description
         *      List of timezones with Olson ID and name with UTC
         *
         * @param {Array} timezoneList List of available timezone arrays
         * @returns {Array} - Array of timezone objects
         */
        function timezonesAsArrayOfObj(timezoneList) {
            var localArray = [];

            angular.forEach(timezoneList, function(value) {
                this.push({
                    olson_id: value[0],
                    utc_label: value[1]
                });
            }, localArray);

            return localArray;
        }

        /**
         * @name common.service:Settings#dialCodes
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns the list of countries with ISD dial codes
         *
         * @param {Boolean} asObject Return dial codes as object
         *
         * @returns {Array|Object} ISD dialcodes
         */
        function dialCodes(asObject) {
            if (asObject) {
                return _dialCodesObject;
            }

            if (_dialCodesArray.length !== 0) {
                return _dialCodesArray;
            }

            return dialCodesAsArrayOfObj(_dialCodesObject);
        }

        /**
         * @name common.service:Settings#dialCodesAsArrayOfObj
         * @private
         * @methodOf common.service:Settings
         *
         * @description
         *      Creates a list of dialcodes with country and dial codes as properties
         *
         * @param {Object} dialCodes Dial codes object
         *
         * @returns {Array} - Array of dial code objects
         */
        function dialCodesAsArrayOfObj(dialCodes) {
            angular.forEach(dialCodes, function(value, key) {
                this.push({
                    country_iso: key,
                    country_code: value
                });
            }, _dialCodesArray);

            return _dialCodesArray;
        }

        /**
         * @name common.service:Settings#countries
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns the list of countries with ISO codes and localised labels
         *
         * @param {Boolean} asObject Return countries as object
         *
         * @returns {Array}|{Object} Countries list
         */
        function countries(asObject) {
            if (asObject) {
                return RaceBetsJS.i18n.data.countries;
            }

            return countriesAsArrayOfObj(RaceBetsJS.i18n.data.countries);
        }

        /**
         * @name common.service:Settings#preferredCountries
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns the list of preferred countries
         *
         * @returns {Array} Countries list
         */
        function preferredCountries() {
            return _preferredCountries;
        }

        /**
         * @name common.service:Settings#countriesAsArrayOfObj
         * @private
         * @methodOf common.service:Settings
         *
         * @description
         *      Creates a sorted list of country objects from the dictionary data
         *
         * @param {Object} countries Localised countries' object from dictionary
         *
         * @returns {Array} Sorted array of country objects
         */
        function countriesAsArrayOfObj(countries) {
            if (_countriesArray) {
                return _countriesArray;
            }

            _countriesArray = [];
            angular.forEach(countries, countriesObjToArray, _countriesArray);
            _countriesArray = _countriesArray.sort(sortCountriesArray);
            return _countriesArray;

            function countriesObjToArray(label, iso) {
                /*jshint validthis: true*/
                var countriesArr = this;

                if (iso && label) {
                    countriesArr.push({
                        iso: iso,
                        label: label
                    });
                }
            }

            function sortCountriesArray(labelA, labelB) {
                if (labelA.label < labelB.label) {
                    return -1;
                }
                if (labelA.label > labelB.label) {
                    return 1;
                }

                return 0;
            }
        }

        /**
         * @name common.service:Settings#currenciesAsArrayOfObj
         * @private
         * @methodOf common.service:Settings
         *
         * @description
         *      List of currenies with currency code and localised label
         *
         * @param {Array} currencyList List of available currencies
         *
         * @returns {Array} Array of currency objects
         */
        function currenciesAsArrayOfObj(currencyList) {
            var localArray = [];

            angular.forEach(currencyList, function(value) {
                this.push({
                    code: value,
                    label: $filter('translate')('label_currency_' + value)
                });
            }, localArray);

            return localArray;
        }

        /**
         * @name common.service:Settings#minLegalAge
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns the minimum legal age
         *
         * @returns {Number} Age
         */
        function minLegalAge(country) {
            if (country && country === 'EE') {
                return 21;
            } else {
                return 18;
            }
        }

        /**
         * @name common.service:Settings#getCountryIOC
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns
         *
         * @param {String} country Country ISO 2 code
         *
         * @returns {String} Country IOC code
         */
        function getCountryIOC(country) {
            return se.countryIOC[country] ? se.countryIOC[country] : country;
        }

        /**
         * @name common.service:Settings#ibanCodeLengths
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Returns IBAN code lengths by countries
         *
         * @returns {Object} IBAN code lengths list
         */
        function ibanCodeLengths() {
            return _ibanCodeLengths;
        }

        /**
         * @name common.service:Settings#postcodePattern
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     RegEx pattern for post code, based on user's country of residence
         *
         * @param {String} country Country iso code
         *
         * @returns {String} Postcode match regex
         */
        function postcodePattern(country) {
            if (country === "BR") {
                return '^\\d{8}|\\d{5}-\\d{3}$';
            } else if (country === 'DE') {
                return '\\d+';
            } else {
                return '.*';
            }
        }

        /**
         * @name common.service:Settings#postcodeMinLength
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Minimum length of post code, based on user's country of residence
         *
         * @param {String} country Country iso code
         *
         * @returns {Number} Min length
         */
        function postcodeMinLength(country) {
            if (country === "BR") {
                return 8;
            } else if (country === 'DE') {
                return 5;
            } else {
                return 2;
            }
        }

        /**
         * @name common.service:Settings#postcodeMaxLength
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Maximum length of post code, based on user's country of residence
         *
         * @param {String} country Country iso code
         *
         * @returns {Number} Min length
         */
        function postcodeMaxLength(country) {
            if (country === "BR") {
                return 9;
            } else if (country === 'DE') {
                return 5;
            } else {
                return 10;
            }
        }

        /**
         * @name common.service:Settings#bankCodeMinLength
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Minimum length of bank code, based on user's country of residence
         *
         * @param {String} country Country iso code
         *
         * @returns {Number} Min length
         */
        function bankCodeMinLength(country) {
            return country === 'DE' ? 8 : 1;
        }

        /**
         * @name common.service:Settings#bankCodeMaxLength
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Maximum length of bank code, based on user's country of residence
         *
         * @param {String} country Country iso code
         *
         * @returns {Number} Min length
         */
        function bankCodeMaxLength(country) {
            return country === 'DE' ? 8 : 99;
        }

        /**
         * @name common.service:Settings#getHostname
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Get second level domain with subdomains. Optionally replace current TLD.
         *
         * @param {String} replaceTld Top level domain to replace
         *
         * @returns {String} Current or new hostname
         */
        function getHostname(replaceTld) {
            var host = $location.host();
            if (replaceTld
                && typeof replaceTld === 'string'
                && !host.includes('localhost')
                && !host.match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)
            ) {
                var parts = host.split('.');
                if (parts[parts.length - 2].match(/^co(m?)$/)) {
                    parts.splice(parts.length - 2);
                    parts.push(replaceTld);
                } else {
                    parts[parts.length - 1] = replaceTld;
                }
                return parts.join('.');
            }

            return host;
        }

        /**
         * @name common.service:Settings#getCookieDomain
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     Get domain for setting cookies
         *
         * @returns {String} Current or new hostname
         */
        function getCookieDomain() {
            // If IP, return IP
            if ($location.host().match(/^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$/)) {
                return $location.host();
            }

            // Return the domain name, replace www., www1., www2. etc
            return '.' + $location.host().replace(/^www\d*\./ig, '');
        }

        /**
         * @name common.service:Settings#passwordPattern
         * @public
         * @methodOf common.service:Settings
         *
         * @description
         *     RegEx pattern for post code, based on user's country of residence
         *
         * @returns {RegExp} Pasword match regex
         */
        function passwordPattern() {
            return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]).{8,}$/;
        }
    }
})(angular, JSON);

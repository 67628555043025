(function () {
    'use strict';

    angular
        .module('common')
        .controller('ExportsAngularAppCtrl', ExportsAngularAppCtrl);

    /**
     * @ngdoc controller
     * @name common.controller:ExportsAngularAppCtrl
     *
     * @requires $scope
     * @requires $route
     * @requires $location
     * @requires Auth
     *
     * @description
     *     Exposes Angular methods thus enables their usage outside Angular app
     *
     *  @ngInject
     */
    function ExportsAngularAppCtrl($injector, $rootScope, $scope, $route, $location, Auth, UserBalance, $filter) {
        $scope.navigateTo = function(url) {
            var prevRoute = $location.path(),
                currentRoute;

            // Remove prefix from url. This is required due that we are using base href in Angular
            // but in old code the navigateTo function adds prefix to every url
            url = url.replace(raceBetsJS.application.globals.urlPrefix, '');

            // Navigate to url
            $location.path(url);

            // Cache new route
            currentRoute = $location.path();

            if (prevRoute === currentRoute) {
                $route.reload();
            } else {
                $scope.$apply();
            }
        };

        $scope.showLogin = function(options) {
            raceBetsJS.application.assets.dialogs.loginDialog.angularLogin(options, Auth);
        };

        $scope.logout = function () {
            Auth.logout();
        };

        $scope.showLogin2fa = function(options) {
            raceBetsJS.application.assets.dialogs.login2faDialog.angularLogin(options, Auth);
        };

        $scope.getUserBalance = function () {
            return UserBalance.getBalance();
        };

        $scope.hideConfirmLimitIndicators = function () {
            $rootScope.$emit('hideConfirmLimitIndicators', {});
        };

        $scope.closeIframe = function (data) {
            $rootScope.$emit('closeIframe', data);
        };

        $scope.getSEOText = function () {
            return $filter('translate')('txt_seo_home_page', {
                brandName: $filter('translate')('seo_brand')
            })
        };

        if (!$rootScope.isB2B) {
            $scope.userRestrictions = $injector.get('UserRestrictions');
        }

    }
})(angular);

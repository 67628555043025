(function (angular) {
    'use strict';

    angular
        .module('featuredHorses')
        .directive('rbFeaturedHorsesList', rbFeaturedHorsesList);

    /**
     * @ngdoc directive
     * @name featuredHorses.directive:rbFeaturedHorsesList
     *
     * @require RaceBetsJS
     *
     * @restrict E
     *
     * @description
     *      Common filtering for featured horses (like horses abroad, stars, etc.)
     *
     */
    function rbFeaturedHorsesList() {
        return {
            controller: Controller,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                horses: '='
            },
            templateUrl: '/angular/modules/featured-horses/views/rb-featured-horses-list.html'
        };

        /* @ngInject */
        function Controller($window, $timeout, $scope, UserSettings, RaceBetsJS) {
            var vm = this;

            vm.fixedOddsClick = fixedOddsClick;
            vm.formatTime = formatTime;
            vm.formatRaceNumber = formatRaceNumber;
            vm.formatOdds = formatOdds;

            /**
             * @ngdoc property
             * @name featuredHorses.directive:rbFeaturedHorsesList#loading
             * @public
             * @propertyOf featuredHorses.directive:rbFeaturedHorsesList
             *
             * @description
             *     Service loading
             *
             * @type {Boolean}
             */
            vm.loading = false;

            // Watch horses changes
            $scope.$watch('horses', watchHorses, true)

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesList#formatTime
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesList
             *
             * @description
             *         Formats time
             *
             */
            function formatTime(time) {
                return RaceBetsJS.format.date(RaceBetsJS.i18n.data.dateFormatTime, time);
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesList#formatRaceNumber
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesList
             *
             * @description
             *         Formats race number
             *
             */
            function formatRaceNumber(raceNumber) {
                return RaceBetsJS.i18n.print('raceNumberX', { race: raceNumber });
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesList#formatOdds
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesList
             *
             * @description
             *         Formats odds
             *
             */
            function formatOdds(odds) {
                var userSettings = UserSettings.get('general');
                
                return RaceBetsJS.format.odds(odds, userSettings.oddsFormat, true);
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesList#fixedOddsClick
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesList
             *
             * @description
             *         Opens the Betting Dialog for fixed odds
             *
             * @param {Object} $event - Angular $event to extract DOM attirbutes for Betting Dialog
             *
             */
            function fixedOddsClick($event) {
                RaceBetsJS.application.assets.bettingDialog.show($event.currentTarget);
            }

            /**
             * @ngdoc method
             * @name featuredHorses.directive:rbFeaturedHorsesList#watchHorses
             * @private
             * @methodOf featuredHorses.directive:rbFeaturedHorsesList
             * 
             * @description
             *     Horses changes callback
             * 
             * @param {Array} horses - horses state
             */
            function watchHorses(horses) {
                var tipped = $window.Tipped;

                function mapRunner(runner) {
                    runner.pp = {
                        purse: runner.purse,
                        purseCurrency: runner.purse_currency,
                        lastRunFlat: runner.last_run_flat,
                        lastRunHurdles: runner.last_run_hurdles,
                        course: runner.course,
                        distance: runner.distance,
                        courseDistance: runner.course_distance,
                    }

                    runner.featuredHorse = {
                        comment: runner.featured_horse_comment
                    }

                    runner.pinSticker = runner.pin_sticker
                    runner.eventCountry = runner.event_country
                    runner.idSubject = runner.id_subject
                    runner.damSire = runner.dam_sire

                    return runner
                }

                $timeout(function() {
                    tipped.create($('div.c-dataTable__row a.c-dataTable__cell--runner strong'), function(elem) {
                        var idRunner = $(elem).parent().parent().data('id-runner');
                        var runner = horses.find(function(item) {
                            return item.id_runner === idRunner
                        })

                        if (!runner) {
                            return
                        }

                        runner = mapRunner(runner)
                        var content = $(raceBetsJS.application.templates.race.runner.details($.extend(true, {}, runner, { raceType: "", includeName: false, eventCountry: runner.eventCountry })));
                        var ret = false;

                        if ($.trim(content.find('table').text())) {
                            ret = content[0];
                        } else if ($.trim(content.text())) {
                            // remove margin-top of first paragraph
                            content.find('p:first').addClass('no-margin-top');
                            ret = content[0];
                        }

                        return ret;
                    }, {
                        hook: 'bottomleft',
                        skin: 'runnerDetails',
                        hideOn: [
                            { element: 'self', event: 'mouseleave' },
                            { element: 'tooltip', event: 'mouseenter' },
                            { element: 'tooltip', event: 'click' }
                        ],
                        maxWidth: 495,
                        zIndex: 10000
                    });
                });
            }
        }

    }
})(angular);

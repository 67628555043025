(function () {
    'use strict';

    angular
        .module('common')
        .directive('rbCurrencySign', currencySign);

    /**
     * @ngdoc directive
     * @name common.directive:rbCurrencySign
     *
     * @scope true
     * @restrict A
     * @requires $filter
     * @element span
     *
     * @description
     *     Generates an element with the currency sign using {@link common.filter:formatCurrency formatCurrency} filter.
     *     Adds a '.currency' class to the element for styling.
     *
     *     The directive scope is isolated.
     *
     * @ngInject
     */
    function currencySign (User) {
        var directive = {
            scope: {},
            restrict: 'A',
            template: '{{currencySign}}',
            link: linkFunc
        };

        return directive;

        function linkFunc ($scope, $element) {

            $scope.currencySign = User.currencySign();

            $element.addClass('currency');
        }
    }
})();

(function () {
    'use strict';

    angular
        .module('common')
        .provider('DynamicRoutes', DynamicRoutes);

    /**
     * @ngdoc service
     * @name common.provider:DynamicRoutes
     *
     * @requires $routeProvider
     *
     * @description
     *     Provide functionality to create routes dynamically based on predefined array of objects
     *
     * @author Sandor Turanszky
     *
     * @ngInject
     */
    function DynamicRoutes($routeProvider) {

        /**
         * @ngdoc method
         * @name common.service:add
         * @public
         * @methodOf common.service:DynamicRoutes
         *
         * @description
         *     Exposes add method as a public API
         *
         */
        this.add = function(config) {
            _add(config);
        };

        /**
         * @ngdoc method
         * @name common.service:_add
         * @private
         * @methodOf common.service:DynamicRoutes
         *
         * @description
         *     Add new route to $routeProvider with params when available
         *
         */
        var _add = function(config) {
            if(config.routes) {
                var routes = config.routes;
                for (var i = 0, l = routes.length; i < l; i++) {
                    var pattern = config.baseUrl + '/' + routes[i].title;

                    if (routes[i].params) {
                        pattern = pattern + '/' + routes[i].params.join('/');
                    }

                    if (routes[i].onlyParams) {
                        pattern = pattern + '/' + routes[i].onlyParams.join('/');
                    }

                    if (config.commonParams && !routes[i].onlyParams) {
                        pattern = pattern + '/' + config.commonParams.join('/');
                    }

                    $routeProvider.when(
                        pattern,
                        (routes[i].properties === undefined) ? config.commonProperties : routes[i].properties
                    );
                }
            }
        };

        /**
         * @ngdoc method
         * @name common.service:$get
         * @private
         * @methodOf common.service:$get
         *
         * @description
         *     Method required by provider specification. Usually used to get configuration data
         *
         */
        this.$get = function () {
            return {
                add: function(config) {
                    _add(config);
                }
            };
        };
    }
})();

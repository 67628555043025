(function (angular) {
    'use strict';

    angular
        .module('user')
        .factory('User', userService);

    /**
     * @ngdoc service
     * @name user.service:User
     *
     * @requires $q
     * @requires UserResource
     * @requires UserSettings
     * @requires UserSession
     * @requires RaceBetsJS
     *
     * @description
     *     User service to handle all user specific actions
     *
     * @ngInject
     */
    function userService($q, $rootScope, UserResource, UserSettings, UserSession, RaceBetsJS) {
        // Local constants
        var number_formats_default = {
                decimal_sep: ',',
                group_sep: '.'
            },
            number_formats_alt = {
                decimal_sep: '.',
                group_sep: ','
            },
            alternate_format_languages = [
                'en',
                'zh',
                'mt',
                'ja'
            ],
            currency_signs = {
                EUR: '€',
                USD: 'US$',
                GBP: '£',
                AED: 'AED',
                ARS: 'AR$',
                AUD: 'AU$',
                BRL: 'R$',
                CAD: 'C$',
                CHF: 'CHF',
                CLP: 'CL$',
                COP: 'CO$',
                CZK: 'Kč',
                DKK: 'kr',
                HKD: 'HK$',
                HUF: 'Ft',
                INR: '₹',
                JPY: '¥',
                MOP: 'MOP$',
                MYR: 'MR',
                MXN: 'MX$',
                NOK: 'kr',
                NZD: 'NZ$',
                PEN: 'S/',
                PLN: 'zł',
                SAR: 'SAR',
                SEK: 'kr',
                SGD: 'SG$',
                TRY: 'TR₤',
                UYU: '$U',
                ZAR: 'R'
            },
            race_type_filters_default = [
                'G',
                'J',
                'T'
            ],
            verification_steps_min = [
                'email'
            ],
            verification_steps_full = [
                'email',
                'personal_details',
                'identity'
            ],
            support_languages = [
                'en',
                'de'
            ];

        var service = {
            numberFormats: numberFormats,
            currency: currency,
            currencySign: currencySign,
            unitSystem: unitSystem,
            isLoggedIn: isLoggedIn,
            noBonus: noBonus,
            country: country,
            ipCountry: ipCountry,
            licenseJurisdiction: licenseJurisdiction,
            isVirtualAvailable: isVirtualAvailable,
            lang: lang,
            supportLang: supportLang,
            idAccount: idAccount,
            idCustomer: idCustomer,
            getGUID: getGUID,
            getTMSessionID: getTMSessionID,
            username: username,
            firstName: firstName,
            lastName: lastName,
            email: email,
            mobile: mobile,
            currentTimestamp: currentTimestamp,
            timeDiff: timeDiff,
            temporaryAccountEnabled: temporaryAccountEnabled,
            accountVerified: accountVerified,
            emailVerified: emailVerified,
            limitedAccess: limitedAccess,
            mustVerify: mustVerify,
            mustVerifyExperian: mustVerifyExperian,
            mustVerifyBrazil: mustVerifyBrazil,
            getBalanceHidden: getBalanceHidden,
            setBalanceVisibility: setBalanceVisibility,
            showSettingsDialog: showSettings,
            getRaceTypeFilters: getRaceTypeFilters,
            getDogsHidden: getDogsHidden,
            verificationSteps: verificationSteps,
            confirmEmail: confirmEmail,
            updateEmail: updateEmail,
            isConsentRequired: isConsentRequired,
            isTrustlyRegistrationAllowed: isTrustlyRegistrationAllowed
        };

        return service;

        /**
         * @ngdoc method
         * @name user.service:User#numberFormats
         * @public
         * @methodOf user.service:User
         *
         * @return {Object} - Number formatting properties based on the user's language
         */
        function numberFormats() {
            if (alternate_format_languages.indexOf(RaceBetsJS.application.user.lang) > -1) {
                return number_formats_alt;
            }

            return number_formats_default;
        }

        /**
         * @ngdoc method
         * @name user.service:User#getCurrency
         * @public
         * @methodOf user.service:User
         *
         * @return {String} - string representation of currency used by the user
         */
        function currency() {
            return RaceBetsJS.application.user.currency;
        }

        /**
         * @ngdoc method
         * @name user.service:User#getCurrencySign
         * @public
         * @methodOf user.service:User
         *
         * @return {String} - string representation of currency used by the user
         */
        function currencySign() {
            return (currency_signs.hasOwnProperty(RaceBetsJS.application.user.currency)) ? currency_signs[RaceBetsJS.application.user.currency] : '';
        }

        /**
         * @ngdoc method
         * @name user.service:User#unitSystem
         * @public
         * @methodOf user.service:User
         *
         * @return {String} - string representation of unit system used by the user
         */
        function unitSystem() {
            var settings = UserSettings.get('general'),
                system;

            system = settings.unitSystem || 'metric';

            return system;
        }

        /**
         * @ngdoc method
         * @name user.service:User#isLoggedIn
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Checks if user is logged in
         *
         * @return {Boolean}
         */
        function isLoggedIn() {
            return RaceBetsJS.application.user.loggedIn;
        }

        /**
         * @ngdoc method
         * @name user.service:User#noBonus
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Checks if bonuses are disabled for the user
         *
         * @return {Boolean}
         */
        function noBonus() {
            return RaceBetsJS.application.user.noBonus;
        }

        /**
         * @ngdoc method
         * @name user.service:User#country
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user country
         *
         * @return {String}
         */
        function country() {
            return RaceBetsJS.application.user.country;
        }

        /**
         * @ngdoc method
         * @name user.service:User#ipCountry
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user country based on IP
         *
         * @return {String}
         */
        function ipCountry() {
            return RaceBetsJS.application.user.ipCountry;
        }

        /**
         * @ngdoc method
         * @name user.service:User#licenseJurisdiction
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user license jurisdiction country
         *
         * @return {String}
         */
        function licenseJurisdiction() {
            return RaceBetsJS.application.user.licenseJurisdiction;
        }

        /**
         * @ngdoc method
         * @name user.service:User#isVirtualAvailable
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     is VR available
         *
         * @return {Boolean}
         */
        function isVirtualAvailable() {
            return RaceBetsJS.application.globals.isVirtualAvailable;
        }

        /**
         * @ngdoc method
         * @name user.service:User#lang
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user lang
         *
         * @return {String} - ISO 639-1 language tag. Example: 'en', 'de'
         */
        function lang() {
            return RaceBetsJS.application.user.lang;
        }

        /**
         * @ngdoc method
         * @name user.service:User#supportLang
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns language for support articles. Defaults to English.
         *
         * @return {String} ISO 639-1 language tag. Example: 'en', 'de'
         */
        function supportLang() {
            return support_languages.indexOf(RaceBetsJS.application.user.lang) >= 0 ?
                    RaceBetsJS.application.user.lang :
                    'en';
        }

        /**
         * @ngdoc method
         * @name user.service:User#idAccount
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns or sets user's account ID
         *
         * @param {Number} id Account ID
         *
         * @return {Number}
         */
        function idAccount(id) {
            if (id) {
                RaceBetsJS.application.user.idAccount = id;
            }
            return RaceBetsJS.application.user.idAccount;
        }

        /**
         * @ngdoc method
         * @name user.service:User#idCustomer
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's customer ID
         *
         * @return {Number}
         */
        function idCustomer() {
            return RaceBetsJS.application.user.idCustomer;
        }

        /**
         * @ngdoc method
         * @name user.service:User#GUID
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's account ID or zeros as GUID
         *
         * @return {String} GUID
         */
        function getGUID() {
            return isLoggedIn() ? '' + idAccount() : '00000000-0000-0000-0000-000000000000';
        }

        /**
         * @ngdoc method
         * @name user.service:User#getTMSessionID
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's ThreadMatrix session ID
         *
         * @return {String} session ID
         */
        function getTMSessionID() {
            return RaceBetsJS.application.user.tmSessionId ? RaceBetsJS.application.user.tmSessionId : null;
        }

        /**
         * @ngdoc method
         * @name user.service:User#username
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's username
         *
         * @return {String} - Username
         */
        function username() {
            return RaceBetsJS.application.user.username;
        }

        /**
         * @ngdoc method
         * @name user.service:User#firstName
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns or set user's first name
         *
         * @param {String} firstName First name
         *
         * @return {String} First name
         */
        function firstName(firstName) {
            if (firstName) {
                RaceBetsJS.application.user.firstName = firstName;
            }
            return RaceBetsJS.application.user.firstName;
        }

        /**
         * @ngdoc method
         * @name user.service:User#lastName
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns or set user's last name
         *
         * @param {String} lastName Last name
         *
         * @return {String} Last name
         */
        function lastName(lastName) {
            if (lastName) {
                RaceBetsJS.application.user.lastName = lastName;
            }
            return RaceBetsJS.application.user.lastName;
        }

        /**
         * @ngdoc method
         * @name user.service:User#email
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's email
         *
         * @return {String} - email
         */
        function email() {
            return RaceBetsJS.application.user.email;
        }

        /**
         * @ngdoc method
         * @name user.service:User#mobile
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's mobile
         *
         * @return {String} mobile number
         */
        function mobile(mobile) {
            if (mobile) {
                RaceBetsJS.application.user.mobile = mobile;
            }
            return RaceBetsJS.application.user.mobile;
        }

        /**
         * @ngdoc method
         * @name user.service:User#currentTimestamp
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's unix epoch based timestamp in seconds
         *     substracting the time difference
         *
         * @return {Number} - timestamp
         */
        function currentTimestamp() {
            return Math.ceil((new Date().getTime() - RaceBetsJS.application.user.timeDiff) / 1000);
        }


        /**
         * @ngdoc method
         * @name user.service:User#timeDiff
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns user's local and server's time difference in seconds
         *
         * @return {Number} - Difference in milliseconds
         */
        function timeDiff() {
            return RaceBetsJS.application.user.timeDiff;
        }

        /**
         * @ngdoc method
         * @name user.service:User#temporaryAccountEnabled
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Temporary account verification is enabled (only for German users)
         *
         * @return {Boolean}
         */
        function temporaryAccountEnabled() {
            return (RaceBetsJS.application.user.country === 'DE' || RaceBetsJS.application.globals.websiteTLD === 'de') && RaceBetsJS.application.user.temporaryAccountEnabled;
        }

        /**
         * @ngdoc method
         * @name user.service:User#accountVerified
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Return if user's account is verified or not
         *
         * @return {Boolean}
         */
        function accountVerified() {
            return RaceBetsJS.application.user.accountVerified;
        }

        /**
         * @ngdoc method
         * @name user.service:User#emailVerified
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Return if user's email is verified
         *
         * @return {Boolean}
         */
        function emailVerified() {
            return RaceBetsJS.application.user.emailVerified;
        }

        /**
         * @ngdoc method
         * @name user.service:User#mustVerify
         * @public
         * @methodOf user.service:User
         *
         * @description
         *      User must go through verification process
         *
         * @return {Boolean}
         */
        function mustVerify() {
            // Email verification is mandatory
            if (!RaceBetsJS.application.user.emailVerified) {
                return true;
            }
            // If user is German and mobile verification is enabled, the verification is mandatory
            if (RaceBetsJS.application.user.country === 'DE' &&
                RaceBetsJS.application.user.temporaryAccountEnabled &&
                !RaceBetsJS.application.user.accountVerified) {
                return true;
            }

            return false;
        }

        /**
         * @ngdoc method
         * @name user.service:User#mustVerifyExperian
         * @public
         * @methodOf user.service:User
         *
         * @description
         *      User must go through experian verification process
         *
         * @return {Boolean}
         */
        function mustVerifyExperian() {

            // experian verification only for users not from germany
            if (RaceBetsJS.application.user.licenseJurisdiction === 'GB' &&
                RaceBetsJS.application.user.temporaryAccountEnabled &&
                !RaceBetsJS.application.user.accountVerified) {
                return true;
            }

            return false;
        }

        /**
         * @ngdoc method
         * @name user.service:User#mustVerifyBrazil
         * @public
         * @methodOf user.service:User
         *
         * @description
         *      User must go through verification process
         *
         * @return {Boolean}
         */
        function mustVerifyBrazil() {

            if (RaceBetsJS.application.user.licenseJurisdiction === 'BR' &&
                RaceBetsJS.application.user.temporaryAccountEnabled &&
                !RaceBetsJS.application.user.accountVerified) {
                return true;
            }

            return false;
        }


        /**
         * @ngdoc method
         * @name user.service:User#limitedAccess
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns access status
         *
         * @return {Object} Status
         */
        function limitedAccess(deadline) {
            if (deadline) {
                RaceBetsJS.application.user.accountLimitedAccess = 'active';
                RaceBetsJS.application.user.accountLimitedAccessDeadline = deadline;
            }

            return {
                status: RaceBetsJS.application.user.accountLimitedAccess,
                days_left: daysLeft(deadline || RaceBetsJS.application.user.accountLimitedAccessDeadline)
            };

            function daysLeft(deadline) {
                if (!deadline) {
                    return 0;
                }

                var expires = new Date(deadline * 1000),
                    currentDate = new Date();

                return Math.round(Math.abs(expires.getTime() - currentDate.getTime())/(24*60*60*1000));
            }
        }



        /**
         * @ngdoc method
         * @name user.service:User#getBalanceHidden
         * @public
         * @methodOf user.service:User
         *
         * @return {Boolean} - boolean if user set the balance to be hidden
         */
        function getBalanceHidden() {
            var settings = UserSettings.get('general'),
                isBalanceHidden = true;

            isBalanceHidden = settings.hideBalance;

            return isBalanceHidden;
        }

        /**
         * @ngdoc method
         * @name user.service:User#setBalanceVisibility
         * @public
         * @methodOf user.service:User
         */
        function setBalanceVisibility(state) {
            var settings = UserSettings.get('general'),
                isBalanceHidden = settings.hideBalance;

            if (state) {
                settings.hideBalance = state;
            } else {
                settings.hideBalance = !isBalanceHidden;
            }

            UserSettings.set('general', settings, true);
        }

        /**
         * @ngdoc method
         * @name user.service:Users#showSettings
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Shows the settings dialog UI
         */
        function showSettings() {
            RaceBetsJS.application.assets.settings.show();
        }

        /**
         * @ngdoc method
         * @name user.service:Users#getRaceTypeFilters
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Shows the settings dialog UI
         *
         * @returns {Array} - Array of active race type filters
         */
        function getRaceTypeFilters() {
            var session = UserSession.get('sidebar');

            return session.filters || race_type_filters_default;
        }

        /**
         * @ngdoc method
         * @name user.service:Users#getDogsHidden
         * @public
         * @methodOf user.service:User
         *
         * @description
         *     Returns boolean of greyhounds should be hidden or not
         *
         * @returns {Boolean} - True if greyhound events are hidden
         */
        function getDogsHidden() {
            var settings = UserSettings.get('general');
            var value = true;

            if (settings.hasOwnProperty('hideDogs')) {
                value = settings.hideDogs;
            }

            return value;
        }

        /**
         * @name common.service:User#verificationSteps
         * @methodOf common.service:User
         *
         * @description
         *     Get required steps of account verification
         *
         * @returns {Array} Steps
         */
        function verificationSteps() {
            if (RaceBetsJS.application.user.country === 'DE' ) {
                return verification_steps_full;
            } else {
                return verification_steps_min;
            }
        }

        /**
         * @name common.service:User#confirmEmail
         * @methodOf common.service:User
         *
         * @description
         *     Confirm email address
         *
         * @returns {Object} - Promise object
         */
        function confirmEmail(data) {
            var defer = $q.defer();

            UserResource
                .confirmEmail(data).$promise
                .then(
                    function (resp) {
                        defer.resolve(resp);
                    },
                    function (resp) {
                        defer.reject(JSON.parse(angular.toJson(resp)));
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:User#updateEmail
         * @methodOf common.service:User
         *
         * @description
         *     Confirm email address
         *
         * @returns {Object} - Promise object
         */
        function updateEmail(data) {
            var defer = $q.defer();

            UserResource
                .updateEmail(data).$promise
                .then(
                    function () {
                        defer.resolve();
                    },
                    function (resp) {
                        defer.reject(JSON.parse(angular.toJson(resp)));
                    }
                );

            return defer.promise;
        }

        /**
         * @name common.service:User#isConsentRequired
         * @methodOf common.service:User
         *
         * @description
         *     Checks if user is logged in, GDPR is enabled and consent is required
         *
         * @returns {Boolean} is required
         */
        function isConsentRequired() {
            var result = true;

            if (!isLoggedIn() || RaceBetsJS.application.globals.gdpr === false || !RaceBetsJS.application.user.consentRequired ) {
                result = false;
            }

            return result;
        }

        /**
         * @name common.service:User#isTrustlyRegistrationAllowed
         * @methodOf common.service:User
         *
         * @description
         *     Checks if user is allowed for trustly registration
         *
         * @returns {Boolean} is required
         */
        function isTrustlyRegistrationAllowed() {
            return (RaceBetsJS.application.globals.trustlyEnabledCountries.indexOf(RaceBetsJS.application.user.ipCountry) > -1) && RaceBetsJS.application.globals.websiteTLD !== 'de';
        }

    }

})(angular);

(function (angular) {
    'use strict';

    angular
        .module('common')
        .filter('formatCurrency', formatCurrency);

    /**
     * @ngdoc filter
     * @name common.filter:formatCurrency
     *
     * @requires $filter
     * @requires User
     *
     * @description
     *     Returns a formatted number with currency symbol based on user's currency.
     *
     *     Options parameter is optional.
     *
     * @param {Number} amount Input to filter
     * @param {Object} options Formatting options
     *
     * @return {String} Amount with user's currency symbol
     *
     * @ngInject
     */
    function formatCurrency($filter, User) {
        return function (amount, options) {
            if (isNaN(amount)) {
                return;
            }

            var defaultOptions = {
                    fractionSize: 2,
                    trailingZeros: true,
                    currency: User.currency(),
                    currencySign: User.currencySign()
                };

            options = angular.extend(defaultOptions, options || {});

            var userCurrencySign = options.currencySign,
                userLanguage = User.lang(),
                forceSuffixByLang = ['de'];

            amount = $filter('formatNumber')(amount, {fractionSize: options.fractionSize, trailingZeros: options.trailingZeros});

            if ( forceSuffixByLang.indexOf(userLanguage) > -1 && options.currency === 'EUR' ) {
                return amount + '\u00A0' + userCurrencySign;
            }
            else {
                return userCurrencySign + '\u00A0' + amount;
            }
        };
    }
})(angular);
